import styled from '@emotion/styled'

export const Header = styled.div`
    color: #171923;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    padding: 0 32px;
    padding-top: 16px;
    margin-bottom: 20px;
    justify-content: flex-end;
    display: flex;

    //& > div:nth-child(1) {

    & > div:nth-of-type(1) {
        display: none;
    }

    @media only screen and (min-width: 992px) {
        justify-content: space-between;
        & > div:nth-of-type(1) {
            //& > div:nth-child(1) {
            display: inline-block;
        }
    }
`