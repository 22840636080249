export const RoleType = {
    READ : 1,
    WRITE : 2,
}
Object.freeze(RoleType);

export const CompanyPlan = {
    BASIC: 'M0015D0001',
    PREMIUM: 'M0015D0002',
    ENTERPRISE: 'M0015D0003',
}
Object.freeze(CompanyPlan);