import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from '@emotion/styled';
import AxiosNavigate from '../../library/axios-navigate';
import {Header} from '../../compontents/Header';
import MonthCalendar from '../../compontents/MonthCalendar';
import dayjs from 'dayjs';
import Modal from '../../compontents/Modal';
import {useReactToPrint} from 'react-to-print';
import LaborInsuranceDetailPrint from '../../compontents/print/LaborInsuranceDetailPrint';
import {useMutation} from '@tanstack/react-query';
import {getAllSearchSite, getInsuranceReport} from '../../library/axios-query';
import DownLoadExcel from '../../library/handle-excel';
import EmptyContainer from '../../compontents/EmptyContainer';

const LaborInsuranceDetail = () => {
    const belongRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-01'));
    const [sites, setSites] = useState([]);
    const [opendExport, setOpendExport] = useState(false);
    const [selectedSiteNm, setSelectedSiteNm] = useState('');
    const [report, setReport] = useState(undefined);
    const [selectedSite, setSelectedSite] = useState(null);

    // 프린트 출력
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    // 현장 4대보험 내역 조회
    const {mutate: getInsuranceReportMutate, data: getInsuranceReportData} = useMutation({
        mutationKey: ['getInsuranceReport'],
        mutationFn: getInsuranceReport,
        onSuccess: (data) => {

        }, onError: (error) => {
            throw error;
        }
    });

    //모든 현장 조회
    const {mutate: handleAllSites, data: getAllSearchSiteData} = useMutation({
        mutationFn: getAllSearchSite,
        mutationKey: ['getAllSearchSite'],
        onSuccess: (data) => {
            if (sites.length === 0) {
                setSites(data);
            } else {
                setSelectedSite(null);
                getInsuranceReportMutate({
                    work_dt: selectedDate,
                    site_seq: null
                });
            }
        },
        onError: (error) => {
            throw error;
        }
    });

    // 현장 검색
    const handleSearchSite = (value) => {
        const list = getAllSearchSiteData.filter((e) => e.site_nm.toLowerCase().replaceAll(' ', '').includes(value.toLowerCase().replaceAll(' ', '')));
        setSites(list);
    };

    // 현장 목록 클릭
    const handleSelectedSite = (e) => {
        setSelectedSite(e);
        getInsuranceReportMutate({work_dt: selectedDate, site_seq: e});
    };

    // 엑셀 다운로드
    const handleExcel = () => {
        const siteName = getInsuranceReportData?.insuranceReport.length;
        if (siteName < 1) {
            alert('4대보험 업무 내역이 없습니다.');
            return;
        }

        DownLoadExcel({
            txt: '4대보험 업무',
            date: dayjs(selectedDate).format('YYYY-MM'),
            site: `${siteName === 1 ? getInsuranceReportData?.insuranceReport[0].site_nm : ''}`
        });
    };

    useEffect(() => {
        handleAllSites();

    }, []);

//출력
    const handleOpenPrintModal = useCallback(() => {
        setOpendExport(true);

    }, []);

    return (
        <AxiosNavigate>
            <div>
                <Header>상세내역</Header>
                <Content>
                    <Info>
                        <CalendarContainer style={{paddingLeft: '16px'}}>
                            <MonthCalendar onChangeDate={(date) => {
                                setSelectedDate(dayjs(date).format('YYYY-MM-01'));
                                getInsuranceReportMutate({
                                    work_dt: dayjs(date).format('YYYY-MM-01'),
                                    site_seq: selectedSite
                                });
                                if (belongRef.current) {
                                    belongRef.current.clearValue();
                                }
                            }}/>
                        </CalendarContainer>

                        {/*<SiteContainers*/}
                        {/*    data={{*/}
                        {/*        sites,*/}
                        {/*        handleAllSites,*/}
                        {/*        handleSearchSite,*/}
                        {/*        handleSelectedSite*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </Info>
                    {
                        getInsuranceReportData?.total ? <Board>

                                <Panel>
                                    <div>
                                        <BoardHeader>
                                            <h2>4대보험 상세내역</h2>
                                        </BoardHeader>
                                    </div>
                                    <div>
                                        <ButtonGroup>
                                            <Button onClick={handleExcel}><Icon
                                                src="/assets/icons/excel.svg"/> 엑셀파일</Button>
                                            <Button onClick={handleOpenPrintModal}><Icon
                                                src="/assets/icons/printer.svg"/> 출력하기</Button>
                                        </ButtonGroup>
                                    </div>
                                </Panel>
                                <DataContainer>
                                    <div><span>대상 기간</span> <h3>{dayjs(selectedDate).format('YYYY년 M월')}</h3></div>
                                    <div><span>총액</span>
                                        <h3>{getInsuranceReportData?.total?.total_amt.toLocaleString()}원</h3>
                                    </div>
                                    <div></div>
                                    <div></div>
                                    <div><span>국민연금</span>
                                        <h3>{getInsuranceReportData?.total?.sum_of_national_pension.toLocaleString()}원</h3>
                                    </div>
                                    <div><span>건강보험</span>
                                        <h3>{getInsuranceReportData?.total?.sum_of_health_insh.toLocaleString()}원</h3></div>
                                    <div><span>고용보험</span>
                                        <h3>{getInsuranceReportData?.total?.employ_insu.toLocaleString()}원</h3></div>
                                    <div><span>산재보험</span>
                                        <h3>{getInsuranceReportData?.total?.accident_insu.toLocaleString()}원</h3></div>
                                </DataContainer>
                                <TableContainer>
                                    <TableWrapper>
                                        <Table id={'report-table'}>
                                            <thead>
                                            <tr>

                                                <th rowSpan={2}>현장명</th>
                                                <th colSpan={3}>국민연금</th>
                                                <th colSpan={3}>건강보험</th>
                                                <th rowSpan={2}>고용보험</th>
                                                <th rowSpan={2}>산재보험</th>
                                            </tr>
                                            <tr>
                                                <th>회사분</th>
                                                <th>개인분</th>
                                                <th>계</th>
                                                <th>회사분</th>
                                                <th>개인분</th>
                                                <th className={'border'}>계</th>

                                            </tr>
                                            </thead>
                                            <tbody>

                                            {getInsuranceReportData?.insuranceReport?.map((e, i) => (
                                                <tr key={e.site_seq}>
                                                    <td style={{textAlign: 'start'}}>{e.site_nm}</td>
                                                    <td className={'table-right'}>{e.company_national_pension.toLocaleString()}</td>
                                                    <td className={'table-right'}>{e.personal_national_pension.toLocaleString()}</td>
                                                    <td className={'table-right'}>{e.sum_of_national_pension.toLocaleString()}</td>
                                                    <td className={'table-right'}>{e.company_health_insu.toLocaleString()}</td>
                                                    <td className={'table-right'}>{e.personal_health_insu.toLocaleString()}</td>
                                                    <td className={'table-right'}>{e.sum_of_health_insu.toLocaleString()}</td>
                                                    <td className={'table-right'}>{e.employ_insu.toLocaleString()}</td>
                                                    <td className={'table-right'}>{e.accident_insu.toLocaleString()}</td>
                                                </tr>
                                            ))
                                            }
                                            </tbody>
                                            <tfoot>
                                            <tr style={{height: '24px'}}>
                                                <th>합계</th>
                                                <th className={'table-right'}>{getInsuranceReportData?.total?.company_national_pension.toLocaleString()}</th>
                                                <th className={'table-right'}>{getInsuranceReportData?.total?.personal_national_pension.toLocaleString()}</th>
                                                <th className={'table-right bold'}>{getInsuranceReportData?.total?.sum_of_national_pension.toLocaleString()}</th>
                                                <th className={'table-right'}>{getInsuranceReportData?.total?.company_health_insu.toLocaleString()}</th>
                                                <th className={'table-right'}>{getInsuranceReportData?.total?.personal_health_insu.toLocaleString()}</th>
                                                <th className={'table-right bold'}>{getInsuranceReportData?.total?.sum_of_health_insh.toLocaleString()}</th>
                                                <th className={'table-right bold'}>{getInsuranceReportData?.total?.employ_insu.toLocaleString()}</th>
                                                <th className={'table-right bold'}>{getInsuranceReportData?.total?.accident_insu.toLocaleString()}</th>
                                            </tr>

                                            </tfoot>
                                        </Table>
                                    </TableWrapper>
                                </TableContainer>
                            </Board> :
                            <EmptyContainer selected={false} falseText={'상세내역이'}
                                            style={{height: '729px'}}></EmptyContainer>

                    }

                </Content>


                <Modal
                    opend={opendExport}
                    closeModal={() => setOpendExport(false)}
                    header={'출력 미리보기'}
                    okText={'출력'}
                    handleOk={() => {
                        handlePrint();
                    }}
                    widthCheck={'60%'}
                >
                    <LaborInsuranceDetailPrint
                        ref={componentRef}
                        data={{getInsuranceReportData: getInsuranceReportData, date: selectedDate}}
                    />
                </Modal>
            </div>
        </AxiosNavigate>
    )
        ;
};

const Content = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 992px) {
        flex-direction: row;
    }
`;
const Info = styled.div`
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (min-width: 992px) {
        flex-direction: column;
    }
`;
const CalendarContainer = styled.div`
    width: 100%;
    padding: 20px 24px 32px 24px;
    border-radius: 16px;
    border: 1px solid #E2E8F0;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

    @media only screen and (min-width: 768px) {
        width: 30%;
    }

    @media only screen and (min-width: 992px) {
        width: 280px;
    }
`;
const Board = styled.div`
    border-radius: 16px;
    background: #F7FAFC;
    width: 100%;
    margin-top: 24px;
    min-height: 729px;

    @media only screen and (min-width: 992px) {
        margin-top: 0;
    }
`;
const Panel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 32px;
`;
const BoardHeader = styled.div`
    margin-bottom: 14px;
    margin-top: 28px;

    h2, h5 {
        margin: 0;
    }

    h5 {
        margin-top: 4px;
    }
`;
const EmptyReport = styled.div`
    border-radius: 16px;
    background: #F7FAFC;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        text-align: center;
        margin: 0;
        padding: 0;
        color: #A0AEC0;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
    }

    @media only screen and (max-width: 992px) {
        min-height: 333px;
        margin-top: 24px;
    }
`;
const EmptyIcon = styled.img`
`;
const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: left;
        padding: 4px;
        font-size: 12px;
        font-weight: 300;
        border-right: 1px solid #CBD5E0;
        border-bottom: 1px solid #CBD5E0;
        white-space: nowrap;
    }

    th {
        background-color: #e2e8f0;
        text-align: center;
    }

    tfoot th {
        border-bottom: none;
    }

    td {
        background: #fff;
        text-align: center;
    }

`;
const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
`;
const Icon = styled.img`
    width: 16px;
`;

const TableContainer = styled.div`
    padding: 0 32px;
    margin-top: 28px;
`;

const TableWrapper = styled.div`
    border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
`;
const Button = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    width: fit-content;
    min-height: 37px;
    height: 37px;
    gap: 9px;
    border: 1.125px solid #E2E8F0;
    border-radius: 6px;
    background: #FFF;
    color: #4A5568;
    font-size: 14px;
    font-style: normal;
    cursor: pointer;

    leading-trim: both;
    text-edge: cap;
    margin-top: 5px;
`;
const DataContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 20px;
    column-gap: 10px;
    padding: 0 32px;
    box-sizing: border-box;
    margin-top: 15px;

    > div {
        display: flex;
        align-items: center;
        column-gap: 10px;

        span {
            width: 60px;
            display: block;
        }

        h3 {
            margin: 0;
            font-size: 16px;
            white-space: nowrap;
        }
    }
`;
export default LaborInsuranceDetail;
