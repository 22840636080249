import styled from '@emotion/styled';
import {Header} from '../../compontents/Header';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import SideModal from '../../compontents/SideModal';
import Modal from '../../compontents/Modal';
import dayjs from 'dayjs';
import Checkbox from 'react-custom-checkbox';
import {PagingFooter} from '../../compontents/PagingFooter';
import Pagination from 'react-js-pagination';
import ReactSelect from 'react-select';
import axiosInstance from '../../library/axios-index';
import AxiosNavigate from '../../library/axios-navigate';
import {useLocation} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {BlockScrolling, checkRole} from '../../bin/common';
import {RoleType} from '../../bin/enum';
import PagesList from '../../compontents/PagesList';
import EmptyContainer from '../../compontents/EmptyContainer';
import Loading from '../../compontents/Loading';

const checkDefault = {
    company: false,
    worker: false,
};

const selectStyles = {
    control: base => ({
        ...base,
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #E2E8F0',
        },
    })
};

const options = [
    {value: 20, label: '20'},
    {value: 30, label: '30'},
    {value: 50, label: '50'},
    {value: 100, label: '100'}
];

function SettingNotice() {

    const location = useLocation();
    const [cookies] = useCookies(['role']);

    const [loading, setLoading] = useState(true);
    const [selectedNotice, setSelectedNotice] = useState({});
    const [notices, setNotices] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [limit, setLimit] = useState(options[0].value);
    const [page, setPage] = useState(1);

    const [opendDetailNotice, setOpendDetailNotice] = useState(false);
    const [opendNewNotice, setOpendNewNotice] = useState(false);
    const [opendModifyNotice, setOpendModifyNotice] = useState(false);
    const [checks, setChecks] = useState(checkDefault);
    const target = useRef('');
    const closeModal = useRef(false);

    const loadNotice = useCallback(async (noti_seq) => {

        const token = localStorage.getItem('admin_token');

        const {data: {result}} = await axiosInstance.post(`/company/notice`, {
            lang: 'ko',
            uuid: 'string',
            token,
            noti_seq
        });

        setSelectedNotice(result);

        if (result.target === 'A') {
            setChecks({
                company: true,
                worker: true,
            });
        } else if (result.target === 'C') {
            setChecks({
                company: true,
                worker: false,
            });
        } else if (result.target === 'W') {
            setChecks({
                company: false,
                worker: true,
            });
        }

    }, [selectedNotice]);

    BlockScrolling(opendDetailNotice);
    BlockScrolling(opendNewNotice);
    BlockScrolling(opendModifyNotice);
    const loadNotices = async () => {

        const token = localStorage.getItem('admin_token');

        const {data: {result: {notices, totalCount}}} = await axiosInstance.post(`/company/listNotice`, {
            lang: 'ko',
            uuid: 'string',
            token,
            page,
            limit
        });

        setNotices(notices);
        setTotalCount(totalCount);
        setTimeout(() => {
            setLoading(false);
        }, 300);

    };

    const handleDetailNotice = useCallback(async (noti_seq) => {
        await loadNotice(noti_seq);
        setOpendDetailNotice(true);

    }, [selectedNotice]);

    const handleOpendNewNotice = useCallback(() => {
        if (!checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
            return;
        }
        setChecks(checkDefault);
        target.current = '';
        setOpendNewNotice(true);

    }, []);

    useEffect(() => {
        (async () => {
            await loadNotices();
        })();
    }, [page, limit]);

    const checkType = (type) => {
        if (type === 'A') {
            return '전체';
        } else if (type === 'C') {
            return '회사';
        } else if (type === 'W') {
            return '현장';
        }
    };

    // 새 공지 등록
    const handleNewNotice = useCallback(async () => {

        const formData = {};
        formData.subject = document.querySelector('input[name=\'subject\']').value;
        formData.content = document.querySelector('textarea').value;

        if (Object.values(formData).some(value => !value)) {
            alert('빈칸을 모두 채워주세요.');
            closeModal.current = true;
            return;
        }

        if (target.current === '') {
            alert('대상자를 선택해주세요.');
            closeModal.current = true;
            return;
        }

        formData.target = target.current;

        const token = localStorage.getItem('admin_token');

        const {data: {result: done}} = await axiosInstance.post(`/company/newNotice`, {
            lang: 'ko',
            uuid: 'string',
            token,
            ...formData
        });

        if (done) {
            alert('공지사항이 등록되었습니다.');
            await loadNotices();
        } else {
            alert('공지사항 등록에 실패하였습니다.');
        }

    }, []);

    // 공지사항 수정
    const handleOpendModifyNotice = useCallback(async (noti_seq) => {
        if (!checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
            return;
        }
        await loadNotice(noti_seq);
        setOpendModifyNotice(true);

    }, [selectedNotice]);

    const handleModifyNotice = useCallback(async () => {

        const formData = {};
        formData.subject = document.querySelector('input[name=\'subject\']').value;
        formData.content = document.querySelector('textarea').value;


        if (Object.values(formData).some(value => !value)) {
            alert('빈칸을 모두 채워주세요.');
            closeModal.current = true;
            return;
        }

        if (target.current === '') {
            alert('대상자를 선택해주세요.');
            closeModal.current = true;
            return;
        }

        formData.target = target.current;

        const token = localStorage.getItem('admin_token');

        const {data: {result: done}} = await axiosInstance.post(`/company/modifyNotice`, {
            lang: 'ko',
            uuid: 'string',
            token,
            noti_seq: selectedNotice.noti_seq,
            ...formData
        });

        if (done) {
            alert('공지사항이 수정되었습니다.');
            await loadNotices();
        } else {
            alert('공지사항 수정에 실패하였습니다.');
        }

    }, [selectedNotice]);

    useEffect(() => {
        if (checks.company && checks.worker) {
            target.current = 'A';
        } else if (checks.company) {
            target.current = 'C';
        } else if (checks.worker) {
            target.current = 'W';
        } else {
            target.current = '';
        }
    }, [checks]);

    return (
        <AxiosNavigate>
            <div style={{paddingBottom: '30px'}}>
                <Header>
                    <div>
                        공지사항
                    </div>

                    <div>
                        <Button style={{backgroundColor: '#3279F5', color: '#fff'}}
                                onClick={handleOpendNewNotice}>작성하기</Button>
                    </div>
                </Header>

                {loading ? <Loading></Loading> : <>    <TableContainer>
                    <TableWrapper>
                        <Table>
                            <thead>
                            <tr>
                                <th width={'150px'}>
                                    <div style={{marginLeft: '8px'}}>날짜</div>
                                </th>
                                <th>제목</th>
                                <th width={'700px'}>공지내용</th>
                                <th>대상자</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                notices?.length === 0 ? <tr style={{height: '500px'}}>
                                        <td colSpan={4}>
                                            <EmptyContainer falseText={'공지사항이'}
                                                            style={{background: '#fff'}}></EmptyContainer>
                                        </td>
                                    </tr> :
                                    notices.map((e, i) => <tr key={i}>
                                        <td>{dayjs(e.crt_dt).format('YYYY년 M월 D일')}</td>
                                        <td>{e.subject}</td>
                                        <td>
                                            <div style={{
                                                minWidth: '162px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}>
                                                {e.content.length > 20 ? e.content.slice(0, 20) + '...' : e.content}
                                                <Button onClick={() => handleDetailNotice(e.noti_seq)}
                                                        style={{marginTop: '0'}}>상세보기</Button>
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{
                                                minWidth: '102px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}>
                                                {checkType(e.target)}
                                                <Button onClick={() => handleOpendModifyNotice(e.noti_seq)}
                                                        style={{marginTop: '0'}}>수정</Button>
                                            </div>
                                        </td>
                                    </tr>)}
                            </tbody>
                        </Table>
                    </TableWrapper>
                </TableContainer>

                    <PagingFooter>
                        <Pagination
                            prevPageText={(<Icon src="/assets/icons/chevron-left.svg"/>)}
                            nextPageText={(<Icon src="/assets/icons/chevron-right.svg"/>)}
                            activePage={page}
                            itemsCountPerPage={limit}
                            totalItemsCount={totalCount === 0 ? 1 : totalCount}
                            pageRangeDisplayed={5}
                            hideFirstLastPages={true}
                            onChange={(e) => setPage(e)}
                        />
                        <PagesList options={options}
                                   onChange={(e) => {
                                       setLimit(e.value);
                                       setPage(1);
                                   }}></PagesList>

                    </PagingFooter></>}


                <SideModal
                    opend={opendDetailNotice}
                    closeModal={() => setOpendDetailNotice(false)}
                    header={'공지사항 상세'}
                >
                    <NoticeContent>
                        <h5 style={{wordWrap: 'break-word',}}>{selectedNotice.subject}</h5>
                        <h6>{dayjs(selectedNotice.crt_dt).format('YYYY년 M월 D일')}</h6>
                        <hr/>
                        <div style={{whiteSpace: 'pre-line', wordWrap: 'break-word',}}>
                            {selectedNotice.content}
                        </div>
                    </NoticeContent>

                </SideModal>

                <Modal
                    opend={opendNewNotice}
                    closeModal={() => {
                        setOpendNewNotice(closeModal.current);
                        closeModal.current = false;
                    }}
                    header={'공자사항 작성'}
                    okText={'확인'}
                    handleOk={handleNewNotice}
                >
                    <FieldGroup className="new-notice-form">
                        <Field fulled>
                            <h6>날짜</h6>
                            <h5>{dayjs().format('YYYY년 M월 D일')}</h5>
                        </Field>

                        <Field fulled>
                            <h6>대상자</h6>
                            <div style={{display: 'flex'}}>

                                <CustomChecked
                                    onChange={(checked) => {
                                        setChecks({
                                            company: checked,
                                            worker: checked,
                                        });
                                    }}
                                    data={{
                                        text: '전체',
                                        isCheck: checks.company && checks.worker,
                                    }}
                                />

                                <CustomChecked
                                    onChange={(checked) => {
                                        setChecks({
                                            ...checks,
                                            company: checked,
                                        });
                                    }}
                                    data={{
                                        text: '회사',
                                        isCheck: checks.company,
                                    }}
                                />

                                <CustomChecked
                                    onChange={(checked) => {
                                        setChecks({
                                            ...checks,
                                            worker: checked,
                                        });
                                    }}
                                    data={{
                                        text: '현장',
                                        isCheck: checks.worker,
                                    }}
                                />

                            </div>
                        </Field>

                        <Field fulled>
                            <h6>제목</h6>
                            <input name="subject" type="text" maxLength={100}/>
                        </Field>

                        <Field fulled>
                            <h6>내용</h6>
                            <textarea style={{height: '300px'}} name="content"></textarea>
                        </Field>

                    </FieldGroup>

                </Modal>

                <Modal
                    opend={opendModifyNotice}
                    closeModal={() => {
                        setOpendModifyNotice(closeModal.current);
                        closeModal.current = false;
                    }}
                    header={'공지사항 수정'}
                    okText={'확인'}
                    handleOk={handleModifyNotice}
                >
                    <FieldGroup className="modify-notice-form">
                        <Field>
                            <h6>날짜</h6>
                            <h5>{dayjs(selectedNotice.crt_dt).format('YYYY년 M월 D일')}</h5>
                        </Field>

                        <Field fulled>
                            <h6>대상자</h6>
                            <div style={{display: 'flex'}}>

                                <CustomChecked
                                    onChange={(checked) => {
                                        setChecks({
                                            company: checked,
                                            worker: checked,
                                        });
                                    }}
                                    data={{
                                        text: '전체',
                                        isCheck: checks.company && checks.worker,
                                    }}
                                />

                                <CustomChecked
                                    onChange={(checked) => {
                                        setChecks({
                                            ...checks,
                                            company: checked,
                                        });
                                    }}
                                    data={{
                                        text: '회사',
                                        isCheck: checks.company,
                                    }}
                                />

                                <CustomChecked
                                    onChange={(checked) => {
                                        setChecks({
                                            ...checks,
                                            worker: checked,
                                        });
                                    }}
                                    data={{
                                        text: '현장',
                                        isCheck: checks.worker,
                                    }}
                                />

                            </div>
                        </Field>

                        <Field fulled>
                            <h6>제목</h6>
                            <input name="subject" defaultValue={selectedNotice.subject} type="text" maxLength={100}/>
                        </Field>

                        <Field fulled>
                            <h6>내용</h6>
                            {/*<textarea style={{height: '300px'}} name="content">{selectedNotice.content}</textarea>*/}
                            <textarea style={{height: '300px'}} name="content"
                                      defaultValue={selectedNotice.content}></textarea>
                        </Field>

                    </FieldGroup>
                </Modal>

            </div>
        </AxiosNavigate>
    );
}

function CustomChecked(params) {

    const data = params.data;

    return (
        <Checkbox
            checked={data.isCheck}
            onChange={params.onChange}
            icon={
                <CheckboxIcon>
                    <CheckboxIconInner>
                        <CheckboxIconImage src="/assets/icons/ignore.svg"/>
                    </CheckboxIconInner>
                </CheckboxIcon>
            }
            borderColor={data.isCheck ? '#3279F5' : '#E2E8F0'}
            style={{overflow: 'hidden'}}
            label={<div style={{paddingLeft: '12px', cursor: 'pointer'}}>{data.text}</div>}
        />
    );
}

const Button = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    min-height: 32px;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 6px;
    border: 1.125px solid #E2E8F0;
    background: #FFF;

    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    cursor: pointer;

    margin-top: 5px;

`;

const CheckboxIcon = styled.div`
    display: flex;
    flex: 1;
    background-color: #3279F5;
    align-self: stretch;
    cursor: pointer;
`;

const CheckboxIconInner = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CheckboxIconImage = styled.img`
    width: 12px;
    height: 12px;
`;

const TableContainer = styled.div`
    padding: 0 32px;
    margin-top: 28px;
`;

const TableWrapper = styled.div`
    border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: left;
        font-size: 13px;
        font-weight: 300;
        // border-right: 1px solid #CBD5E0;
        border-bottom: 1px solid #CBD5E0;
        white-space: nowrap;
    }

    th {
        //background-color: #F7FAFC;
    }

    td {
        padding: 2px;
    }

    tr:last-child td {
        border-bottom: none;
    }


    td:first-of-type {
        text-align: center;
    }

    thead {
        height: 31px;
    }
`;

const NoticeContent = styled.div`
    h5, h6 {
        margin: 0;
    }

    h5 {
        color: #171923;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

    h6 {
        color: #4A5568;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }

    hr {
        background: #EDF2F7;
        color: #EDF2F7;
        border: 1px solid #EDF2F7;
        margin: 20px 0;
    }

    div {
        color: #4A5568;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }
`;

const FieldGroup = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0 14px;
`;

const Field = styled.div`
    h6 {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
    }

    h5 {
        color: #171923;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 16px 0;
    }

    label {
        display: inline-block;
        padding-right: 26px;
        padding-top: 6px;
        padding-bottom: 12px;
        cursor: pointer;
    }

    input[type=text] {
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-sizing: border-box;
        width: 100%;
        border: 0px;
        outline: none;

        padding: 8px 12px;

        color: #171923;
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        background: #FFF;
        border: 1px solid #CBD5E0;
        border-radius: 6px;
        margin-bottom: 12px;
        margin-top: 6px;

        :focus {

        }
    }

    textarea {
        width: 100%;
        min-height: 80px;
        border-radius: 6px;
        border: 1px solid #E2E8F0;
        box-sizing: border-box;
        margin-top: 6px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        resize: none;
        padding: 8px 12px;
        color: #171923;
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    width: ${({fulled}) => fulled ? `100%` : '48%'};

    @media only screen and (max-width: 992px) {
        width: 100%;
    }
`;


const SelectWrapper = styled.div`
    display: flex;
    align-items: center;

    span {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-right: 6px;
    }
`;
const Icon = styled.img`
    width: 16px;
`;
const Select = styled(ReactSelect)`
    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    border-radius: 6px;
    background: #FFF;
    margin-top: 5px;

    div {
        cursor: pointer;
        color: #4A5568;
        border-color: #E2E8F0;
    }
`;
export default SettingNotice;