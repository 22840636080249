import React, {forwardRef, useEffect, useState} from 'react';
import {InputBox} from '../../library/styled-components';
import styled from '@emotion/styled';
import {useForm} from 'react-hook-form';
import CustomChecked from '../../compontents/CustomChecked';
import {useMutation} from '@tanstack/react-query';
import {modifyWorkAuth} from '../../library/axios-query';


const RoleEmployForm = forwardRef((props, ref) => {
    const {
        register,
        control,
        clearErrors,
        watch,
        handleSubmit,
        reset,
        setValue,
        getValues,
        setFocus,
        formState: {errors}
    } = useForm({
        defaultValues:
            {},

    });
    const {data, employInfor, loadEmployees, setOpendRoleEmploy, setOpendDetailEmploy} = props;
    const [roleOptions, setRoleOptions] = useState([]);
    const [allCheck, setAllCheck] = useState(false);

    // 근로자 업무 권한 변경
    const {mutate: modifyWorkAuthMutate} = useMutation({
        mutationKey: ['modifyWorkAuth'],
        mutationFn: modifyWorkAuth,
        onSuccess: async (data) => {
            if (data.done) {
                alert('수정되었습니다.');
                await loadEmployees();
                setOpendRoleEmploy(false);
                setOpendDetailEmploy(false);
            }
        },
        onError: (error) => {
            throw error;
        }
    });


    useEffect(() => {

        data.map((item, index) => {
            setRoleOptions(prevState => ([...prevState, {
                value: item.dtl_cd_nm,
                label: item.dtl_cd_nm,
                checked: employInfor?.work_auth.split('')[index] === '1'
            }]));
        });
    }, []);

    const onSubmit = (e) => {
        const items = {
            userSiteSeq: employInfor.user_site_seq,
            workAuth: roleOptions.map(item => item.checked ? 1 : 0).join('')
        };

        modifyWorkAuthMutate(items);
    };

    useEffect(() => {
        if (roleOptions.length === 0) return;
        if (roleOptions.every(item => item.checked)) {
            setAllCheck(true);
        } else {
            setAllCheck(false);
        }
    }, [roleOptions]);


    return (
        <FieldGroup className="role-employ-form" ref={ref} onSubmit={handleSubmit(onSubmit)}>

            <InputBox fulled>
                <div style={{display: 'flex', rowGap: '8px', flexDirection: 'column'}}>
                    <CustomChecked
                        onChange={(checked) => {
                            setAllCheck(checked);
                            setRoleOptions(prevState => prevState.map(item => ({
                                ...item,
                                checked: checked
                            })));
                        }}
                        data={{
                            text: '전체관리',
                            isCheck: allCheck
                        }}
                    />
                    {
                        roleOptions && roleOptions?.map((e, i) => (
                            <CustomChecked
                                key={e.value}
                                onChange={(checked) => {
                                    setRoleOptions(prevState => prevState.map(item => item.value === e.value ? {
                                        ...item,
                                        checked: checked
                                    } : item));
                                }}
                                data={{
                                    text: e.label,
                                    isCheck: e.checked
                                }}
                            />
                        ))
                    }
                </div>
            </InputBox>
        </FieldGroup>
    );
});
const FieldGroup = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 10px;

    > div {
        row-gap: 8px;
        display: flex;
        flex-direction: column;
    }

    textarea {
        width: 100%;
        min-height: 80px;
        border-radius: 6px;
        border: 1px solid #CBD5E0;
        box-sizing: border-box;
        // margin-top: 6px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        resize: none;
        padding: 8px 12px;
        color: #171923;
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    input {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }

    label {
        margin-left: 0;

        > span,
        input {
            padding: 0;

        }

        .MuiFormControlLabel-label {
            font-size: 15px;
        }

    }
`;
export default RoleEmployForm;