import React, {useEffect, useRef, useState} from 'react';
import AxiosNavigate from '../../library/axios-navigate';
import {Header} from '../../compontents/Header';
import styled from '@emotion/styled';
import {PagingFooter} from '../../compontents/PagingFooter';
import Pagination from 'react-js-pagination';
import PagesList from '../../compontents/PagesList';
import moment from 'moment';
import SideModal from '../../compontents/SideModal';
import {Content} from '../../library/styled-components';
import {ModalButton} from '../../compontents/ModalButton';
import Modal from '../../compontents/Modal';
import NewApplyForm from './new-apply-form';
import axiosInstance from '../../library/axios-index';
import {FormProvider, useForm} from 'react-hook-form';
import ModifyApplyForm from './modify-apply-form';
import {useMutation} from '@tanstack/react-query';
import {getDiscountApply, getDiscountRule} from '../../library/admin-react-query';
import {addCommas} from '../../bin/common';
import SearchBox from '../../compontents/Search';
import { TableStyle } from '../../library/styled-components';
const options = [
    {value: 20, label: '20'},
    {value: 30, label: '30'},
    {value: 50, label: '50'},
    {value: 100, label: '100'}
];


const DiscountApplyAdmin = () => {
    const formRef = useRef();
    const closeModal = useRef(false);
    const methods = useForm();

    const [totalCount, setTotalCount] = useState(0);
    const [limit, setLimit] = useState(options[0].value);
    const [page, setPage] = useState(1);

    // 회사 정보
    const [company, setCompany] = useState([]);

    const [selectInfo, setSelectInfo] = useState({});

    const [openedApplyForm, setOpenedApplyForm] = useState(false);
    const [openedModifyForm, setOpenedModifyForm] = useState(false);
    const [openedDetail, setOpenedDetail] = useState(false);
    const [searchTxt, setSearchTxt] = useState(''); // 엔터
    const [searchValue, setSearchValue] = useState(''); // 키보드

    // 할인 정책 정보
    const {mutate: getDiscountApplyMutate, data: getDiscountApplyData} = useMutation({
        mutationKey: ['getDiscountApply'],
        mutationFn: getDiscountApply,
        onSuccess: data => {
            setTotalCount(data.totalCount);
            // console.log('안녕1', data);
            if (openedDetail) {
                setSelectInfo(data.list.find(e => e.dscnt_seq === selectInfo.dscnt_seq));
            }
        },
        onError: error => {
            console.log(error);
        }
    });


    // 할인 정책 정보
    const {mutate: getDiscountRuleMutate, data: getDiscountRuleData} = useMutation({
        mutationKey: ['getDiscountRule'],
        mutationFn: getDiscountRule,
        onSuccess: data => {
            // console.log('안녕2', data);

        },
        onError: error => {
            alert(error.message);
        }
    });
    const getCompanyList = async (search_txt, page, limit) => {
        try {
            const {data: {result: {list, count}}} = await axiosInstance.post(`/company/list`, {
                search_txt,
                page: 1,
                limit: 1000
            });
            
            setCompany(list.filter(e => e.corp_seq !== 30));
        } catch (e) {
            return alert('회사 정보를 가져오기를 실패 하였습니다. 다시 시도해 주세요');
        }

    };

    useEffect(() => {

        (async () => {
            try {
                await getCompanyList('', 1, 1000);
            } catch (e) {

            }
        })();

        getDiscountRuleMutate({
            page: 1,
            limit: 100
        });
    }, []);

    useEffect(() => {
        getDiscountApplyMutate({
            page: page,
            limit: limit,
            search_txt: searchTxt
        });
    }, [page, limit, searchTxt]);
    // 할인 정책 수정
    const handleSelectedModify = () => {
        setOpenedApplyForm(true);
    };

    const handleFinishAction = (value) => {
        if (value === 'create') {
            setOpenedApplyForm(false);
            setPage(1);
            setLimit(20);

        } else if (value === 'update') {
            setOpenedModifyForm(false);
        }
        //  getDiscountApplyMutate();
        getDiscountApplyMutate({
            page: page,
            limit: limit,
            search_txt: ''
        });
    };

    return (
      <AxiosNavigate>
        <div>
          <FormProvider {...methods}>
            <Header>
              <div>할인 적용</div>
              <div style={{ display: "flex", columnGap: "16px" }}>
                <SearchBox
                  placeholder={"법인명 검색"}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      e.nativeEvent.isComposing === false
                    ) {
                      if (
                        e.target.value.length > 0 &&
                        e.target.value.trim().length === 0
                      ) {
                        alert("법인명을 입력해 주세요");
                        setSearchValue(e.target.value.trim());
                        return;
                      }
                      setSearchTxt(e.target.value);
                      setSearchValue(e.target.value.trim());
                      setPage(1);
                    }
                  }}
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                ></SearchBox>
                <Button
                  style={{ backgroundColor: "#3279F5", color: "#fff" }}
                  onClick={(event) => {
                    event.preventDefault();
                    setOpenedApplyForm(true);
                  }}
                >
                  작성하기
                </Button>
              </div>
            </Header>

            <TableStyle>
              <TableWrapper>
                <table>
                  <thead>
                    <tr>
                      <th width={"70px"}>구분</th>
                      <th>법인명</th>
                      <th width={"200px"}>아이디</th>
                      <th width={"150px"}>할인 정책</th>
                      <th width={"100px"}>할인율/금액</th>
                      <th width={"200px"}>기간</th>
                      <th width={"150px"}>등록일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getDiscountApplyData?.list &&
                      getDiscountApplyData?.list?.map((e, i) => (
                        <tr key={i}>
                          <td>{i + limit * (page - 1) + 1}</td>
                          <td className={"table-left"}>
                            <div
                              style={{
                                // width: '50%',
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {e.corp_nm}
                              <Button
                                onClick={() => {
                                  setSelectInfo(e);
                                  setOpenedDetail(true);
                                }}
                                style={{ marginLeft: "8px" }}
                              >
                                상세보기
                              </Button>
                            </div>
                          </td>
                          <td>{e.corp_id}</td>
                          <td>{e.dscnt_title}</td>
                          <td>
                            {e.dscnt_type === "FR"
                              ? e.dscnt_amt + "%"
                              : addCommas(e.dscnt_amt) + "원"}
                          </td>
                          <td>
                            {moment(e.start_dt).format("YYYY년 M월")} ~{" "}
                            {moment(e.end_dt).format("YYYY년 M월")}
                          </td>
                          <td>
                            {moment().utc(e.crt_dt).format("YYYY년 M월 D일")}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </TableWrapper>
            </TableStyle>

            <PagingFooter>
              <Pagination
                prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
                nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
                activePage={page}
                itemsCountPerPage={limit}
                totalItemsCount={totalCount === 0 ? 1 : totalCount}
                pageRangeDisplayed={5}
                hideFirstLastPages={true}
                onChange={(e) => setPage(e)}
              />
              <PagesList
                options={options}
                onChange={(e) => {
                  setLimit(e.value);
                  setPage(1);
                }}
              ></PagesList>
            </PagingFooter>

            <SideModal
              opend={openedDetail}
              closeModal={() => setOpenedDetail(false)}
              header={"할인 적용 상세"}
            >
              {selectInfo && (
                <Side>
                  <Content>
                    <figure>
                      <h6>할인 제목</h6>
                      <h5>{selectInfo?.dscnt_title}</h5>
                    </figure>
                    <figure>
                      <h6>할인 내용</h6>
                      <h5>{selectInfo?.dscnt_desc}</h5>
                    </figure>
                    <figure>
                      <h6>할인율/금액</h6>
                      <h5>
                        {selectInfo.dscnt_type === "FR"
                          ? selectInfo.dscnt_amt + "%"
                          : addCommas(selectInfo.dscnt_amt) + "원"}
                      </h5>
                    </figure>
                    <figure>
                      <h6>기간</h6>
                      <h5>
                        {moment(selectInfo.start_dt).format("YYYY년 M월")} ~{" "}
                        {moment(selectInfo.end_dt).format("YYYY년 M월")}
                      </h5>
                    </figure>
                  </Content>

                  <div style={{ display: "grid" }} className={"grid"}>
                    <ModalButton onClick={() => setOpenedDetail(false)}>
                      취소
                    </ModalButton>
                    <ModalButton onClick={() => setOpenedModifyForm(true)}>
                      수정
                    </ModalButton>
                  </div>
                </Side>
              )}
            </SideModal>

            <Modal
              opend={openedApplyForm}
              closeModal={() => {
                setOpenedApplyForm(closeModal.current);
                closeModal.current = false;
              }}
              header={"할인 적용 등록"}
              formRef={formRef}
              cancelText={"취소"}
              okText={"확인"}
              widthCheck={"950px"}
              style={{ maxHeight: "950px" }}
            >
              <NewApplyForm
                ref={formRef}
                data={company}
                action={handleFinishAction}
                dataOption={getDiscountRuleData?.list}
              ></NewApplyForm>
            </Modal>
            <Modal
              opend={openedModifyForm}
              closeModal={() => {
                setOpenedModifyForm(closeModal.current);
                closeModal.current = false;
              }}
              header={"할인 적용 수정"}
              formRef={formRef}
              cancelText={"취소"}
              okText={"확인"}
              widthCheck={"950px"}
            >
              <ModifyApplyForm
                ref={formRef}
                data={selectInfo}
                action={handleFinishAction}
              ></ModifyApplyForm>
            </Modal>
          </FormProvider>
        </div>
      </AxiosNavigate>
    );
};

const Side = styled.div`
    //height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    box-sizing: border-box;

    .grid {
        grid-template-columns: repeat(2, 100px);
        margin-left: auto;
    }

    > div > figure {
        h6 {
            min-width: 80px;
        }

        a {
            text-decoration: none;
            color: #3279f5;
        }

        h5 p {
            margin: 0;
        }

    }

    > div > figure:last-child {
        div {
            display: grid;
            gap: 5px;
            grid-template-columns: repeat(1, 1fr);


        }

        h5 {
            cursor: pointer;
        }
    }

    > div > figure:nth-of-type(3) {
        h5 {
            width: calc(100% - 80px);

            img {
                width: auto;
                max-width: 100%;
            }
        }
    }


    figure:nth-of-type(3) {
        * {
            width: auto !important;
            max-width: 100% !important;
        }

        table {
            width: 100% !important;
        }
    }
`;
const Icon = styled.img`
    width: 16px;
`;
const Button = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    min-height: 32px;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 6px;
    border: 1.125px solid #E2E8F0;
    background: #FFF;
    box-sizing: border-box;
    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    cursor: pointer;

    //    margin-top: 5px;

`;
const TableContainer = styled.div`
    padding: 0 32px;
    margin-top: 28px;
`;

const TableWrapper = styled.div`
    /* border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto; */
      th, td {
        text-align: center;
        }
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: center;
        padding: 8px;
        font-size: 13px;
        font-weight: 300;
        border-right: 1px solid #CBD5E0;
        border-bottom: 1px solid #CBD5E0;
        white-space: nowrap;
    }

    th {
        background-color: #F7FAFC;
    }

    tr:last-child td {
        border-bottom: none;
    }

`;
export default DiscountApplyAdmin;

