import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import styled from "@emotion/styled";
const NaverMap = forwardRef((props, ref) => {
  const { addressList, openMapMode } = props;
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const [marker, setMarker] = useState(null);
  const [address, setAddress] = useState("");
  const [addr2, setAddr2] = useState("");
  const [zoneCode, setZoneCode] = useState("");
  const [logitude, setLogitude] = useState(
    openMapMode?.items?.logitude ? openMapMode?.items?.logitude : 126.9784229
  ); //세로
  const [latitude, setLatitude] = useState(
    openMapMode?.items?.latitude ? openMapMode?.items?.latitude : 37.5683629
  );
  const [mapInfo, setMapInfo] = useState(openMapMode?.items);

  // var position = new window.naver.maps.LatLng(37.5683629, 126.9784229);
  useEffect(() => {
    if (window.naver && window.naver.maps) {
      initializeMap();
    } else {
      console.error("Naver Maps API가 로드되지 않았습니다.");
    }
  }, []);

  const initializeMap = () => {
    const map = new window.naver.maps.Map(mapRef.current, {
      center: new window.naver.maps.LatLng(latitude, logitude),
      zoom: 17,
      zoomControl: false,
      logoControl: false,
    });
    const infoWindow = new window.naver.maps.InfoWindow({
      anchorSkew: true,
    });
    map.setCursor("pointer");
    setMap(map);
    setInfoWindow(infoWindow);

    var marker = new window.naver.maps.Marker({
      position: new window.naver.maps.LatLng(latitude, logitude),
      map: map,
    });
    setMarker(marker);

    map?.addListener("click", (e) => {
      marker.setPosition(e.coord);
      searchCoordinateToAddress(e.latlng);
    });
  };

  useEffect(() => {
    // initializeMap();
  }, [latitude, logitude, address]);

  const searchCoordinateToAddress = (latlng) => {
    window.naver.maps.Service.reverseGeocode(
      {
        coords: new window.naver.maps.LatLng(latlng.y, latlng.x),
        orders: [
          window.naver.maps.Service.OrderType.ADDR,
          window.naver.maps.Service.OrderType.ROAD_ADDR,
        ].join(","),
      },
      function (status, response) {
        if (status !== window.naver.maps.Service.Status.OK) {
          return alert("Something wrong!");
        }

        var result = response.v2, // 검색 결과의 컨테이너
          items = result.results, // 검색 결과의 배열
          address = result.address; // 검색 결과로 만든 주소

        setAddress(
          !address?.roadAddress
            ? address?.jibunAddress
                ?.replaceAll("특별시", "")
                ?.replaceAll("광역시", "")
                ?.replaceAll("특별자치도", "")
            : address?.roadAddress
                ?.replaceAll("특별시", "")
                ?.replaceAll("광역시", "")
                ?.replaceAll("특별자치도", "")
        );

        handleSearchAddress(
          !address?.roadAddress ? address?.jibunAddress : address?.roadAddress
        );
      }
    );
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleSearchAddress = (address) => {
    if (!address) return;
    window?.naver?.maps?.Service?.geocode(
      {
        query: address,
      },
      function (status, response) {
        if (status !== window.naver.maps.Service.Status.OK) {
          return alert("Something wrong!");
        }
        if (response.v2.meta.totalCount === 0) {
          return alert("주소가 옳바르지 않습니다. 다시 확인해 주세요");
        }
        var result = response.v2, // 검색 결과의 컨테이너
          items = result.addresses; // 검색 결과의 배열
        const point = new window.naver.maps.Point(items[0].x, items[0].y);

        if (!items[0]) {
          // alert("주소가 옳바르지 않습니다. 다시 확인해 주세요");
          return;
        }
        setZoneCode(items[0]?.addressElements[8].longName);
        setLogitude(Number(items[0]?.x).toFixed(10));
        setLatitude(Number(items[0]?.y).toFixed(10));
        setAddress(
          !items[0]?.roadAddress
            ? items[0]?.jibunAddress
                ?.replaceAll("특별시", "")
                ?.replaceAll("광역시", "")
                ?.replaceAll("특별자치도", "")
            : items[0]?.roadAddress
                ?.replaceAll("특별시", "")
                ?.replaceAll("광역시", "")
                ?.replaceAll("특별자치도", "")
        );
        map?.setCenter(point);
        marker?.setPosition(items[0]);
      }
    );
  };
  useEffect(() => {
    if (openMapMode?.modeName === "modify") {
      setAddress(openMapMode?.items?.addr1);
      setAddr2(openMapMode?.items?.addr2);
      setZoneCode(openMapMode?.items?.postal_cd);
      handleSearchAddress(openMapMode?.items?.addr1);
    }
  }, [openMapMode]);

  // 부모 컴포넌트로 데이터 넘기기 위한 함수
  const setAddrData = () => {
    const subAddr = document.getElementById("addr2").value;
    if (props.hasOwnProperty("setAddrInfo")) {
      if (address == "") {
        alert("주소를 입력해 주세요");
        return;
      }

      const isDuplicate = addressList?.some(
        (item) =>
          item?.addr_seq !== mapInfo?.addr_seq &&
          item?.addr1 === address &&
          (item?.addr2 === null && "") === (!addr2 && "")
      );
      if (isDuplicate) {
        alert("중복된 주소가 있습니다.");
        return;
      }

      props.setOpendMap(false);
      props.setAddrInfo(
        address,
        subAddr,
        zoneCode,
        logitude,
        latitude,
        props.openMapMode
      );
    }
  };
  useImperativeHandle(ref, () => ({
    setAddrData,
  }));

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <Input
          id={"addr"}
          placeholder={
            "주소 또는 동으로 검색 후, 지도에서 위치를 선택해 주세요"
          }
          value={address}
          onChange={handleAddressChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (address === "") {
                alert("주소가 옳바르지 않습니다. 다시 확인해 주세요");
                return;
              }
              handleSearchAddress(address);
            }
          }}
        />
        <Button
          style={{ marginTop: "3px" }}
          onClick={(e) => {
            if (address === "") {
              alert("주소가 옳바르지 않습니다. 다시 확인해 주세요");
              return;
            }
            handleSearchAddress(address);
          }}
        >
          검색
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <Input
          id="addr2"
          maxLength={50}
          placeholder={"상세 주소를 입력해 주세요"}
          value={addr2}
          style={{ width: "100%" }}
          onChange={(e) => setAddr2(e.target.value)}
        />
      </div>

      <div ref={mapRef} style={{ width: "500px", height: "500px" }}></div>
    </div>
  );
});

const Input = styled.input`
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  appearance: none;
  box-sizing: border-box;
  width: 90%;
  border: 0px;
  outline: none;
  padding: 8px 12px;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  background: #fff;
  border: 1px solid #cbd5e0;
  border-radius: 6px;
  margin-bottom: 12px;

  :focus {
  }
`;
const Button = styled.button`
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #3279f5;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
`;
export default NaverMap;
