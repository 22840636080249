import React, {useEffect} from 'react';
import {InputBox} from '../../library/styled-components';
import styled from '@emotion/styled';
import {numberOnly} from '../../bin/common';
import {useForm} from 'react-hook-form';
import axiosInstance from '../../library/axios-index';

const fieldsOrder = ['phone_num'
    , 'phone_YN',
    'phone_num',
    'certKey_num',
    'certKey_YN'];

const ModifyPhoneModal = ({formRef, phoneNum, handleResetPhone}) => {
    const {register, handleSubmit, getValues, setValue, setFocus, watch, reset, formState: {errors}} = useForm({
        defaultValues: {
            phone_num: '',
            phone_YN: false,
            certKey_num: '',
            certKey_YN: false,
        }
    });

    const isPhoneNum = watch('phone_num');
    const isPhoneYN = watch('phone_YN');
    const isCertKeyYN = watch('certKey_YN');
    useEffect(() => {
        if (isPhoneNum) {
            setValue('phone_YN', false);
            setValue('certKey_YN', false);
            setValue('certKey_num', '');
        }
    }, [isPhoneNum, setValue]);

    useEffect(() => {
        if (isPhoneYN) {
            setValue('phone_YN', true);
        } else {
            setValue('phone_YN', false);
        }
    }, [isPhoneYN, setValue]);

    useEffect(() => {
        if (isCertKeyYN) {
            setValue('certKey_YN', true);
        } else {
            setValue('certKey_YN', false);
        }
    }, [isCertKeyYN, setValue]);

    const onSubmit = async (e) => {
        handleResetPhone(e.phone_num);
    };

    // 신규 등록 오류
    const onError = (errors) => {
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            const firstError = fieldsOrder.find(key => errorKeys.includes(key));
            setFocus(firstError);

            if (errors[firstError].type === 'noSpaces') {
                setValue(firstError, '');
            }
            alert(errors[firstError]?.message || 'Form validation error');
        }
    };

    // 문자 전송
    const sendPhoneMessage = async () => {
        if (getValues('phone_num') === phoneNum) {
            alert('이전 휴대폰 번호와 동일합니다');
            return;
        }
        if (getValues('phone_num') === '') {
            alert('휴대폰 번호를 입력해주세요');
            return;
        }

        if (getValues('phone_num').length < 11) {
            alert('휴대폰 번호를 확인해주세요');
            return;
        }
        try {
            await axiosInstance.post('/sms/smsSend', {
                lang: 'ko',
                phone_numb: getValues('phone_num'),
                platform: 'WEB',
            });

            setValue('phone_YN', true);
            setFocus('certKey_num');
            setTimeout(() => {
                if (getValues('phone_YN') === false) {
                    setValue('phone_YN', false);
                }
            }, 180000);
        } catch (e) {
            alert(e.response.data.message);
        }
    };

    // 인증번호 확인
    const checkCertKey = async () => {
        if (getValues('certKey_YN') === '') {
            alert('인증번호를 입력해주세요');
            return;
        }

        if (getValues('certKey_YN').length < 6) {
            alert('인증번호를 확인해주세요');
            return;
        }
        await axiosInstance.post('/sms/appSmsCert', {
            lang: 'ko',
            phone_numb: getValues('phone_num'),
            cert_numb: getValues('certKey_num'),
        }).then(({data: {result: result}}) => {
            if (result.done === true) {
                setValue('certKey_YN', true);
                alert('인증되었습니다');
            } else {
                alert('인증번호가 일치하지 않습니다');
                setFocus('certKey_num');
            }
        }).catch(({response: {data}}) => {
            alert(data.message);
            setFocus('certKey_num');
            setValue('certKey_YN', false);
            setValue('certKey_num', '');
            // setValue('phone_YN', false);
        });
    };

    return (

        <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
            <InputBox fulled>
                <h6>휴대폰번호(관리자)</h6>
                <div style={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                    columnGap: '8px'
                }}>
                    <input name="phone_num"
                           placeholder={'- 제외한 숫자만 입력해주세요'}
                           maxLength="11"
                           onChange={(e) => {
                               numberOnly(e);
                               setValue('phone_YN', false);
                           }}
                           {...register('phone_num', {
                               validate: value => value.toString().length > 10 || '휴대폰 번호를 입력해주세요'
                           })}
                           style={{width: 'calc(100% - 101px)', margin: '4px 0 6px'}}
                           autoFocus={true}
                           type="text"/>
                    <FieldButton style={{
                        width: '93px', fontWeight: '600'
                    }} disabled={getValues('phone_YN')} onClick={sendPhoneMessage} {...register('phone_YN', {
                        validate: value => value || '휴대폰번호를 인증해주세요'
                    })}>
                        <span style={{fontSize: '14px'}}>인증번호 전송</span>
                    </FieldButton>
                </div>
                <div style={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                    columnGap: '8px'
                }}>
                    <input name="certKey_num"
                           placeholder={'인증번호 6자리를 입력해주세요'}
                           onChange={(e) => {
                               numberOnly(e);
                           }}
                           {...register('certKey_num', {
                               validate: value => value.toString().length > 5 || '인증번호를 입력해주세요'
                           })}
                           style={{width: 'calc(100% - 101px)', margin: '4px 0 6px'}}
                           maxLength="6"
                           type="text"/>
                    <FieldButton style={{
                        width: '93px',
                        fontWeight: '600'

                    }} disabled={getValues('certKey_YN')}
                                 onClick={checkCertKey}
                                 {...register('certKey_YN', {
                                     validate: value => value || '인증번호를 인증해주세요'
                                 })}
                    >
                        <span style={{fontSize: '14px'}}>인증하기</span>
                    </FieldButton>
                </div>
            </InputBox>
        </form>

    );
};
const FieldButton = styled.div`
    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    box-sizing: border-box;
    display: inline-block;
    padding: 8px;
    border-radius: 6px;
    background: #4A5568;
    // margin-left: 8px;
    cursor: pointer;
    text-align: center;

    ${props => props.disabled && `
  background: #CBD5E0;
  cursor: default;
`}
`;
export default ModifyPhoneModal;
