import React, {forwardRef, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {InputBox} from '../../library/styled-components';
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import SelectedFilterBox from '../../compontents/SelectedFilterBox';
import SearchBox from '../../compontents/Search';
import {addCommas} from '../../bin/common';
import {useMutation} from '@tanstack/react-query';
import {createDiscountApply} from '../../library/admin-react-query';

const fieldsOrder = [
    'discount_type',
    'price',
    'month_type',
    'month_data',
    'subject',
    'explanation',
    'target',
];


const NewApplyForm = forwardRef((props, ref) => {
    const {action} = props;
    const data = Array.isArray(props?.data) ? props?.data?.map((e) => ({
        name: e.corp_nm,
        seq: e.corp_seq
    })) : [];

    const discountType = Array.isArray(props?.data) ? props?.dataOption?.map((e) => ({
        label: e.dscnt_title,
        value: e.dscnt_seq
    })) : [];


    const [searchTxt, setSearchTxt] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [company, setCompany] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [selectInfo, setSelectInfo] = useState({});

    const {
        watch,
        register,
        control,
        handleSubmit,
        reset,
        setValue,
        getValues,
        setFocus,
        formState: {errors}
    } = useForm({
        defaultValues:
            {
                discount_type: '',
            }
    });
    const isDiscount = watch('discount_type');
    const useYN = watch('target');

// 할인 적용 생성
    const {mutate: createDiscountApplyMutate} = useMutation({
        mutationKey: ['createDiscountApply'],
        mutationFn: createDiscountApply,
        onSuccess: data => {
            if (data.done) {
                action('create');
            }
        },
        onError: error => {
            alert(error.message);
        }
    });


    useEffect(() => {
        setSelectInfo(props?.dataOption?.find(e => e.dscnt_seq === getValues('discount_type').value));
    }, [isDiscount]);

    useEffect(() => {
        const text = searchTxt.toLowerCase().replaceAll(' ', '');
        const list = data.filter(e => {
            return e.name.toLowerCase().replaceAll(' ', '').includes(text);
        });

        setCompany(list);
    }, [searchTxt]);

    useEffect(() => {
        setCompany(data);
    }, []);
    const onSubmit = (e) => {
        if (selectedCompany?.length === 0) {
            alert('법인을 선택해주세요');
            return;
        }
        const items = {
            dscnt_seq: e.discount_type.value,
            target: Number(e.target),
            corp_seq: selectedCompany.map(e => e.seq)
        };

        createDiscountApplyMutate(items);
    };

    const onError = (errors) => {
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            const firstError = fieldsOrder.find(key => errorKeys.includes(key));
            setFocus(firstError);

            if (errors[firstError].type === 'noSpaces') {
                setValue(firstError, '');
            }
            alert(errors[firstError]?.message || 'Form validation error');
        }
    };
    return (
        <FieldGroup className="discount-apply-form" ref={ref} onSubmit={handleSubmit(onSubmit, onError)}>
            <InputBox fulled>
                <h6>할인 정책</h6>
                <Controller
                    name="discount_type"
                    control={control}
                    rules={{required: '할인 정책을 선택해주세요'}}
                    render={({field}) => (
                        <SelectedFilterBox  {...field}
                                            placeholder={'할인 정책'}
                                            style={{width: '100%', height: '39px'}}
                            //   value={field.value ?? ''}
                                            onChange={(e) => {
                                                setValue('discount_type', {
                                                    value: e.value,
                                                    label: e.label
                                                });
                                                setValue('price', '0');
                                            }}
                                            options={discountType}></SelectedFilterBox>

                    )}
                />
            </InputBox>
            {
                isDiscount && selectInfo && (<><InputBox fulled>
                    <h6>할인 내용</h6>
                    <h5>{selectInfo?.dscnt_desc}</h5>
                </InputBox>
                    <InputBox fulled>
                        <h6>할인율/금액</h6>
                        <h5>{selectInfo.dscnt_type === 'FR' ? selectInfo.dscnt_amt + '%' : addCommas(selectInfo.dscnt_amt) + '원'}</h5>
                    </InputBox>
                    <InputBox fulled>
                        <h6>기간</h6>
                        <h5>{selectInfo.date_type === 'M' ? selectInfo.period + ' 개월' : selectInfo.period + ' 년'}</h5>
                    </InputBox></>)
            }
            <InputBox>
                <Controller
                    name="target"
                    control={control}
                    rules={{required: '대상을 선택해주세요'}}
                    render={({field}) => (
                        <FormControl style={{rowGap: '8px', flexDirection: 'column'}}>
                            <h6>대상</h6>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                {...field}
                                value={field.value ?? ''} // 폼 상태와 라디오 버튼 동기화
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setValue('target', value); // 폼 상태를 업데이트
                                    setSearchValue('');
                                    // 라디오 버튼 선택에 따라 로직 실행
                                    if (value === '0') {
                                        setCompany([]); // 전체 선택 시 회사 목록 초기화
                                        setSelectedCompany(data); // 전체 선택한 경우 모든 회사 선택
                                    } else {
                                        setCompany(data); // 개별 선택 시 회사 목록을 다시 설정
                                        setSelectedCompany([]); // 선택한 회사를 초기화
                                    }
                                }}
                            >
                                <FormControlLabel
                                    value="0"
                                    control={<Radio size="small"/>}
                                    label="전체"
                                    sx={{fontSize: '14px'}}
                                />
                                <FormControlLabel
                                    value="1"
                                    control={<Radio size="small"/>}
                                    label="개별"
                                    sx={{fontSize: '14px'}}
                                />
                            </RadioGroup>
                        </FormControl>
                    )}
                />
            </InputBox>

            <InputBox fulled>
                <h6>법인</h6>
                <SearchWrap>

                    {
                        useYN === '1' && <>   <SearchBox style={{height: '39px'}} placeholder={'법인을 검색해주세요'}
                                                         onKeyDown={(e) => {
                                                             if (e.key === 'Enter' && e.nativeEvent.isComposing === false) {
                                                                 if (e.target.value.length > 0 && e.target.value.trim().length === 0) {
                                                                     alert('법인을 입력해 주세요');
                                                                     setSearchValue(e.target.value.trim());
                                                                     return;
                                                                 }

                                                                 setSearchTxt(e.target.value);
                                                                 setSearchValue(e.target.value.trim());
                                                             }
                                                         }} value={searchValue} onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}> </SearchBox> <CompanyList>
                            {company?.map((e, i) =>
                                <CompanyItem
                                    key={e.seq}
                                    id={'siteItem' + e.seq}
                                    className={selectedCompany.some(item => item.seq === e.seq) ? 'action' : ''}
                                    onClick={(event) => {
                                        const test = selectedCompany.some(item => {
                                            return e.seq === item.seq;
                                        });
                                        if (test) {
                                            return;
                                        }
                                        setSelectedCompany(prevState => [...prevState, e]);
                                    }}
                                >{e.name}</CompanyItem>)}
                        </CompanyList></>
                    }
                    <SelectedList>
                        {
                            selectedCompany.map((e, i) => <Label key={i}
                                                                 style={{cursor: 'pointer'}}>{e.name}
                                <Icon style={{marginLeft: '2px'}} onClick={(event) => {
                                    event.preventDefault();
                                    setSelectedCompany(prevState => prevState.filter(item => item.seq !== e.seq));
                                    //   setValue('target', 'N');
                                }}
                                      src="/assets/icons/left-icon.svg"/></Label>)
                        }
                    </SelectedList>
                </SearchWrap>
            </InputBox>
        </FieldGroup>
    );
});
const Icon = styled.img`
    width: 16px;
`;
const Label = styled.span`
    padding: 2px 4px;
    align-items: center;
    margin-right: 6px;
    border-radius: 6px;
    //border: 1px solid #E2E8F0;
    background-color: #D7FBE2;
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    width: fit-content;
    display: flex;
    height: 30px;
    box-sizing: border-box;
`;
const SelectedList = styled.div`
    overflow: auto;
    min-height: 250px;
    height: 250px;
    padding: 7px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #CBD5E0;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    row-gap: 6px;
`;
const CompanyList = styled.div`
    overflow: auto;
    height: 250px;
    margin: 5px 0;
    box-sizing: border-box;
    background-color: #F7FAFC;
    //border-radius: 4px;
    //border: 1px solid #CBD5E0;
`;

const CompanyItem = styled.div`
    color: #4A5568;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 8px;
    cursor: pointer;


    &&.action {
        background-color: #D7FBE2;
    }

    :hover {
        background: rgba(0, 0, 0, 0.05) !important;
    }

`;


const SearchWrap = styled.div`
    input {
        border: none;
    }


`;

const FieldGroup = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 10px;

    > div {
        row-gap: 8px;
        display: flex;
        flex-direction: column;
    }

    textarea {
        width: 100%;
        border-radius: 6px;
        border: 1px solid #CBD5E0;
        box-sizing: border-box;
        // margin-top: 6px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        resize: none;
        padding: 8px 12px;
        color: #171923;
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    input {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        font-size: 14px !important;
    }

    label {
        margin-left: 0;
        align-items: flex-start;

        > span,
        input {
            padding: 0;

        }

        .MuiFormControlLabel-label {
            font-size: 14px;
            color: #171923;
            font-weight: 500;
        }

    }

    h5 {
        //margin-bottom: 0 !important;
        //margin-top: 0 !important;
        margin: 0 !important;
        font-size: 14px !important;
    }
`;
export default NewApplyForm;