import React, { forwardRef, useEffect, useState } from "react";
import { TableStyle } from "../../library/styled-components";
import moment from "moment";
import styled from "@emotion/styled";
import { InputBox } from "../../library/styled-components";
import { useMutation } from "@tanstack/react-query";
import { getMonthlyCommute } from "../../library/axios-query";
import EmptyContainer from "../../compontents/EmptyContainer";

const AttendanceMonth = forwardRef((props, ref) => {
  const { date } = props;
  const [selectedDate, setSelectedDate] = useState(date);
  const [days, setDays] = useState([]);
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");

  // 출역 현황 - 개인별(월별) 출퇴근 정보 (WEB)
  const { mutate: getMonthlyCommuteMutate, data: getMonthlyCommuteData } =
    useMutation({
      mutationFn: getMonthlyCommute,
      mutationKey: ["getMonthlyCommute"],
      onSuccess: async (data) => {},
      onError: (error) => {
        throw error;
      },
    });

  useEffect(() => {
    setStartDay(moment(selectedDate).startOf("month").format("YYYY-MM-DD"));
    setEndDay(
      moment(selectedDate).add("1", "M").startOf("month").format("YYYY-MM-DD")
    );
    getMonthlyCommuteMutate({ workDt: selectedDate });
  }, [selectedDate]);

  useEffect(() => {
    const diffDay = moment(endDay).diff(moment(startDay), "days");
    for (let index = 1; index <= diffDay; index++) {
      setDays((prevState) => [
        ...prevState,
        {
          date: index,
          day: moment(`${selectedDate}-${index}`).format("dd"),
        },
      ]);
    }
  }, [endDay, startDay]);

  return (
    <div ref={ref}>
      <InputBox fulled>
        <div>
          <InputContainer
            name="start_dt"
            style={{ width: "230px", marginTop: "0px" }}
            type="month"
            value={selectedDate}
            onChange={(e) => {
              setDays([]);
              setSelectedDate(e.target.value);
            }}
          />
        </div>
      </InputBox>

      <TableStyle
        style={{
          padding: "0",
          margin: "0",
        }}
      >
        <TableWrapper>
          <table>
            <thead>
              <tr>
                <th rowSpan={2} width={"140px"}>
                  성명
                </th>
                <th rowSpan={2}>현장</th>
                <th rowSpan={2} width={"40px"}>
                  근태
                </th>
                <th colSpan={days?.length}>
                  {`${startDay} ~ ${moment(selectedDate)
                    .endOf("month")
                    .format("YYYY-MM-DD")}`}
                </th>
              </tr>
              <tr>
                {days &&
                  days?.map((e, i) => (
                    <th
                      style={{ minWidth: "38px", width: "38px" }}
                      key={e.date}
                    >
                      {e.date}
                    </th>
                  ))}
              </tr>
            </thead>

            <tbody>
              {getMonthlyCommuteData && getMonthlyCommuteData.length !== 0 ? (
                getMonthlyCommuteData?.map((e, i) => (
                  <React.Fragment key={i}>
                    <tr>
                      <td
                        rowSpan={3}
                        style={{ width: "140px", minWidth: "140px" }}
                        className={` ${
                          getMonthlyCommuteData.length === i + 1 &&
                          "none-border"
                        } spaces`}
                      >
                        {e.userNm}
                      </td>
                      <td
                        rowSpan={3}
                        className={` ${
                          getMonthlyCommuteData.length === i + 1 &&
                          "none-border"
                        } spaces border table-left`}
                      >
                        {e.siteNm}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: "40px",
                          minWidth: "40px",
                          borderBottomStyle: "dotted",
                        }}
                      >
                        출근
                      </td>
                      {days &&
                        days.map((item) => (
                          <td
                            key={item.date}
                            className="value"
                            style={{
                              borderBottomStyle: "dotted",
                            }}
                          >
                            {
                              e.commuteTime.find(
                                (info) =>
                                  info.workDt ===
                                  moment(`${selectedDate}-${item.date}`).format(
                                    "YYYY-MM-DD"
                                  )
                              )?.startDt
                            }
                          </td>
                        ))}
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: "40px",
                          minWidth: "40px",
                        }}
                      >
                        퇴근
                      </td>
                      {days &&
                        days.map((item) => (
                          <td key={item.date} className="value">
                            {
                              e.commuteTime.find(
                                (info) =>
                                  info.workDt ===
                                  moment(`${selectedDate}-${item.date}`).format(
                                    "YYYY-MM-DD"
                                  )
                              )?.endDt
                            }
                          </td>
                        ))}
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={50}>
                    <EmptyContainer
                      selected={false}
                      falseText={`월별 출역 내역이`}
                      style={{ height: "500px", background: "#fff" }}
                    ></EmptyContainer>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </TableWrapper>
      </TableStyle>
    </div>
  );
});
const TableWrapper = styled.div`
  th,
  td {
    text-align: center;
  }

  .value {
    font-size: 11px;
    width: 38px;
    min-width: 38px;
  }
`;

const InputContainer = styled.input`
  &::-webkit-inner-spin-button,
  &::-webkit-clear-button {
    display: none;
  }
`;

export default AttendanceMonth;
