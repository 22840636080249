import React, { forwardRef, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { TablePrintStyle } from "../../library/styled-components";
import { useReactToPrint } from "react-to-print";
const UserPaymentStatementPrint = forwardRef((props, ref) => {
  const { day, data, getLaborPaymentData, setPrint } = props;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onPrintError: (error) => console.error("프린트 오류:", error),
    documentTitle: "개인별 노무비 지급명세서",
    onAfterPrint: (event) => {
      setPrint(false);
    },
  });
  useEffect(() => {
    handlePrint();
  }, [componentRef]);
  return (
    <Container ref={componentRef}>
      <Header>
        <Title>노무비 지급명세서</Title>
        <span>{day}</span>
      </Header>

      <TablePrintStyle style={{ marginTop: "15px" }}>
        <TableWrapper className="table_wrapper first">
          <table>
            <thead>
              <tr>
                <th width="120px">이름</th>
                <td
                  style={{ width: "150px", minWidth: "150px" }}
                  className="spaces"
                >
                  {data?.user_nm}
                </td>
                <th className="" width="120px">
                  주민등록번호
                </th>
                <td style={{ width: "150px", minWidth: "150px" }}>
                  {data?.id_num}
                </td>
                <th className="print_border" width="120px">
                  주소
                </th>
                <td className="spaces table-left">
                  {data.addr1} {data.addr2}
                </td>
              </tr>
            </thead>
          </table>
        </TableWrapper>
      </TablePrintStyle>

      <TablePrintStyle style={{ marginTop: "10px" }}>
        <TableWrapper className="table_wrapper">
          <table>
            <thead>
              <tr>
                <th style={{ width: "70px" }} rowSpan={3}>
                  년월
                </th>
                <th rowSpan={3} className="spaces" style={{ width: "556px" }}>
                  현장명
                </th>
                <th style={{ width: "40px" }} rowSpan={3}>
                  직종
                </th>
                <th colSpan={16}>출 역 사 항</th>
                <th
                  colSpan={3}
                  rowSpan={2}
                  style={{ width: "60px", minWidth: "60px" }}
                >
                  주차/년월차
                  <br />
                  /추가
                </th>
                <th className="th_width" rowSpan={2}>
                  단가
                </th>
                <th className="th_width" rowSpan={2}>
                  소득세
                </th>
                <th className="th_width" rowSpan={2}>
                  고용보험
                </th>
                <th className="th_width" rowSpan={2}>
                  국민연금
                </th>
                <th className="th_width" rowSpan={2}>
                  기타/노조
                </th>
                <th className="th_width" rowSpan={2}>
                  식대
                </th>
              </tr>
              <tr>
                <th className="tb_width">1</th>
                <th className="tb_width">2</th>
                <th className="tb_width">3</th>
                <th className="tb_width">4</th>
                <th className="tb_width">5</th>
                <th className="tb_width">6</th>
                <th className="tb_width">7</th>
                <th className="tb_width">8</th>
                <th className="tb_width">9</th>
                <th className="tb_width">10</th>
                <th className="tb_width">11</th>
                <th className="tb_width">12</th>
                <th className="tb_width">13</th>
                <th className="tb_width">14</th>
                <th className="tb_width">15</th>
                <th className="print_border tb_width">16</th>
              </tr>
              <tr>
                <th className="tb_width">17</th>
                <th className="tb_width">18</th>
                <th className="tb_width">19</th>
                <th className="tb_width">20</th>
                <th className="tb_width">21</th>
                <th className="tb_width">22</th>
                <th className="tb_width">23</th>
                <th className="tb_width">24</th>
                <th className="tb_width">25</th>
                <th className="tb_width">26</th>
                <th className="tb_width">27</th>
                <th className="tb_width">28</th>
                <th className="tb_width">29</th>
                <th className="tb_width">30</th>
                <th className="tb_width">31</th>
                <th className="tb_width"></th>
                <th colSpan={3} style={{ width: "60px", minWidth: "60px" }}>
                  총일수/공수
                </th>
                <th className="th_width">노무비총액</th>
                <th className="th_width">지방소득세</th>
                <th className="th_width">건강보험</th>
                <th className="th_width">요양보험</th>
                <th className="th_width">공제계</th>
                <th className="th_width">실지급액</th>
              </tr>
            </thead>
            {getLaborPaymentData && (
              <tbody>
                {getLaborPaymentData?.list?.map((e, i) => (
                  <React.Fragment key={i}>
                    <tr>
                      <td rowSpan={2} style={{ minWidth: "70px" }}>
                        {e.yyyymm}
                      </td>
                      <td
                        rowSpan={2}
                        style={{
                          cursor: "pointer",
                          textAlign: "left",
                          whiteSpace: "normal",
                        }}
                      >
                        {e.siteNm}
                      </td>
                      <td
                        rowSpan={2}
                        style={{
                          maxWidth: "40px",
                          minWidth: "0px",
                        }}
                      >
                        {e.jobPosition}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[0] === 10 ? "" : e.dayAttendList[0]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[1] === 10 ? "" : e.dayAttendList[1]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[2] === 10 ? "" : e.dayAttendList[2]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[3] === 10 ? "" : e.dayAttendList[3]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[4] === 10 ? "" : e.dayAttendList[4]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[5] === 10 ? "" : e.dayAttendList[5]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[6] === 10 ? "" : e.dayAttendList[6]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[7] === 10 ? "" : e.dayAttendList[7]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[8] === 10 ? "" : e.dayAttendList[8]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[9] === 10 ? "" : e.dayAttendList[9]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[10] === 10 ? "" : e.dayAttendList[10]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[11] === 10 ? "" : e.dayAttendList[11]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[12] === 10 ? "" : e.dayAttendList[12]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[13] === 10 ? "" : e.dayAttendList[13]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[14] === 10 ? "" : e.dayAttendList[14]}
                      </td>
                      <td className="tb_width font line">
                        {e.dayAttendList[15] === 10 ? "" : e.dayAttendList[15]}
                      </td>
                      {/* 주차/년월차/추가 */}
                      <td className="line"></td>
                      <td className="  line"></td>
                      <td className="  line"></td>

                      <td className={"table-right line  salary-width"}>
                        {e.dayAmt.toLocaleString()}
                      </td>
                      <td className={"table-right line "}>
                        {e.taxIncome.toLocaleString()}
                      </td>
                      <td className={"table-right line "}>
                        {e.taxEmployInsu.toLocaleString()}
                      </td>
                      <td className={"table-right line "}>
                        {e.taxNationPension.toLocaleString()}
                      </td>
                      <td className={"table-right line "}>
                        {e.taxEtcAndUnion.toLocaleString()}
                      </td>
                      <td className={"table-right line "}>
                        {e.taxMeal.toLocaleString()}
                      </td>
                    </tr>
                    <tr style={{ position: "relative" }}>
                      <td className="tb_width font">
                        {e.dayAttendList[16] === 10 ? "" : e.dayAttendList[16]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[17] === 10 ? "" : e.dayAttendList[17]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[18] === 10 ? "" : e.dayAttendList[18]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[19] === 10 ? "" : e.dayAttendList[19]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[20] === 10 ? "" : e.dayAttendList[20]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[21] === 10 ? "" : e.dayAttendList[21]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[22] === 10 ? "" : e.dayAttendList[22]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[23] === 10 ? "" : e.dayAttendList[23]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[24] === 10 ? "" : e.dayAttendList[24]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[25] === 10 ? "" : e.dayAttendList[25]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[26] === 10 ? "" : e.dayAttendList[26]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[27] === 10 ? "" : e.dayAttendList[27]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[28] === 10 ? "" : e.dayAttendList[28]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[29] === 10 ? "" : e.dayAttendList[29]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[30] === 10 ? "" : e.dayAttendList[30]}
                      </td>
                      <td className="tb_width font">
                        {e.dayAttendList[31] === 10 ? "" : e.dayAttendList[31]}
                      </td>
                      <td className=" ">{e.attendCnt}</td>
                      <td className=" " colSpan={2}>
                        {e.manDay}
                      </td>
                      <td className={"table-right  salary-width"}>
                        {e.salaryAmt.toLocaleString()}
                      </td>
                      <td className={"table-right "}>
                        {e.taxLocaIncome.toLocaleString()}
                      </td>
                      <td className={"table-right "}>
                        {e.taxHealthInsu.toLocaleString()}
                      </td>
                      <td className={"table-right "}>
                        {e.taxCareInsu.toLocaleString()}
                      </td>
                      <td className={"table-right "}>
                        {e.taxSum.toLocaleString()}
                      </td>
                      <td className={"table-right "}>
                        {e.paymentAmt.toLocaleString()}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
                <tr>
                  <th colSpan={19} rowSpan={2} className="none-border">
                    총계
                  </th>
                  <th style={{ width: "20px", minWidth: "20px" }}></th>
                  <th style={{ width: "20px", minWidth: "20px" }}></th>
                  <th style={{ width: "20px", minWidth: "20px" }}></th>
                  <th className={"table-right  salary-width"}>
                    {getLaborPaymentData?.total?.dayAmt?.toLocaleString()}
                  </th>
                  <th className={"table-right "}>
                    {getLaborPaymentData?.total?.taxIncome?.toLocaleString()}
                  </th>
                  <th className={"table-right "}>
                    {getLaborPaymentData?.total?.taxEmployInsu?.toLocaleString()}
                  </th>
                  <th className={"table-right "}>
                    {getLaborPaymentData?.total?.taxNationPension?.toLocaleString()}
                  </th>
                  <th className={"table-right "}>
                    {getLaborPaymentData?.total?.taxEtcAndUnion?.toLocaleString()}
                  </th>
                  <th className={"table-right "}>
                    {getLaborPaymentData?.total?.taxMeal?.toLocaleString()}
                  </th>
                </tr>
                <tr>
                  <th
                    className="none-border "
                    style={{ width: "20px", minWidth: "20px" }}
                  >
                    {getLaborPaymentData?.total?.attendCnt}
                  </th>
                  <th
                    colSpan={2}
                    className="none-border "
                    style={{ width: "40px", minWidth: "40px" }}
                  >
                    {getLaborPaymentData?.total?.manDay}
                  </th>
                  <th className={"table-right none-border  salary-width"}>
                    {getLaborPaymentData?.total?.salaryAmt?.toLocaleString()}
                  </th>
                  <th className={"table-right none-border "}>
                    {getLaborPaymentData?.total?.taxLocaIncome?.toLocaleString()}
                  </th>
                  <th className={"table-right none-border "}>
                    {getLaborPaymentData?.total?.taxHealthInsu?.toLocaleString()}
                  </th>
                  <th className={"table-right none-border "}>
                    {getLaborPaymentData?.total?.taxCareInsu?.toLocaleString()}
                  </th>
                  <th className={"table-right none-border "}>
                    {getLaborPaymentData?.total?.taxSum?.toLocaleString()}
                  </th>
                  <th className={"table-right none-border "}>
                    {getLaborPaymentData?.total?.paymentAmt?.toLocaleString()}
                  </th>
                </tr>
              </tbody>
            )}
          </table>
        </TableWrapper>
      </TablePrintStyle>
    </Container>
  );
});

export default UserPaymentStatementPrint;

const TableWrapper = styled.div`
  .tb_width {
    width: 23px;
    min-width: 23px;
  }

  .th_width {
    width: 50px;
    min-width: 50px;
  }

  .salary-width {
    width: 60px;
    min-width: 60px;
  }
  th,
  td {
    box-sizing: border-box;
    text-align: center;
  }
  .font {
    font-size: 8px;
  }
  &&.first {
    thead {
      height: 31px;
    }

    th,
    td {
      font-size: 10px;
      border-bottom: none;
    }
  }
`;
const Container = styled.div`
  background: #fff;

  @page {
    size: A4 landscape !important;
    margin-top: 20px;
    margin-bottom: 25px;
    display: block;
  }
  @media print {
    display: block;
    padding: 20px 24px;
    table th {
      background-color: #edf2f7 !important;
      -webkit-print-color-adjust: exact;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: relative;
  /* margin-top: 15px; */
  span {
    font-size: 12px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;
const Title = styled.div`
  color: #171923;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 30px; */
  text-align: center;
  /* margin-bottom: 6px; */
`;
