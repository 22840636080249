import React from 'react';
import styled from '@emotion/styled';

const Loading = () => {
    return (
        <LoadingWrap className={'loading-wrap'}>
            <Icon src="/assets/icons/loading.svg"/>
        </LoadingWrap>
    );
};
const LoadingWrap = styled.div`
    width: calc(100vw - 250px);
    //width: 100%;
    position: fixed;
    top: 0;
    left: 250px;
    background: transparent;
    //background: #fff;
    display: flex;
    border-radius: 16px;
    height: 100vh;

    align-items: center;
    justify-content: center;

`;
const Icon = styled.img`
    width: 130px;
    height: 130px;
    animation: rotate 1s linear infinite;
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;
export default Loading;
