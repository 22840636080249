import React, { useEffect, useRef, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import styled from "@emotion/styled";
import Modal from "../../compontents/Modal";
import { InputBox } from "../../library/styled-components";
import OwnEmploymentContractPrint from "../../compontents/print/OwnEmploymentContractPrint";
import axiosInstance from "../../library/axios-index";
import SelectedFilterBox from "../../compontents/SelectedFilterBox";
import { useMutation } from "@tanstack/react-query";
import {
  getContractPreview,
  getLaborContractSetting,
  modifyLaborContractSetting,
} from "../../library/axios-query";
import { htmlToText } from "../../bin/common";
import { useReactToPrint } from "react-to-print";
import ContractBizStandardPrint from "../../compontents/print/ContractBizStandardPrint";
import ContractBizConstructPrint from "../../compontents/print/ContractBizConstructPrint";

const ContractAgree = () => {
  const token = localStorage.getItem("admin_token");

  //industry => D - 일반 ,C - 건설  contract=> 표준근로계약서 - D, 자체 근로계약서 - C  select=> 자동 선택 - D, 다중 선택- M
  const [contractAgreeType, setContractAgreeType] = useState({
    industry: "",
    contract: "",
    select: "",
  });
  const [openedOwnContract, setOpenedOwnContract] = useState(false);
  const [openedOwnContractPrint, setOpenedOwnContractPrint] = useState(false);
  const [openedSitesSelected, setOpenedSitesSelected] = useState(false);
  const [siteFilterOption, setSiteFilterOptions] = useState([]);
  const [sitesSelected, setSitesSelected] = useState([]);
  const [sitesSelectedView, setSitesSelectedView] = useState([]);
  const [content, setContent] = useState("");
  const [contentView, setContentView] = useState("");

  const modalClose = useRef(false);
  const componentRef = useRef(null);
  const contractRef = useRef();

  const handlePrint2 = useReactToPrint({
    content: () => contractRef.current,
    documentTitle: "",
    onPrintError: (error) => console.error("프린트 오류2:", error),
  });
  // 사용중인(가장 최신의) 근로계약서 설정 정보 가져오기
  const { mutate: getContractSettingMutate, data: getContractSettingData } =
    useMutation({
      mutationKey: ["getLaborContractSetting"],
      mutationFn: getLaborContractSetting,
      onSuccess: (data) => {
        setContractAgreeType({
          industry: data.lc_biz_sector,
          contract: data.lc_type,
          select: data.lc_site_type,
        });
        // setContent(data.lc_content);
        if (data.lc_content === null) {
          setContent(htmlToText(data.workCondition));
        } else {
          setContent(data.lc_content);
        }

        handleSiteList(data);
      },
      onError: (error) => {
        console.log(error);
      },
    });

  // 사용중인(가장 최신의) 근로계약서 설정 정보 가져오기
  const {
    mutate: modifyContractSettingMutate,
    data: modifyContractSettingData,
  } = useMutation({
    mutationKey: ["modifyLaborContractSetting"],
    mutationFn: modifyLaborContractSetting,
    onSuccess: (data) => {
      if (data.done) {
        alert("저장이 완료되었습니다");
        getContractSettingMutate();
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 회사 근로계약서 설정 시 미리 보기용 데이터 조회 API
  const { mutate: getContractPreviewMutate, data: getContractPreviewData } =
    useMutation({
      mutationKey: ["getContractPreview"],
      mutationFn: getContractPreview,
      onSuccess: (data) => {
        console.log(data);
        setOpenedOwnContractPrint(true);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  useEffect(() => {
    (async () => {
      const {
        data: {
          result: { sites, totalCount },
        },
      } = await axiosInstance.post(`/admin/siteList`, {
        lang: "ko",
        uuid: "string",
        token,
        search_txt: "",
        page: 1,
        limit: 1000,
      });
      const siteFilter = new Map(
        sites.map((site) => [site.site_seq, site.site_nm])
      );
      const filterOption = [];
      siteFilter.forEach((value, key) => {
        filterOption.push({ value: "" + key, label: value });
      });
      filterOption.sort((a, b) => a.label.localeCompare(b.label));
      setSiteFilterOptions(filterOption);
    })();
  }, []);

  const handleOwnContract = () => {
    const data = {
      lcSeq: getContractSettingData.lc_seq,
      lcBizSector: contractAgreeType.industry,
      lcType: contractAgreeType.contract,
      lcContent:
        contractAgreeType.contract === "D"
          ? getContractSettingData.lc_content
          : content,
      lcSiteType: contractAgreeType.select,
      lcSiteList:
        contractAgreeType.select === "D"
          ? getContractSettingData?.lc_site_list?.split(",")
          : sitesSelected.map((site) => site.value),
    };
    console.log(data);

    modifyContractSettingMutate(data);
  };
  useEffect(() => {
    getContractSettingMutate();
  }, [siteFilterOption]);

  const handleSiteList = (data) => {
    let test = [];
    data?.lc_site_list?.split(",").map((list) => {
      siteFilterOption.filter((site) => {
        if (site.value === list) {
          test.push(site);
        }
      });
    });
    setSitesSelected(test);
  };

  return (
    <>
      <Board className="type">
        <BoardHeader>
          <h3>근로계약서 관리</h3>
          <Button
            style={{ width: "60px" }}
            onClick={(event) => {
              event.preventDefault();
              handleOwnContract();
            }}
          >
            저장
          </Button>
        </BoardHeader>
        <Field
          style={{
            width: "100%",
            marginTop: "28px",
            display: "flex",
            flexDirection: "column",
            rowGap: "15px",
          }}
        >
          <div>
            <Text>업종 선택</Text>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              style={{ alignItems: "center", height: "32px" }}
              value={contractAgreeType?.industry} // 폼 상태와 라디오 버튼 동기화
              onChange={(event) => {
                const value = event.target.value;
                setContractAgreeType((prevState) => ({
                  ...prevState,
                  industry: value,
                }));
              }}
            >
              <FormControlLabel
                value="D"
                control={<Radio size="small" />}
                label="일반"
                sx={{ fontSize: "14px" }}
              />
              <FormControlLabel
                value="C"
                control={<Radio size="small" />}
                label="건설"
                sx={{ fontSize: "14px" }}
              />
            </RadioGroup>
          </div>
          <div>
            <Text>양식 변경</Text>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={contractAgreeType?.contract} // 폼 상태와 라디오 버튼 동기화
              onChange={(event) => {
                const value = event.target.value;
                setContractAgreeType((prevState) => ({
                  ...prevState,
                  contract: value,
                }));
              }}
            >
              <FormControlLabel
                value="D"
                control={<Radio size="small" />}
                label="표준근로계약서"
                sx={{ fontSize: "14px" }}
              />
              <FormControlLabel
                value="C"
                control={<Radio size="small" />}
                label="자체 근로계약서"
                sx={{ fontSize: "14px" }}
              />
              <Button
                style={{ width: "60px" }}
                onClick={() => {
                  setOpenedOwnContract(true);
                  setContentView(content);
                }}
                disabled={contractAgreeType?.contract === "D"}
              >
                수정
              </Button>
            </RadioGroup>
          </div>
          <div>
            <Text>현장명 표기 방식</Text>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={contractAgreeType?.select} // 폼 상태와 라디오 버튼 동기화
              onChange={(event) => {
                const value = event.target.value;
                setContractAgreeType((prevState) => ({
                  ...prevState,
                  select: value,
                }));
                if (value === "D") {
                  setSitesSelectedView([]);
                } else {
                  setSitesSelectedView(sitesSelected);
                }
              }}
            >
              <FormControlLabel
                value="D"
                control={<Radio size="small" />}
                label="자동 선택"
                sx={{ fontSize: "14px" }}
              />
              <FormControlLabel
                value="M"
                control={<Radio size="small"></Radio>}
                label="다중 선택"
                sx={{ fontSize: "14px" }}
              ></FormControlLabel>
              <Button
                style={{ width: "auto" }}
                onClick={() => {
                  setOpenedSitesSelected(true);
                  setSitesSelectedView(sitesSelected);
                }}
                disabled={contractAgreeType?.select === "D"}
              >
                현장 지정
              </Button>
            </RadioGroup>
            <em
              style={{
                color: "red",
                fontWeight: 500,
                fontSize: "14px",
                marginTop: "5px",
                display: "block",
              }}
            >
              {" "}
              * 현장 지정 없을 시 "당일 근로 현장은 작업 지시에 따라 정해짐"
              으로 표기
            </em>
          </div>
        </Field>
      </Board>
      <Modal
        opend={openedOwnContract}
        closeModal={(event) => {
          const targetText = event.target.innerText;
          if (targetText === "확인") {
            setContent(contentView);
          }
          setOpenedOwnContract(false);
        }}
        header={"자체 근로계약서 수정"}
        okText={"미리보기"}
        cancelText={"확인"}
        noCloseModal={true}
        handleOk={() => {
          const items = {
            contractBizType: contractAgreeType.industry,
            workCondition: contentView,
            lcSiteList:
              contractAgreeType.select === "D"
                ? null
                : sitesSelected.map((site) => Number(site.value)),
          };
          getContractPreviewMutate(items);
        }}
        widthCheck={"50%"}
      >
        <form className="own-contract-form">
          <Text>
            사업주 정보, 근로자 정보, 현장명, 근로장소, 계약기간, 임금,
            근로시간은 시스템 상으로 작성됩니다. <br />그 외의 특수 조항들을
            아래 양식에 기입해 주십시오.
          </Text>
          <InputBox fulled style={{ marginTop: "30px" }}>
            <textarea
              style={{
                height: "500px",
                fontWeight: "normal",
                fontSize: "14px",
                lineHeight: "20px",
              }}
              name="content"
              value={contentView || ""}
              onChange={(event) => {
                setContentView(event.target.value);
              }}
            ></textarea>
          </InputBox>
        </form>
      </Modal>
      <Modal
        opend={openedOwnContractPrint}
        closeModal={() => {
          setOpenedOwnContractPrint(false);
        }}
        header={"출력 미리보기"}
        okText={"출력"}
        style={{}}
        cancelText={"취소"}
        noCloseModal={true}
        handleOk={() => {
          handlePrint2();
        }}
        shouldCloseOnOverlayClick={false}
        widthCheck={"60%"}
      >
        <OwnEmploymentContractPrint
          data={getContractPreviewData}
          ref={componentRef}
        ></OwnEmploymentContractPrint>
        {contractAgreeType?.industry === "D" ? (
          <ContractBizStandardPrint
            ref={contractRef}
            content={contentView}
          ></ContractBizStandardPrint>
        ) : (
          <ContractBizConstructPrint
            ref={contractRef}
            content={contentView}
          ></ContractBizConstructPrint>
        )}
      </Modal>

      {/*  현장 지정  */}
      <Modal
        opend={openedSitesSelected}
        closeModal={(event) => {
          setOpenedSitesSelected(false);
        }}
        header={
          <h4 style={{ margin: "0", fontSize: "18px" }}>
            현장 지정
            <>
              {" "}
              <br />
              <em
                style={{
                  fontSize: "14px",
                  color: "red",
                  fontWeight: "normal",
                  position: "absolute",
                  top: "46px",
                }}
              >
                * 10개 현장까지 선택 가능합니다.{" "}
              </em>
            </>
          </h4>
        }
        okText={"확인"}
        cancelText={"취소"}
        noCloseModal={true}
        handleOk={() => {
          setSitesSelected(sitesSelectedView);
          setOpenedSitesSelected(false);
        }}
        widthCheck={"50%"}
      >
        <div>
          <SelectedFilterBox
            options={siteFilterOption}
            placeholder={"현장명을 선택해주세요"}
            style={{
              width: "100%",
              height: "38px",
              border: "1px solid #E2E8F0",
            }}
            onChange={(e) => {
              if (sitesSelectedView.length >= 10) {
                alert("10개 현장까지 선택 가능합니다");
                return;
              }

              if (sitesSelectedView.some((site) => site.value === e.value)) {
                return;
              }
              setSitesSelectedView((prevState) => [
                ...prevState,
                {
                  value: e.value,
                  label: e.label,
                },
              ]);
            }}
          ></SelectedFilterBox>
          <SitesContainer>
            {sitesSelectedView?.map((site) => (
              <li key={site?.value}>
                <span>{site.label}</span>{" "}
                <img
                  alt={"XButton"}
                  src="/assets/icons/left-icon.svg"
                  onClick={(event) => {
                    event.stopPropagation();
                    const selected = site.value;
                    setSitesSelectedView((prevState) =>
                      prevState.filter((site) => site.value !== selected)
                    );
                  }}
                />
              </li>
            ))}
          </SitesContainer>
        </div>
      </Modal>
    </>
  );
};

const SitesContainer = styled.ul`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  height: 423px;
  border-radius: 6px;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  padding: 8px 12px;
  overflow-y: auto;

  li {
    display: flex;
    color: #1a202c;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    width: fit-content;
    padding: 4px 6px;
    box-sizing: border-box;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    background-color: rgb(215, 251, 226);
    margin-left: 6px;

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
`;
const Text = styled.span`
  color: #718096;
  font-size: 14px;
  font-style: normal;
  display: block;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 6px;

  span {
    color: red;
  }
`;
const Board = styled.div`
  width: 100%;

  @media only screen and (min-width: 992px) {
    width: 45%;
  }

  > span {
    color: red;
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
    display: block;
  }

  &&.type {
    label {
      padding-bottom: 0px;

      span {
        padding: 0;
      }

      em {
        color: red;
        font-weight: 500;
        font-size: 14px;
        display: block;
      }
    }

    > span {
      color: red;
      font-weight: 500;
      font-size: 14px;

      > em {
        margin-left: 10px;
      }
    }

    label {
      margin-left: 0;
      display: flex;
      align-items: center;

      > span,
      input {
        padding: 0;
      }

      .MuiFormControlLabel-label {
        font-size: 14px;
        color: #171923;
        line-height: 20px;
        font-weight: 500;
      }
    }
  }
`;

const BoardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 70%;
  h3 {
    margin: 0;
  }

  span {
    position: absolute;
    left: 0;
    color: red;
    font-weight: 500;
    font-size: 14px;
  }
`;
const Field = styled.div`
  width: 100%;

  label {
    display: inline-block;
    padding-bottom: 12px;
  }

  @media only screen and (min-width: 992px) {
    width: 33.3%;
  }
`;
const Button = styled.button`
  display: flex;
  height: 32px;
  padding: 1px 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #3279f5;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;

  &&:disabled {
    background-color: #9ea8b6;
    cursor: no-drop;
  }
`;
export default ContractAgree;
