import styled from '@emotion/styled';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Header} from '../../compontents/Header';
import ReactSelect from 'react-select';
import Modal from '../../compontents/Modal';
import MonthCalendar from '../../compontents/MonthCalendar';
import {useReactToPrint} from 'react-to-print';
import {HOST_URL} from '../../bin/env';
import dayjs from 'dayjs';
import DownLoadExcel from '../../library/handle-excel';
import SiteContainers from '../../compontents/SiteContainer';
import axiosInstance from '../../library/axios-index';
import AxiosNavigate from '../../library/axios-navigate';
import {addCommas, BlockScrolling} from '../../bin/common';
import MonthlyPaymentStatementPagePrint from '../../compontents/print/MonthlyPaymentStatementPagePrint';
import Loading from '../../compontents/Loading';
import EmptyContainer from '../../compontents/EmptyContainer';

const selectStyles = {
    control: base => ({
        ...base,
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #E2E8F0',
        },
    })
};

//월별노무현황
function LaborMonthlyPaymentStatement() {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [opendExport, setOpendExport] = useState(false);

    const [sites, setSites] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [report, setReport] = useState(null);
    const [reportEmpty, setReportEmpty] = useState(true);
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-01'));
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedSiteNm, setSelectedSiteNm] = useState('');
    const [loading, setLoading] = useState(true);
    const handleAllSites = useCallback(async () => {
        const token = localStorage.getItem('admin_token');

        const {
            data: {result},
        } = await axiosInstance.post(`${HOST_URL}/admin/searchSite`, {
            lang: 'ko',
            uuid: 'string',
            token,
            search_txt: '',
        });

        setSites(result);
        setSiteList(result);
        setReport(null);
        setReportEmpty(true);
        setSelectedSite(null);
        setSelectedSiteNm(null);
        await loadReport();
    }, []);

    BlockScrolling(opendExport);
    // 현장 검색
    const handleSearchSite = (value) => {
        const list = siteList.filter((e) => e.site_nm.toLowerCase().replaceAll(' ', '').includes(value.toLowerCase().replaceAll(' ', '')));
        setSites(list);
    };

    const handleSelectedSite = (e) => {
        setSelectedSite(e);
        setSelectedSiteNm(siteList.find(site => site.site_seq === e)?.site_nm);
    };

    const loadReport = useCallback(async () => {

        let site = [];

        if (selectedSite != null) {
            site.push(selectedSite);
        }
        if (!selectedSite && siteList.length > 1) {
            site = siteList.map((s) => {
                return s.site_seq;
            });
        }

        const request = {
            site_seq: site,
            work_dt: selectedDate
        };


        if (site?.length >= 1) {
            const {data: {result}} = await axiosInstance.post(
                `${HOST_URL}/admin/monthlyLaborPaymentStatementList`,
                request
            );

            setTimeout(() => {
                setLoading(false);
            }, 300);
            //데이터 받고 0.3초 후 데이터 사라짐
            if (result?.total?.seq < 1 && siteList.length > 0) {
                setReport(null);
                setReportEmpty(true);
                return;
            }
            setReport(result);
            setReportEmpty(false);
        }

    }, [selectedSite, selectedDate, siteList]);


    const handleExcel = () => {
        if (report?.total?.seq < 1) {
            setReportEmpty(true);
            return;
        }

        DownLoadExcel({txt: '월별노무현황', date: dayjs(selectedDate).format('YYYY-MM'), site: selectedSiteNm});
    };

    const handleOpenPrintModal = useCallback(() => {
        setOpendExport(true);
    }, []);

    useEffect(() => {
        setLoading(true);

        (async () => {
            await loadReport();

        })();

    }, [selectedDate, selectedSite, siteList]);

    useEffect(() => {
        setLoading(true);
        (async () => {
            await handleAllSites();
        })();

    }, []);

    return (
        <AxiosNavigate>
            <div>
                <Header>월별노무현황</Header>
                <Content>
                    <Info>
                        <CalendarContainer style={{paddingLeft: '16px'}}>
                            <MonthCalendar onChangeDate={(date) => {
                                setSelectedDate(dayjs(date).format('YYYY-MM-01'));
                                // setSelectedSite(null)
                                // setSelectedSiteNm(null)
                            }}/>
                        </CalendarContainer>
                        <SiteContainers
                            data={{
                                sites,
                                handleAllSites,
                                handleSearchSite,
                                handleSelectedSite,
                            }}
                        />

                    </Info>
                    {
                        loading ? <Loading></Loading> : (report
                                ? <Board>
                                    <Panel>
                                        <div>
                                            <BoardHeader>
                                                <h2>{selectedSiteNm}</h2>
                                                <h5>
                                                    <br/>
                                                    <strong>기간 </strong>
                                                    <span>{selectedDate == null ?
                                                        `${dayjs().startOf('month').format('YYYY년 M월 D일')} 
                       ~ ${dayjs().endOf('month').format('YYYY년 M월 D일')}`
                                                        : `${dayjs(selectedDate).startOf('month').format('YYYY년 M월 D일')} 
                       ~ ${dayjs(selectedDate).endOf('month').format('YYYY년 M월 D일')}`
                                                    }</span>
                                                </h5>
                                            </BoardHeader>
                                        </div>
                                        <div>
                                            <ButtonGroup>
                                                <Button onClick={handleExcel}><Icon
                                                    src="/assets/icons/excel.svg"/> 엑셀파일</Button>
                                                <Button onClick={handleOpenPrintModal}><Icon
                                                    src="/assets/icons/printer.svg"/> 출력하기</Button>
                                            </ButtonGroup>
                                        </div>
                                    </Panel>
                                    <TableContainer>
                                        <TableWrapper>
                                            <Table id={'report-table'}>
                                                <thead>

                                                <tr>
                                                    <th rowSpan={4}>현장명</th>
                                                    <th rowSpan={4}>년월</th>
                                                    <th rowSpan={4}>인원</th>
                                                    <th rowSpan={4}>일수</th>
                                                    <th rowSpan={4}>공수</th>
                                                    <th rowSpan={4}>노무비</th>

                                                    <th rowSpan={2}> 소득세</th>
                                                    <th rowSpan={2}> 고용보험</th>
                                                    <th rowSpan={2}> 건강보험</th>
                                                    <th rowSpan={2}> 기타</th>

                                                    <th rowSpan={4}> 공제계</th>
                                                    <th rowSpan={4}> 식대</th>
                                                    <th rowSpan={4}> 실지급액</th>
                                                </tr>
                                                <tr></tr>
                                                <tr>

                                                    <th rowSpan={2}> 지방소득세</th>
                                                    <th rowSpan={2}> 국민연금</th>
                                                    <th rowSpan={2}> 요양보험</th>
                                                    <th rowSpan={2} className="border"> 노조/분회비</th>

                                                </tr>
                                                <tr></tr>
                                                </thead>
                                                <tbody>
                                                {report?.paymentStatementList?.map((paymentStatus, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <tr>
                                                                <td style={{textAlign: 'left'}}
                                                                    rowSpan={2}>{paymentStatus?.site?.site_nm}</td>
                                                                <td rowSpan={2}>{dayjs(selectedDate).format('YY년 MM월')}</td>
                                                                <td rowSpan={2}>{paymentStatus?.attendList.seq}</td>
                                                                <td rowSpan={2}>{paymentStatus?.attendList.attend_cnt}</td>
                                                                <td rowSpan={2}>{paymentStatus?.attendList.man_day}</td>
                                                                <td rowSpan={2}
                                                                    className={'table-right'}>{addCommas(paymentStatus?.attendList.salary_amt)}</td>
                                                                <td className={'table-right'}>{addCommas(paymentStatus?.attendList.tax1)}</td>
                                                                <td className={'table-right'}>{addCommas(paymentStatus?.attendList.tax2)}</td>
                                                                <td className={'table-right'}>{addCommas(paymentStatus?.attendList.tax7)}</td>
                                                                <td className={'table-right'}>{addCommas(paymentStatus?.attendList.tax4)}</td>
                                                                <td rowSpan={2}
                                                                    className={'table-right'}>{addCommas(paymentStatus?.attendList.tax9)}</td>
                                                                <td rowSpan={2}
                                                                    className={'table-right'}>{addCommas(paymentStatus?.attendList.tax5)}</td>
                                                                <td rowSpan={2}
                                                                    className={'table-right'}>{addCommas(paymentStatus?.attendList.real_salary_amt)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={'table-right'}>{addCommas(paymentStatus?.attendList.tax6)}</td>
                                                                <td className={'table-right'}>{addCommas(paymentStatus?.attendList.tax3)}</td>
                                                                <td className={'table-right'}>{addCommas(paymentStatus?.attendList.tax8)}</td>
                                                                <td className={'table-right border'}>{addCommas(paymentStatus?.attendList.tax4)}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })}
                                                {
                                                    <>
                                                        <tr>
                                                            <th rowSpan={2} colSpan={2}>합 계</th>
                                                            <th rowSpan={2}>{report?.total?.seq}</th>
                                                            <th rowSpan={2}>{report?.total?.attend_cnt}</th>
                                                            <th rowSpan={2}>{report?.total?.man_day}</th>
                                                            <th rowSpan={2}
                                                                className={'table-right'}>{addCommas(report?.total?.salary_amt)}</th>
                                                            <th className={'table-right'}>{addCommas(report?.total?.tax1)}</th>
                                                            <th className={'table-right'}>{addCommas(report?.total?.tax2)}</th>
                                                            <th className={'table-right'}>{addCommas(report?.total?.tax7)}</th>
                                                            <th className={'table-right'}>{addCommas(report?.total?.tax4)}</th>
                                                            <th rowSpan={2}
                                                                className={'table-right'}>{addCommas(report?.total?.tax9)}</th>
                                                            <th rowSpan={2}
                                                                className={'table-right'}>{addCommas(report?.total?.tax5)}</th>
                                                            <th rowSpan={2}
                                                                className={'table-right'}>{addCommas(report?.total?.real_salary_amt)}</th>
                                                        </tr>
                                                        <tr>
                                                            <th className={'table-right'}>{addCommas(report?.total?.tax6)}</th>
                                                            <th className={'table-right'}>{addCommas(report?.total?.tax3)}</th>
                                                            <th className={'table-right'}>{addCommas(report?.total?.tax8)}</th>
                                                            <th
                                                                className={'table-right border'}>{addCommas(report?.total?.tax4)}</th>
                                                        </tr>
                                                    </>
                                                }
                                                </tbody>
                                            </Table>
                                        </TableWrapper>
                                    </TableContainer>
                                </Board>
                                :
                                <EmptyContainer selected={!reportEmpty}
                                                falseText={'월별노무현황이'}>
                                </EmptyContainer>
                        )
                    }

                </Content>
                <Modal
                    opend={opendExport}
                    closeModal={() => setOpendExport(false)}
                    header={'출력 미리보기'}
                    okText={'출력'}
                    handleOk={() => {
                        handlePrint();
                    }}
                    widthCheck={'70%'}
                >
                    <MonthlyPaymentStatementPagePrint
                        ref={componentRef}
                        selectedDate={selectedDate}
                        selectedSiteNm={selectedSiteNm}
                        data={report}
                    />
                </Modal>
            </div>
        </AxiosNavigate>
    );
}

const Content = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 992px) {
        flex-direction: row;
    }
    @media only screen and (max-width: 992px) {
        .loading-wrap {
            width: 100vw;
            left: 0;
            bottom: 0;
            top: unset;
            height: 400px;
        }
    }
`;

const Info = styled.div`
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (min-width: 992px) {
        flex-direction: column;
    }
`;

const CalendarContainer = styled.div`
    width: 100%;
    padding: 20px 24px 32px 24px;
    border-radius: 16px;
    border: 1px solid #E2E8F0;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

    @media only screen and (min-width: 768px) {
        width: 30%;
    }

    @media only screen and (min-width: 992px) {
        width: 280px;
    }
`;


const Button = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    width: fit-content;
    min-height: 37px;
    height: 37px;
    gap: 9px;
    border: 1.125px solid #E2E8F0;
    border-radius: 6px;
    background: #FFF;
    color: #4A5568;
    font-size: 14px;
    font-style: normal;
    cursor: pointer;

    leading-trim: both;
    text-edge: cap;
    margin-top: 5px;
`;

const Select = styled(ReactSelect)`
    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    border-radius: 6px;
    background: #FFF;
    margin-top: 5px;

    div {
        cursor: pointer;
        color: #4A5568;
        border-color: #E2E8F0;
    }
`;

const SiteSearch = styled.div`
    display: flex;
    align-items: center;
    margin-top: 18px;
    margin-bottom: 12px;
    background: #F7FAFC;
    border-radius: 8px;
`;

const SiteSearchIcon = styled.img`
    padding-left: 12px;
`;

const SiteSearchInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 12px;

    background: #F7FAFC;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    :focus {

    }
`;

const SiteList = styled.div`
    overflow: auto;
    height: 80%;
`;

const SiteItem = styled.div`
    color: #4A5568;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 8px;
    cursor: pointer;

    :hover {
        background: rgba(0, 0, 0, 0.05);
    }
`;

const SiteEmptyList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    p {
        color: #A0AEC0;
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        letter-spacing: 0.16px;
        text-transform: uppercase;
    }
`;

const EmptyReport = styled.div`
    border-radius: 16px;
    background: #F7FAFC;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        text-align: center;
        margin: 0;
        padding: 0;
        color: #A0AEC0;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
    }

    @media only screen and (max-width: 992px) {
        min-height: 333px;
        margin-top: 24px;
    }
`;

const EmptyIcon = styled.img`
`;

const Board = styled.div`
    border-radius: 16px;
    background: #F7FAFC;
    width: 100%;
    margin-top: 24px;

    @media only screen and (min-width: 992px) {
        margin-top: 0;
    }
`;


const Panel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 32px;
`;

const Search = styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #CBD5E0;
    overflow: hidden;
    background: #fff;
`;

const SearchIcon = styled.img`
    padding-left: 12px;
`;

const SearchInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    :focus {

    }
`;

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
`;

const BoardButton = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    min-height: 32px;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 6px;
    border: 1.125px solid #E2E8F0;
    background: #FFF;

    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    cursor: pointer;

    margin-top: 5px;

`;
const Icon = styled.img`
    width: 16px;
`;

const TableContainer = styled.div`
    padding: 0 32px;
    margin-top: 28px;
`;

const TableWrapper = styled.div`
    border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: left;
        font-size: 12px;
        font-weight: 300;
        border-right: 1px solid #CBD5E0;
        border-bottom: 1px solid #CBD5E0;
        white-space: nowrap;
        padding: 2px;
    }

    th {
        background-color: #e2e8f0;
        text-align: center;
    }

    tr:last-child td {
        border-bottom: none;
    }

    td {
        background: #fff;
        text-align: center;
    }

    tbody tr:last-of-type th {
        border-bottom: none;
    }

`;

const PageGroup = styled.div`
    display: flex;
    justify-content: center;

    ul {
        display: flex;
        padding: 0;
        list-style: none;
    }

    li a {
        display: inline-block;
        cursor: pointer;
        color: #000;
        padding: 6px 12px;
        text-decoration: none;
        font-size: 12px;
    }

    li.active a {
        color: #fff;
        background-color: #3279F5 !important;
        border-radius: 4px;
    }

    li:hover:not(.active) a {
        background-color: #EBF2FE !important;
        border-radius: 4px;
    }
`;

const SelectWrapper = styled.div`
    display: flex;
    align-items: center;

    span {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-right: 6px;
    }
`;

const BoardHeader = styled.div`
    margin-bottom: 14px;
    margin-top: 28px;

    h2, h5 {
        margin: 0;
    }

    h5 {
        margin-top: 4px;
    }
`;


export default LaborMonthlyPaymentStatement;