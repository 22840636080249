import React, {forwardRef} from 'react';
import styled from '@emotion/styled';
import {useForm} from 'react-hook-form';
import {InputBox} from '../../library/styled-components';
import {numberOnly} from '../../bin/common';
import {useMutation} from '@tanstack/react-query';
import {getLaborSearchInfo} from '../../library/admin-react-query';

const EmployIdCheckForm = forwardRef((props, ref) => {

    const {
        register,
        control,
        clearErrors,
        watch,
        handleSubmit,
        reset,
        setValue,
        getValues,
        setFocus,
        formState: {errors}
    } = useForm({
        defaultValues:
            {idNumber: ''}
    });

    const {mutate: getLaborSearchInfoMutate} = useMutation({
        mutationKey: 'getLaborSearchInfo',
        mutationFn: getLaborSearchInfo,
        onSuccess: (data) => {
            if (data?.userCount === 0) {
                //신규
                props.handleCheckId(1, {
                    id_num: getValues('idNumber'),
                });
            } else if (data?.userCount > 0 && data?.siteMapCount === 0) {
                //수정
                props.handleCheckId(2, {
                    id_num: getValues('idNumber'),
                    user_seq: data?.user_seq
                });
            } else if (data?.userCount > 0 && data?.siteMapCount > 0) {
                alert(`현재 '${data?.site_nm}' 근무중인 근로자입니다.\n현장을 변경하려면 퇴사후 진행해 주세요.`);
                props.handleCheckId(3, null);

            }
        },
        onError: (error) => {
            throw error;
        }
    });

    const onSubmit = (e) => {
        getLaborSearchInfoMutate(e?.idNumber);
    };

    const onError = (errors) => {
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            const firstError = errorKeys[0];
            setFocus(firstError);
            if (errors[firstError].type === 'noSpaces') {
                setValue(firstError, '');
            }
            alert(errors[firstError]?.message || 'Form validation error');
        }
    };


    return (
        <FieldGroup className="employ-id-check-form" ref={ref} onSubmit={handleSubmit(onSubmit, onError)}>
            <InputBox fulled>
                <h6>주민등록번호(외국인관리번호)</h6>
                <input name="idNumber" type="text"
                       {...register('idNumber', {
                           minLength: {
                               value: 13,
                               message: '주민등록번호를 확인해주세요'
                           },
                           required: '주민등록번호를 입력해주세요'
                       })}
                       onChange={e => {
                           numberOnly(e);
                           setValue('idNumber', e.target.value);
                       }}
                       onKeyDown={(e) => {
                           if (e.key === 'Enter') {
                               e.preventDefault();
                               handleSubmit(
                                   (data) => {
                                       onSubmit(data); // 최신 상태 반영 후 처리
                                   },
                                   (errors) => {
                                       onError(errors); // 유효성 검사 실패 시
                                   }
                               )();
                           }
                       }}


                       maxLength={13}
                />
            </InputBox>

        </FieldGroup>
    );
});
const FieldGroup = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 10px;

    > div {
        row-gap: 8px;
        display: flex;
        flex-direction: column;
    }

    textarea {
        width: 100%;
        min-height: 80px;
        border-radius: 6px;
        border: 1px solid #CBD5E0;
        box-sizing: border-box;
        // margin-top: 6px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        resize: none;
        padding: 8px 12px;
        color: #171923;
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    input {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }

    label {
        margin-left: 0;

        > span,
        input {
            padding: 0;

        }

        .MuiFormControlLabel-label {
            font-size: 15px;
        }

    }
`;
export default EmployIdCheckForm;
