import axiosInstance from './axios-index';

// 중복된 법인 등록번호 체크
export const checkBizRegNum = async (bizRegNum: string) => {
    try {
        const {data: {result: result}} = await axiosInstance.post('/company/checkBizRegNum', {
            biz_reg_num: bizRegNum
        });
        return result.existed;
    } catch (e) {
        alert('사업자등록번호 중복 체크에 실패 하였습니다. 다시 시도해 주세요');
        return true;
    }
};

// 중복된 사업자등록번호 체크
export const checkCorpRegNum = async (corpRegNum) => {
    try {
        const {data: {result: result}} = await axiosInstance.post('/company/checkCorpRegNum', {
            corp_reg_num: corpRegNum
        });
        return result.existed;
    } catch {
        alert('법인등록번호 중복 체크에 실패 하였습니다. 다시 시도해 주세요');
        return true;
    }
};