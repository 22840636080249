import React from 'react';
import styled from '@emotion/styled';

const SearchBox = ({placeholder, onKeyDown, style, value, onChange}) => {
    //console.log(props);
    //   console.log(onKeyDown);
    return (
        <Search style={style}>
            <SearchIcon src="/assets/icons/search-sm.svg"/>
            <SearchInput
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                onChange={onChange}
                value={value}
            ></SearchInput>
        </Search>
    );
};

const Search = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #CBD5E0;
    overflow: hidden;
    height: 34px;
    box-sizing: border-box;
`;

const SearchIcon = styled.img`
    padding-left: 12px;
`;

const SearchInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;
    padding: 6px 12px;
    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 100%;

    :focus {

    }
`;
export default SearchBox;
