import Cookies from 'js-cookie';
import axios from 'axios';
import { HOST_URL } from '../bin/env'

// export const baseUrl = HOST_URL;
const domain = process.env.NEXT_PUBLIC_DOMAIN;
const axiosInstance = axios.create({
  baseURL: HOST_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('admin_token')}`
  },
});

const gotoLogin = () => {
  Cookies.remove('accessToken', {
    path: '/',
  });
  Cookies.remove('refreshToken', {
    path: '/',
  });
  Cookies.remove('accessToken', {
    domain: domain,
    path: '/',
  });
  Cookies.remove('refreshToken', {
    domain: domain,
    path: '/',
  });
  // Cookies.remove('refreshToken');
  window.location.href = '/login';
};

axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('admin_token');
      if ( token ) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config;
    },
    (error) => Promise.reject(error),
)

export default axiosInstance;
