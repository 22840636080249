// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx-js-style';

const applyStyles = (ws) => {
    const range = XLSX.utils.decode_range(ws['!ref']);

    // 첫 번째 행 스타일 적용
    for (let C = range.s.c; C <= range.e.c + 1; ++C) {
        const cell_address = {c: C, r: 0};
        const cell_ref = XLSX.utils.encode_cell(cell_address);

        if (!ws[cell_ref]) continue;

        ws[cell_ref].s = {
            alignment: {
                vertical: 'center',
                horizontal: 'center'
            },
            border: {
                top: {style: 'thin', color: {auto: 1}},
                bottom: {style: 'thin', color: {auto: 1}},
                left: {style: 'thin', color: {auto: 1}},
                right: {style: 'thin', color: {auto: 1}}
            }
        };
    }

    // 모든 셀에 기본 셀 선 추가
    for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
            const cell_address = {c: C, r: R};
            const cell_ref = XLSX.utils.encode_cell(cell_address);

            if (!ws[cell_ref]) continue;

            if (!ws[cell_ref].s) {
                ws[cell_ref].s = {};
            }
            ws[cell_ref].s.border = {
                top: {style: 'thin', color: {auto: 1}},
                bottom: {style: 'thin', color: {auto: 1}},
                left: {style: 'thin', color: {auto: 1}},
                right: {style: 'thin', color: {auto: 1}}

            };
        }
    }
};
const getColumnWidthsFromTable = (data) => {
    const maxLengths = [];

    data.forEach(row => {
        row.forEach((cell, colIdx) => {
            const value = cell ? cell.toString() : '';
            maxLengths[colIdx] = Math.max(maxLengths[colIdx] || 0, value.length);
        });
    });

    return maxLengths.map(length => ({
        wch: length + 6 // 적절한 여백을 주기 위해 2를 더합니다.
    }));


};
const formatTableData = (table) => {
    const rows = table.querySelectorAll('tr');
    const formattedData = [];
    const merges = [];

    rows.forEach((row, rowIndex) => {
        const cells = row.cells;
        let colIndex = 0;

        for (let i = 0; i < cells.length; i++) {
            const cell = cells[i];
            let cellText = cell.innerText;

            // 쉼표가 포함된 숫자를 문자열로 변환
            if (/^[0-9,]+$/.test(cellText)) {
                cellText = cellText.replace(/,/g, ''); // 쉼표 제거
                cellText = Number(cellText).toLocaleString(); // 숫자로 변환 후 다시 쉼표 추가
            }

            // 병합된 셀을 계산하여 데이터를 정확한 위치에 삽입
            while (formattedData[rowIndex] && formattedData[rowIndex][colIndex] !== undefined) {
                colIndex++;
            }

            if (cell.rowSpan > 1 || cell.colSpan > 1) {
                merges.push({
                    s: {r: rowIndex, c: colIndex},
                    e: {r: rowIndex + cell.rowSpan - 1, c: colIndex + cell.colSpan - 1}
                });
            }

            if (!formattedData[rowIndex]) {
                formattedData[rowIndex] = [];
            }

            formattedData[rowIndex][colIndex] = cellText;

            // 병합된 셀의 범위를 반영하여 colIndex를 증가
            for (let rs = 0; rs < cell.rowSpan; rs++) {
                for (let cs = 0; cs < cell.colSpan; cs++) {
                    if (!formattedData[rowIndex + rs]) {
                        formattedData[rowIndex + rs] = [];
                    }
                    formattedData[rowIndex + rs][colIndex + cs] = cellText;
                }
            }

            colIndex += cell.colSpan;
        }
    });

    return {formattedData, merges};
};

const DownLoadExcel = (value) => {

    var ws = null;
    const table = document.getElementById('report-table');
    if (value.type === 1) {
        // 복제본 생성
        const clonedTable = table.cloneNode(true);
        // 특정 클래스가 있는 열 제외
        const rows = clonedTable.querySelectorAll('.remove');
        rows.forEach(row => row.remove());


        const {formattedData, merges} = formatTableData(clonedTable);
        ws = XLSX.utils.aoa_to_sheet(formattedData);
        ws['!merges'] = merges;
        ws['!cols'] = getColumnWidthsFromTable(formattedData);
    } else {
        const {formattedData, merges} = formatTableData(table);
        ws = XLSX.utils.aoa_to_sheet(formattedData);
        ws['!merges'] = merges;
        ws['!cols'] = getColumnWidthsFromTable(formattedData);
    }

    // 스타일 적용
    applyStyles(ws);

    // 열 너비 자동 설정
    const wb = XLSX.utils.book_new();


    XLSX.utils.book_append_sheet(wb, ws, 'Sheet');
    XLSX.writeFile(wb, `${value.txt}_${value.date ?? ''}` + `${value.site ? '_' + value.site : ''}` + `.xlsx`);

};

export default DownLoadExcel;