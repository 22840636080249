import React from 'react';
import styled from '@emotion/styled';

const EmptyContainer = ({selected, falseText, ...props}) => {

    return (
        <Empty {...props}>
            <div>
                <p><EmptyIcon src="/assets/icons/check-circle-broken.svg"/></p>
                {
                    selected ? (<><p>현장목록에서</p><p>현장명을 검색해주세요</p></>) : <p>{falseText} 없습니다</p>
                }
            </div>
        </Empty>
    );
};


const Empty = styled.div`
    border-radius: 16px;
    background: #F7FAFC;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        text-align: center;
        margin: 0;
        padding: 0;
        color: #A0AEC0;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
    }

    @media only screen and (max-width: 992px) {
        min-height: 333px;
        margin-top: 24px;
    }
`;

const EmptyIcon = styled.img`
`;
export default EmptyContainer;
