import styled from '@emotion/styled';
import dayjs from 'dayjs';
import {useState} from 'react';
import ReactCalendar from 'react-calendar';

function MonthCalendar({
                           onChangeDate = (date) => {
                           }
                       }) {
    const [value, onChange] = useState(new Date());

    return (
        <div>
            <StyledCalendar
                onChange={
                    (e) => {
                        onChange(e);
                        onChangeDate(dayjs(e).format('YYYY-MM-01'));
                    }
                }
                minDetail="year"
                maxDetail="year"
                prev2Label={null}
                next2Label={null}
                prevLabel={<img src="/assets/icons/chevron-left.svg"/>}
                nextLabel={<img src="/assets/icons/chevron-right.svg"/>}
                value={value}
            />
        </div>
    );
}

const StyledCalendar = styled(ReactCalendar)`
    button {
        background: #fff;
        border: none;
        cursor: pointer;
    }

    .react-calendar__navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;
        margin-bottom: 16px;
    }

    .react-calendar__navigation__label {
        color: #171923;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 22px;
    }

    .react-calendar__tile {
        height: 48px;
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: #fff;
        border-radius: 6px;
        border: 1px solid #E2E8F0;
        padding: 0;
    }

    .react-calendar__tile--active {
        background: #2D3748;
        color: #fff;
        border-radius: 6px;
    }

    .react-calendar__year-view__months {
        gap: 8px;
        justify-content: center;

        button {
            flex: 0 0 30% !important;
        }
    }
`;

export default MonthCalendar;