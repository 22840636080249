import styled from "@emotion/styled";
import { Header } from "../compontents/Header";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactSelect from "react-select";
import Pagination from "react-js-pagination";
import { ModalButton } from "../compontents/ModalButton";
import Modal from "../compontents/Modal";
import SideModal from "../compontents/SideModal";
import { PagingFooter } from "../compontents/PagingFooter";
import axiosInstance from "../library/axios-index";
import dayjs from "dayjs";
import { addCommas, BlockScrolling, checkRole } from "../bin/common";
import { Tooltip } from "react-tooltip";
import KakaoMap from "../compontents/KakaoMap";
import AxiosNavigate from "../library/axios-navigate";
import { RoleType } from "../bin/enum";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import CustomTimePicker from "../compontents/TimePicker";
import PopUpModal from "../compontents/PopUpModal";
import PlanSelectModal from "../compontents/PlanSelectModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  siteAddressCreate,
  siteAddressDelete,
  siteAddressList,
  siteAddressUpdate,
} from "../library/axios-query";
import SearchBox from "../compontents/Search";
import PagesList from "../compontents/PagesList";
import EmptyContainer from "../compontents/EmptyContainer";
import Loading from "../compontents/Loading";
import SelectedFilterBox from "../compontents/SelectedFilterBox";
import CustomChecked from "../compontents/CustomChecked";
import NaverMap from "../compontents/NaverMap";

const selectStyles = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #E2E8F0",
    },
  }),
};

const options = [
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];
const workWeekOptions = [
  { value: 1, label: "1일" },
  { value: 2, label: "2일" },
  { value: 3, label: "3일" },
  { value: 4, label: "4일" },
  { value: 5, label: "5일" },
  { value: 6, label: "6일" },
  { value: 7, label: "7일" },
];
const dayWeekKo = {
  monday: "월",
  tuesday: "화",
  wednesday: "수",
  thursday: "목",
  friday: "금",
  saturday: "토",
  sunday: "일",
};
const dayWeekEn = {
  월: "monday",
  화: "tuesday",
  수: "wednesday",
  목: "thursday",
  금: "friday",
  토: "saturday",
  일: "sunday",
};

function Site() {
  const location = useLocation();
  const [cookies] = useCookies(["role"]);
  const [loading, setLoading] = useState(true);

  const [limit, setLimit] = useState(options[0].value);
  const [page, setPage] = useState(5);
  const [searchTxt, setSearchTxt] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [sites, setSites] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [detailSite, setDetailSite] = useState({});
  const [workFieldOptions, setWorkFieldOptions] = useState([]);
  const [teamsOptions, setTeamsOptions] = useState([]);
  const [workWeek, setWorkWeek] = useState({
    월: false,
    화: false,
    수: false,
    목: false,
    금: false,
    토: false,
    일: false,
  });
  const [workWeekSelect, setWorkWeekSelect] = useState(0);
  const [addr, setAddr] = useState("");
  const [subAddr, setSubAddr] = useState("");
  const [zoneCode, setZoneCode] = useState("");
  const [logitude, setLogitude] = useState("");
  const [latitude, setLatitude] = useState("");

  const [opendDetailSite, setOpendDetailSite] = useState(false);
  const [opendNewSite, setOpendNewSite] = useState(false);
  const [opendModifySite, setOpendModifySite] = useState(false);

  const [opendWorkTimeSet, setOpendWorkTimeSet] = useState(false);
  const [modifyTimeData, setModifyTimeData] = useState({
    am_begin: "00:00:00",
    am_end: "00:00:00",
    pm_begin: "12:00:00",
    pm_end: "12:00:00",
    satday_yn: "N",
    satday_begin: "00:00:00",
    satday_end: "00:00:00",
    satday_pm_begin: "12:00:00",
    satday_pm_end: "12:00:00",
    weekend_yn: "N",
    weekend_begin: "00:00:00",
    weekend_end: "00:00:00",
    weekend_pm_begin: "12:00:00",
    weekend_pm_end: "12:00:00",
    overtime_yn: "N",
    overtime_begin: "12:00:00",
    overtime_end: "12:00:00",
  });

  const [formFields, setFormFields] = useState([]);
  const [addFormField, setAddFormField] = useState(true);

  const [endYtabColor, setEndYtabColor] = useState("#fff");
  const [endNtabColor, setEndNtabColor] = useState("#EDF2F7");
  const [endYn, setEndYn] = useState("Y");
  const [openPlanSelect, setOpenPlanSelect] = useState(false);

  const [opendMap, setOpendMap] = useState(false);
  const [openMapMode, setOpenMapMode] = useState({ modeName: "", items: "" });

  const [siteManager, setSiteManager] = useState([]);
  const [opendSelectSiteManager, setOpendSelectSiteManager] = useState(false);
  const [opendSiteManagerPopup, setOpendSiteManagerPopup] = useState(false);
  const [cancelSelectSiteManager, setCancelSelectSiteManager] = useState(false);
  const [popUpData, setPopupData] = useState({
    response: false,
    userData: null,
    content: null,
  });
  const [buttonType, setButtonType] = useState(null);

  const modalClose = useRef(false);

  BlockScrolling(opendModifySite);
  BlockScrolling(opendDetailSite);
  BlockScrolling(opendWorkTimeSet);
  BlockScrolling(opendSiteManagerPopup);
  BlockScrolling(openPlanSelect);

  const handleSiteInfo = useCallback(
    (site_seq) => {
      setOpendDetailSite(true);

      setDetailSite(sites.find((e) => e.site_seq === site_seq));
    },
    [sites]
  );

  const queryClient = useQueryClient();

  // 현장 주소 수정하기
  const { mutate: addressUpdate, data: addressUpdateItem } = useMutation({
    mutationKey: ["siteAddressUpdate"],
    mutationFn: siteAddressUpdate,
    onSuccess: (data) => {
      getAgainSiteAddress();
    },
    onError: (error) => {
      throw error;
    },
  });

  // 현장 주소 리스트
  const { mutate: getSiteAddress, data: getSiteAddressList } = useMutation({
    mutationKey: ["siteAddressList"],
    mutationFn: siteAddressList,
    onSuccess: (data) => {},
    onError: (error) => {
      throw error;
    },
  });

  // 현장 주소 생성
  const { mutate: addressCreate, data: addressCreateList } = useMutation({
    mutationKey: ["siteAddressCreate"],
    mutationFn: siteAddressCreate,
    onSuccess: (data) => {
      getAgainSiteAddress();

      // getSiteAddress(detailSite?.site_seq);
    },
    onError: (error) => {
      throw error;
    },
  });

  // 현장 주소 삭제
  const { mutate: addressDelete, data: addressDeleteList } = useMutation({
    mutationKey: ["siteAddressDelete"],
    mutationFn: siteAddressDelete,
    onSuccess: (data) => {
      getAgainSiteAddress();
    },
    onError: (error) => {
      throw error;
    },
  });

  const getAgainSiteAddress = useCallback(() => {
    getSiteAddress(detailSite?.site_seq);
  }, [detailSite?.site_seq]);

  const handleWorkTimeSet = (site_seq) => {
    setOpendWorkTimeSet(true);
    const site = sites.find((e) => e.site_seq === site_seq);
    let newModifyTimeData = {
      ...modifyTimeData,
    };

    for (let key in site) {
      if (site.hasOwnProperty(key) && site[key] !== null) {
        newModifyTimeData[key] = site[key];
      }
    }

    newModifyTimeData.rest_day.split(",").map((item) =>
      setWorkWeek((prevState) => ({
        ...prevState,
        [item]: true,
      }))
    );
    setWorkWeekSelect(newModifyTimeData.work_day_cnt);

    setModifyTimeData({
      ...newModifyTimeData,
      time_range:
        site.time_range === null
          ? "0"
          : parseInt(site.time_range.split(":")[1], 10),
    });
  };

  const getValue = (arr, name) => arr.find((e) => e.name === name).value;

  const changeTime = (name, value) => {
    setModifyTimeData({
      ...modifyTimeData,
      [name]: value,
    });
  };

  const handleAddrAndZoneCode = (fullAddr, subAddr, zoneCode = "") => {
    setAddr(fullAddr);
    setZoneCode(zoneCode);
    setSubAddr(subAddr);
  };

  const handleLogitudeAndLatitude = (logitude, latitude) => {
    setLogitude(logitude);
    setLatitude(latitude);
  };

  const handleAddrInfo = (
    addr,
    subAddr,
    zoneCode,
    logitude,
    latitude,
    mode
  ) => {
    if (mode.modeName === "modify") {
      const modifyItems = {
        addr_seq: mode?.items?.addr_seq,
        default_yn: mode.items.default_yn,
        site_seq: mode.items.site_seq,
        addr1: addr,
        addr2: subAddr == "" ? null : subAddr,
        latitude: latitude,
        logitude: logitude,
        postal_cd: zoneCode,
      };
      addressUpdate(modifyItems);
      // 수정하기 페이지 > 수정하기
    } else if (mode.modeName === "add") {
      // 수정하기 페이지 > 주소등록하기
      const addItems = {
        site_seq: detailSite?.site_seq,
        postal_cd: zoneCode,
        addr1: addr,
        addr2: subAddr == "" ? null : subAddr,
        latitude: latitude,
        logitude: logitude,
      };
      addressCreate(addItems);
    } else {
      // 현장 추가하기 페이지
      handleAddrAndZoneCode(addr, subAddr, zoneCode);
      handleLogitudeAndLatitude(logitude, latitude);
    }
  };

  const initPopupData = useCallback(() => {
    setPopupData({ response: false, userData: null, content: null });
    setCancelSelectSiteManager(false);
  }, [popUpData]);

  const handleFindManager = useCallback(
    async (e) => {
      const inputs = Array.from(document.querySelectorAll("input"));
      const phoneNum =
        getValue(inputs, "site_manager_phone_num1") +
        "-" +
        getValue(inputs, "site_manager_phone_num2") +
        "-" +
        getValue(inputs, "site_manager_phone_num3");

      const managerName = getValue(inputs, "site_manager_nm");
      if (buttonType == "modify") {
        if (
          detailSite.phone_num == phoneNum.replaceAll("-", "") ||
          detailSite.manager_phone_num_temp == phoneNum.replaceAll("-", "")
        ) {
          alert("이미 등록된 현장소장 연락처 입니다.");
          return;
        }
      }

      if (managerName == null || phoneNum.length < 13) {
        alert("현장소장 이름과 핸드폰 번호를 확인해주세요");
        return;
      }

      const {
        data: { result },
      } = await axiosInstance.post("/company/get/manager-user-info", {
        user_nm: managerName,
        phone_num: phoneNum.replaceAll("-", ""),
      });

      let userData = null;
      let content = null;
      let response;

      if (Object.keys(result).length != 0) {
        response = true;
        userData = {
          user_seq: result.user_seq,
          user_nm: result.user_nm,
          phone_num: result.phone_num,
          photo: result.photo,
        };
        content = "현장소장을 등록합니다";
      } else {
        response = false;
        userData = {
          user_seq: null,
          user_nm: managerName,
          phone_num: phoneNum,
          photo: null,
        };
        content =
          "대상자가 일사에 가입되어 있지 않습니다.\n현장소장은 일사 가입자만 등록이 가능합니다.";
        // "대상자가 일사에 가입되어 있지 않습니다.\n\n먼저 등록하시겠습니까?\n\n(아래 정보로 일사 가입 요청 문자가 전송됩니다)";
      }

      setPopupData({
        response: response,
        userData: userData,
        content: content,
      });

      setOpendSiteManagerPopup(true);
    },
    [popUpData, detailSite, buttonType]
  );

  const loadSearchSiteManager = useCallback(
    async (value) => {
      const token = localStorage.getItem("admin_token");
      // filter 4 = 입사자 및 승인 대기자 목록
      const {
        data: {
          result: { laborEmployees, countOfLaborEmploy },
        },
      } = await axiosInstance.post(`/admin/laborEmployList`, {
        lang: "ko",
        uuid: "string",
        token,
        search_txt: value,
        page: 1,
        limit: 100,
        filter: "4",
      });
      const currentSiteManger = laborEmployees.filter(
        (e) => e.site_seq === detailSite.site_seq
      );
      setSiteManager(currentSiteManger);
    },
    [siteManager, detailSite]
  );

  useEffect(() => {
    if (opendSelectSiteManager) {
      (async () => {
        await loadSearchSiteManager("");
      })();
    }
  }, [opendSelectSiteManager]);

  // 자식 컨테이너 함수 호출용(카카오 맵 에서 사용)
  const childRef = useRef({});

  /* 수정하기->설계 변경 필드 추가*/
  const handleAddFields = () => {
    // 필드 추가 시 설계 변경 이력이 하나도 없다면, 초기값은 현재 계약금액, 공사기간
    // 설계 변경 이력이 있다면, 초기값은 마지막 설계 변경 필드의 계약금액, 공사 기간
    if (addFormField) {
      let lastContractAmtField = "";
      let lastBeginDayField = "";
      let lastEndDayField = "";

      if (formFields.length > 1) {
        lastContractAmtField = formFields[formFields.length - 1].contractAmt;
        lastBeginDayField = formFields[formFields.length - 1].beginDay;
        lastEndDayField = formFields[formFields.length - 1].endDay;
      } else {
        const formData = Array.from(
          document.querySelectorAll(".modify-site-form input")
        ).reduce((acc, cur, i) => {
          acc[cur.name] = cur.value;
          return acc;
        }, {});

        lastContractAmtField = formData.contract_amt;
        lastBeginDayField = formData.begin_day;
        lastEndDayField = formData.end_day;
      }

      const values = [
        ...formFields,
        {
          contractAmt: lastContractAmtField,
          beginDay: lastBeginDayField,
          endDay: lastEndDayField,
        },
      ];
      setFormFields(values);
    }
    setAddFormField(false);
  };
  /* 기존 설계 변경 출력 전 초기화*/
  const handleInitFields = (changeHistory) => {
    let values = [];
    if (changeHistory.length !== 0) {
      changeHistory?.map((item) => {
        values = [
          ...values,
          {
            contractAmt: addCommas(item.contract_amt),
            beginDay: item.begin_day,
            endDay: item.end_day,
          },
        ];
      });
    }
    setFormFields(values);
  };

  const handleInputChange = (index, e) => {
    const values = [...formFields];
    if (e.target.name === "contractAmt") {
      numberOnly(e);
      e.target.value = addCommas(parseInt(e.target.value));
      if (e.target.value.length >= e.target.maxLength) {
        const nextInput = e.target.nextElementSibling;
        if (nextInput) {
          nextInput.focus();
        }
        values[index].contractAmt = e.target.value;
      }
    } else if (e.target.name === "beginDay") {
      values[index].beginDay = e.target.value;
    } else {
      // 종료일을 시작일 보다 이른 날짜로 잡으면 시작일과 동일하게 입력됨. 시작일을 선택하지 않으면 입력 불가
      if (values[index].beginDay !== "") {
        if (e.target.value <= values[index].beginDay) {
          values[index].endDay = "";
        } else {
          values[index].endDay = e.target.value;
        }
      }
    }
    setFormFields(values);
  };

  const handleInputDate = (e) => {
    // 공사기간(종료일)을 공사기간(시작일)보다 빠른 날짜로 설정하지 못함.
    let beginDay = "";
    if (e.target.id === "end_day_new") {
      beginDay = document.getElementById("begin_day_new");
    } else {
      beginDay = document.getElementById("begin_day_modify");
    }
    if (beginDay.value) {
      if (e.target.value < beginDay.value) {
        e.target.value = "";
      }
    } else {
      e.target.value = "";
    }
  };

  /* 설계 변경 필드 추가 시 추가된 필드에만 입력 가능하게 설정 */
  useEffect(() => {
    if (!addFormField) {
      const contractAmtFieldId = "contractAmt" + (formFields.length - 1);
      const beginDayFieldId = "beginDay" + (formFields.length - 1);
      const endDayFieldId = "endDay" + (formFields.length - 1);
      // console.log(contractAmtFieldId, beginDayFieldId, endDayFieldId);
      let contractAmtField = document.getElementById(contractAmtFieldId);
      let beginDayField = document.getElementById(beginDayFieldId);
      let endDayField = document.getElementById(endDayFieldId);
      contractAmtField.readOnly = false;
      //react-tooltip 사용을 위한 className 설정, 추가된 필드 오버레이 시에만 툴팁을 보여주기 위해.
      contractAmtField.className = "contractAmt" + (formFields.length - 1);
      //tooltip의 anchorSelect 가 build 시 작동하지 않아, tooltip id를 다시 설정해주는 것으로 처리
      contractAmtField.attributes.getNamedItem("data-tooltip-id").value =
        "contractAmt" + (formFields.length - 1);
      beginDayField.readOnly = false;
      endDayField.readOnly = false;
    }
  }, [addFormField]);

  //수정 -> 진행 / 종료 탭 선택
  const handleEndYnTab = (e) => {
    if (e.target.id === "endN") {
      setEndYtabColor("#EDF2F7");
      setEndNtabColor("#fff");
      setEndYn("N");
    } else {
      setEndYtabColor("#fff");
      setEndNtabColor("#EDF2F7");
      setEndYn("Y");
    }
  };

  const numberOnly = (e) => {
    if (e.target.value.match(/[^0-9]/g)) {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }
  };

  const handleSearchTxt = useCallback(
    async (value) => {
      await loadSites(value, page, limit);
      setSearchTxt(value);
    },
    [searchTxt, page, limit]
  );

  const handlePage = useCallback(
    async (value) => {
      await loadSites(searchTxt, value, limit);

      setPage(value);
    },
    [searchTxt, page, limit]
  );

  const handleLimit = useCallback(
    async (e) => {
      await loadSites(searchTxt, page, e.value);

      setLimit(e.value);
      setPage(1);
    },
    [searchTxt, page, limit]
  );

  const handleNewSite = useCallback(async () => {
    const form = Array.from(document.querySelectorAll(".new-site-form input"));

    // 상세 주소, 퇴직공제 가입번호, 고용/산재 관리번호, 국민/건강 관리번호 는 입력하지 않아도 되어, 조건에서 제외 addr2 = 상세 주소 필드
    // if (form.find(e => ((e.value === ''))
    //     && (e.name !== 'retirement_num') && (e.name !== 'employment_num') && (e.name !== 'health_num')
    //     && (e.name !== '') && (e.name !== 'tax_email_addr') && (e.name !== 'tax_user_nm')
    //     && (e.name !== 'tax_phone_num')
    // )) {
    //     alert('필수 입력 항목이 비어있습니다');
    //     modalClose.current = true;
    //     return;
    // }
    const formData = form.reduce((acc, cur, i) => {
      acc[cur.name] = cur.value;
      return acc;
    }, {});

    // 공사명
    if (
      formData["const_nm"] === "" ||
      formData["const_nm"]?.toString().trim() === ""
    ) {
      alert("공사명을 등록해 주세요.");
      findInput(form, "const_nm").value = "";
      findInput(form, "const_nm").focus();
      modalClose.current = true;
      return;
    }

    // 현장명
    if (
      formData["site_nm"] === "" ||
      formData["site_nm"]?.toString().trim() === ""
    ) {
      alert("현장명을 등록해 주세요.");
      findInput(form, "site_nm").value = "";
      findInput(form, "site_nm").focus();
      modalClose.current = true;
      return;
    }

    // 공사기간
    if (!formData["begin_day"]) {
      alert("공사기간 (시작일)을 등록해 주세요.");
      modalClose.current = true;
      return;
    }

    // 공사기간
    if (!formData["end_day"]) {
      alert("공사기간 (종료일)을 등록해 주세요.");
      modalClose.current = true;
      return;
    }

    // 현장코드
    if (
      formData["site_code"] === "" ||
      formData["site_code"]?.toString().trim() === ""
    ) {
      alert("현장코드를 등록해 주세요.");
      findInput(form, "site_code").value = "";
      findInput(form, "site_code").focus();
      modalClose.current = true;
      return;
    }

    // 계약금액(부가세포함)
    if (
      formData["contract_amt"] === "" ||
      formData["contract_amt"]?.toString().trim() === ""
    ) {
      alert("계약금액(부가세포함)을 등록해 주세요.");
      findInput(form, "contract_amt").value = "";
      findInput(form, "contract_amt").focus();
      modalClose.current = true;
      return;
    }

    // 발주처(원도급공사)
    if (
      formData["orderer_nm"] === "" ||
      formData["orderer_nm"]?.toString().trim() === ""
    ) {
      alert("발주처(원도급공사)를 등록해 주세요.");
      findInput(form, "orderer_nm").value = "";
      findInput(form, "orderer_nm").focus();
      modalClose.current = true;
      return;
    }

    // 원도급사(하도급공사)
    if (
      formData["contractor_nm"] === "" ||
      formData["contractor_nm"]?.toString().trim() === ""
    ) {
      alert("원도급사(하도급공사)를 등록해 주세요.");
      findInput(form, "contractor_nm").value = "";
      findInput(form, "contractor_nm").focus();
      modalClose.current = true;
      return;
    }

    // 관리부서
    // if (formData['department'] === '') {
    //     alert('관리 부서를 등록해 주세요.');
    //     modalClose.current = true;
    //     return;
    // }

    //현장소장
    if (!popUpData?.response && popUpData?.userData === null) {
      alert("현장 소장을 등록해 주세요.");
      modalClose.current = true;
      return;
    }

    // 현장주소
    if (addr == "") {
      alert("현장주소를 등록해 주세요.");
      modalClose.current = true;
      return;
    }

    // 가상펜스
    if (
      formData["distance"] === "" ||
      formData["distance"]?.toString().trim() === ""
    ) {
      alert("가상펜스를 등록해 주세요.");
      findInput(form, "distance").value = "";
      findInput(form, "distance").focus();
      modalClose.current = true;
      return;
    }

    // 금액 부분 , 삭제 후 숫자로 변경
    formData["contract_amt"] = parseInt(
      formData["contract_amt"].replaceAll(",", "")
    );
    formData["distance"] = parseInt(formData["distance"].replaceAll(",", ""));
    formData["addr1"] = addr;

    //가상 펜스
    if (50 > formData["distance"] || formData["distance"] > 1000) {
      alert("가상펜스는 50 ~ 1,000 미터 이내로 등록해 주세요.");
      modalClose.current = true;
      return;
    }
    //메인 주소
    if (!formData["addr1"]) {
      alert("필수 입력 항목이 비어있습니다");
      modalClose.current = true;
      return;
    }

    modalClose.current = false;

    //우편번호, 위도, 경도
    formData["postal_cd"] = zoneCode;
    formData["logitude"] = logitude;
    formData["latitude"] = latitude;
    formData["addr2"] = subAddr;

    // 관리 부서 값이 없으면 0으로 전달
    formData["department"] =
      formData["department"] === "" ? 0 : Number(formData["department"]);

    //현장소장 / 임시 현장소장
    if (!cancelSelectSiteManager) {
      if (popUpData.response && popUpData.userData != null) {
        formData["manager_nm_temp"] = null;
        formData["manager_phone_num_temp"] = null;
        formData["manager_nm"] = popUpData.userData.user_nm;
        formData["phone_num"] = popUpData.userData.phone_num.replaceAll(
          "-",
          ""
        );
        formData["manager_user_seq"] = popUpData.userData.user_seq;
      } else {
        formData["manager_nm_temp"] = popUpData?.userData?.user_nm
          ? popUpData?.userData?.user_nm
          : null;
        formData["manager_phone_num_temp"] = popUpData?.userData?.phone_num
          ? popUpData?.userData?.phone_num.replaceAll("-", "")
          : null;
        formData["manager_nm"] = null;
        formData["phone_num"] = null;
        formData["manager_user_seq"] = popUpData?.userData?.user_seq
          ? popUpData?.userData?.user_seq
          : null;
      }

      if (!popUpData.response) {
        formData["user_site_seq"] = null;
      }
    }

    initPopupData();

    const token = localStorage.getItem("admin_token");

    const {
      data: {
        result: { sites, totalCount },
      },
    } = await axiosInstance.post(`/admin/siteNew`, {
      lang: "ko",
      uuid: "string",
      token,
      team_seq: formData["department"],
      ...formData,
    });

    const _sites = await loadSites(searchTxt, page, limit);
    setSites(_sites);
  }, [
    searchTxt,
    page,
    limit,
    detailSite,
    zoneCode,
    addr,
    logitude,
    latitude,
    popUpData,
    cancelSelectSiteManager,
  ]);

  const handleModifySite = useCallback(async () => {
    const form = Array.from(
      document.querySelectorAll(".modify-site-form input")
    );
    // if (form.find(e => ((e.value === '') && (e.name !== 'addr2'))
    //     && (e.name !== 'retirement_num') && (e.name !== 'employment_num') && (e.name !== 'health_num')
    //     && (e.name !== '') && (e.name !== 'manager_nm') && (e.name !== 'tax_email_addr') && (e.name !== 'tax_user_nm')
    //     && (e.name !== 'tax_phone_num')
    // )) {
    //     alert('필수 입력 항목이 비어있습니다');
    //     modalClose.current = true;
    //     return;
    // }

    const formData = form.reduce((acc, cur, i) => {
      acc[cur.name] = cur.value;
      return acc;
    }, {});

    // 관리 부서 값이 없으면 0으로 전달
    formData["department"] = Number(formData["department"]);

    // 공사명
    if (
      formData["const_nm"] === "" ||
      formData["const_nm"]?.toString().trim() === ""
    ) {
      alert("공사명을 등록해 주세요.");
      findInput(form, "const_nm").value = "";
      findInput(form, "const_nm").focus();
      modalClose.current = true;
      return;
    }

    // 현장명
    if (
      formData["site_nm"] === "" ||
      formData["site_nm"]?.toString().trim() === ""
    ) {
      alert("현장명을 등록해 주세요.");
      findInput(form, "site_nm").value = "";
      findInput(form, "site_nm").focus();
      modalClose.current = true;
      return;
    }

    // 현장코드
    if (
      formData["site_code"] === "" ||
      formData["site_code"]?.toString().trim() === ""
    ) {
      alert("현장코드를 등록해 주세요.");
      findInput(form, "site_code").value = "";
      findInput(form, "site_code").focus();
      modalClose.current = true;
      return;
    }

    // 계약금액(부가세포함)
    if (
      formData["contract_amt"] === "" ||
      formData["contract_amt"]?.toString().trim() === ""
    ) {
      alert("계약금액(부가세포함)을 등록해 주세요.");
      findInput(form, "contract_amt").value = "";
      findInput(form, "contract_amt").focus();
      modalClose.current = true;
      return;
    }

    // 발주처(원도급공사)
    if (
      formData["orderer_nm"] === "" ||
      formData["orderer_nm"]?.toString().trim() === ""
    ) {
      alert("발주처(원도급공사)를 등록해 주세요.");
      findInput(form, "orderer_nm").value = "";
      findInput(form, "orderer_nm").focus();
      modalClose.current = true;
      return;
    }

    // 원도급사(하도급공사)
    if (
      formData["contractor_nm"] === "" ||
      formData["contractor_nm"]?.toString().trim() === ""
    ) {
      alert("원도급사(하도급공사)를 등록해 주세요.");
      findInput(form, "contractor_nm").value = "";
      findInput(form, "contractor_nm").focus();
      modalClose.current = true;
      return;
    }

    // 관리부서
    // if (formData['department'] === '') {
    //     alert('관리 부서를 등록해 주세요.');
    //     modalClose.current = true;
    //     return;
    // }

    // 가상펜스
    if (
      formData["distance"] === "" ||
      formData["distance"]?.toString().trim() === ""
    ) {
      alert("가상펜스를 등록해 주세요.");
      findInput(form, "distance").value = "";
      findInput(form, "distance").focus();
      modalClose.current = true;
      return;
    }

    // 가상펜스
    formData["distance"] = parseInt(formData["distance"].replaceAll(",", ""));
    if (50 > formData["distance"] || formData["distance"] > 1000) {
      alert("가상펜스는 50 ~ 1,000 미터 이내로 등록해 주세요.");
      modalClose.current = true;
      return;
    }

    formData["contract_amt"] = parseInt(
      formData["contract_amt"].replaceAll(",", "")
    );
    // 설계 변경 추가가 있을 경우, 설계 변경 필드의 데이터 추가
    if (!addFormField) {
      //마지막 formField = 추가된 설계 변경 필드
      formData["change_contract_amt"] = parseInt(
        formFields[formFields.length - 1].contractAmt.replaceAll(",", "")
      );
      formData["change_begin_day"] = formFields[formFields.length - 1].beginDay;
      formData["change_end_day"] = formFields[formFields.length - 1].endDay;
    }

    delete formData.beginDay;
    delete formData.endDay;
    delete formData.contractAmt;

    // 현장 진행 / 종료 업데이트를 위해 key 추가
    formData["end_yn"] = endYn;

    //우편번호, 위도, 경도
    formData["postal_cd"] = zoneCode;
    formData["logitude"] = logitude;
    formData["latitude"] = latitude;

    //현장소장 / 임시 현장소장
    if (
      detailSite?.manager_nm_temp !== null &&
      detailSite?.manager_phone_num_temp !== null &&
      detailSite?.manager_nm === null &&
      detailSite?.phone_num === ""
    ) {
      //  소장(비회원)
      if (!popUpData.response && popUpData.userData === null) {
        formData["manager_nm_temp"] = null;
        formData["manager_phone_num_temp"] = null;
        formData["manager_nm"] = null;
        formData["phone_num"] = null;
        formData["manager_user_seq"] = null;
      }
    }

    if (popUpData.response && popUpData.userData != null) {
      //   소장 변경(회원)
      formData["manager_nm_temp"] = null;
      formData["manager_phone_num_temp"] = null;
      formData["manager_nm"] = popUpData?.userData?.user_nm;
      formData["phone_num"] = popUpData?.userData?.phone_num.replaceAll(
        "-",
        ""
      );
      formData["manager_user_seq"] = popUpData?.userData?.user_seq;
    } else {
      // 소장 변경(비회원)
      if (popUpData?.userData?.user_seq === null) {
        formData["manager_nm"] = null;
        formData["phone_num"] = null;
        formData["manager_user_seq"] = null;
        formData["manager_nm_temp"] = popUpData?.userData?.user_nm;
        formData["manager_phone_num_temp"] =
          popUpData?.userData?.phone_num.replaceAll("-", "");
      }
    }

    const {
      data: {
        result: { sites, totalCount },
      },
    } = await axiosInstance.post(`/admin/siteModify`, {
      lang: "ko",
      uuid: "string",
      site_seq: detailSite.site_seq,
      corp_seq: detailSite.corp_seq,
      team_seq: formData["department"],
      ...formData,
    });

    const _sites = await loadSites(searchTxt, page, limit);

    setDetailSite((e) =>
      _sites.find((e) => e.site_seq === detailSite.site_seq)
    );
    initPopupData();
  }, [
    searchTxt,
    page,
    limit,
    detailSite,
    addFormField,
    endYn,
    zoneCode,
    logitude,
    latitude,
    popUpData,
    cancelSelectSiteManager,
  ]);

  const handleManagerChange = useCallback(() => {
    const managerInput = document.getElementById("manager_nm_" + buttonType);
    managerInput.value = popUpData.userData.user_nm;
  });

  const loadSites = async (search_txt, page, limit) => {
    setPage(page);
    setLimit(limit);

    const token = localStorage.getItem("admin_token");

    const {
      data: {
        result: { sites, totalCount },
      },
    } = await axiosInstance.post(`/admin/siteList`, {
      lang: "ko",
      uuid: "string",
      token,
      search_txt,
      page,
      limit,
    });

    let workField = await axiosInstance.post(`/code/codeList`, {
      lang: "ko",
      uuid: "string",
      token,
      mst_cd_id: "M0003",
    });
    let teamsResp = await axiosInstance.post(`/company/listTeam`, {
      lang: "ko",
      uuid: "string",
      token,
    });

    teamsResp = teamsResp?.data?.result.map((e) => {
      return { value: e.team_seq, label: e.team_nm };
    });

    setTeamsOptions(teamsResp);
    workField = workField.data.result
      .filter((e) => e.dtl_cd_nm !== "Reserved")
      .map((e) => {
        return { value: e.dtl_cd_id, label: e.dtl_cd_nm };
      });

    setWorkFieldOptions(workField);
    setSites(sites);
    setTotalCount(totalCount);

    setTimeout(() => {
      setLoading(false);
    }, 300);
    return sites;
  };

  useEffect(() => {
    (async () => {
      await loadSites("", 1, options[0].value);
    })();
  }, []);

  // 근무조건 설정 시간 변경
  const requestModifyTime = async () => {
    const weekLen = Object.values(workWeek).filter(
      (item) => item === true
    ).length;

    if (workWeekSelect === 0) {
      alert("근무일을 선택해주세요");
      return;
    }

    if (weekLen === 0) {
      alert("휴일을 선택해주세요");
      return;
    }
    if (weekLen + workWeekSelect < 7) {
      alert("근무일와 휴일 합이 7일 이어야 합니다");
      return;
    }

    const request = {
      site_seq: modifyTimeData.site_seq,
      time_range: `00:${modifyTimeData.time_range}:00`,
      am_begin: modifyTimeData.am_begin,
      am_end: modifyTimeData.am_end,
      pm_begin: modifyTimeData.pm_begin,
      pm_end: modifyTimeData.pm_end,
      satday_yn: modifyTimeData.satday_yn,
      satday_begin: modifyTimeData.satday_begin,
      satday_end: modifyTimeData.satday_end,
      satday_pm_begin: modifyTimeData.satday_pm_begin,
      satday_pm_end: modifyTimeData.satday_pm_end,
      weekend_yn: modifyTimeData.weekend_yn,
      weekend_begin: modifyTimeData.weekend_begin,
      weekend_end: modifyTimeData.weekend_end,
      weekend_pm_begin: modifyTimeData.weekend_pm_begin,
      weekend_pm_end: modifyTimeData.weekend_pm_end,
      overtime_yn: modifyTimeData.overtime_yn,
      overtime_begin: modifyTimeData.overtime_begin,
      overtime_end: modifyTimeData.overtime_end,
      workDayCount: workWeekSelect,
      restDay: Object.keys(workWeek).filter((day) => workWeek[day]),
    };

    const result = await axiosInstance.post(
      `/admin/siteModify/workTime`,
      request
    );
    setOpendWorkTimeSet(false);
    if (result.data.code === 200) {
      await loadSites(searchTxt, page, limit);
    } else {
      alert("근무조건 설정에 실패하였습니다.");
    }
  };

  // 근무조건 설정 시간 유효성 검사
  function validateTime() {
    const time = modifyTimeData;

    if (
      adaptTime(time.am_begin, time.am_end) !== 240 ||
      time.am_begin === null ||
      time.am_end === null
    ) {
      alert(
        "평일 오전 근무 시간은 4시간으로 설정해 주세요.",
        adaptTime(time.am_begin, time.am_end)
      );
      return false;
    }
    if (
      adaptTime(time.pm_begin, time.pm_end) !== 240 ||
      time.pm_begin === null ||
      time.pm_end === null
    ) {
      alert("평일 오후 근무 시간은 4시간으로 설정해 주세요.");
      return false;
    }

    if (time.satday_yn === "Y") {
      const minute = adaptTime(time.satday_begin, time.satday_end);
      if (
        minute < 60 ||
        minute > 240 ||
        time.satday_begin === null ||
        time.satday_end === null
      ) {
        alert("토요일 오전 근무 시간은 1~4시간 사이로 설정해 주세요.");
        return false;
      }
      const minute2 = adaptTime(time.satday_pm_begin, time.satday_pm_end);
      if (
        minute2 < 60 ||
        minute2 > 240 ||
        time.satday_pm_begin === null ||
        time.satday_pm_end === null
      ) {
        alert("토요일 오후 근무 시간은 1~4시간 사이로 설정해 주세요.");
        return false;
      }
    }

    if (time.weekend_yn === "Y") {
      const minute = adaptTime(time.weekend_begin, time.weekend_end);
      if (
        minute < 60 ||
        minute > 240 ||
        time.weekend_begin === null ||
        time.weekend_end === null
      ) {
        alert("일요일/공휴일 오전 근무 시간은 1~4시간 사이로 설정해 주세요.");
        return false;
      }
      const minute2 = adaptTime(time.weekend_pm_begin, time.weekend_pm_end);
      if (
        minute2 < 60 ||
        minute2 > 240 ||
        time.weekend_pm_begin === null ||
        time.weekend_pm_end === null
      ) {
        alert("일요일/공휴일 오후 근무 시간은 1~4시간 사이로 설정해 주세요.");
        return false;
      }
    }

    if (time.overtime_yn === "Y") {
      const minute = adaptTime(time.overtime_begin, time.overtime_end);
      if (
        minute < 120 ||
        minute > 240 ||
        time.overtime_begin === null ||
        time.overtime_end === null
      ) {
        alert("야간 근무 시간은 2~4시간 사이로 설정해 주세요.");
        return false;
      }
    }

    if (time.time_range < 0 || time.time_range > 30) {
      alert("오차범위는 0~30분 사이로 설정해 주세요.");
      return false;
    }

    return true;
  }

  function adaptTime(time1, time2) {
    let t1 = new Date(`1970-01-01T${time1}`);
    let t2 = new Date(`1970-01-01T${time2}`);

    // 두 시간 사이의 차이를 계산
    let difference = Math.abs(t2 - t1);

    return Math.floor(difference / 1000 / 60);
  }

  const checkCompanyPlan = useCallback(async () => {
    try {
      await axiosInstance.post("/company/check-plan");
    } catch (e) {
      setOpenPlanSelect(true);
      return false;
    }
    return true;
  }, []);

  const findInput = (form, name) => {
    return form.find((e) => e.name === name);
  };

  const handleCheck = (checked, name) => {
    const weekLen = Object.values(workWeek).filter(
      (item) => item === true
    ).length;
    if (weekLen + workWeekSelect >= 7 && checked) {
      alert("근무일와 휴일 합이 7일 이어야 합니다");
      return 1;
    } else {
      if (!name) return;
      setWorkWeek((prevState) => ({ ...prevState, [name]: checked }));
      return 0;
    }
  };

  return (
    <AxiosNavigate>
      <div>
        <Header>
          <div>현장 관리</div>
          <div
            style={{ display: "flex", alignItems: "center", columnGap: "16px" }}
          >
            <SearchBox
              placeholder="현장명 검색"
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
                  if (
                    e.target.value.length > 0 &&
                    e.target.value.trim().length === 0
                  ) {
                    alert("현장명을 입력해 주세요");
                    setSearchValue(e.target.value.trim());
                    return;
                  }
                  handleSearchTxt(e.target.value.replaceAll(" ", ""));
                  setSearchValue(e.target.value.trim());
                }
              }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            ></SearchBox>

            <Button
              style={{ backgroundColor: "#3279F5", color: "#fff" }}
              onClick={async () => {
                if (cookies.role.admin) {
                  //관리자가 일때
                  if (!(await checkCompanyPlan())) return;
                  setOpendNewSite(true);
                  handleAddrAndZoneCode("", "", "");
                  handleLogitudeAndLatitude(null, null);
                  setButtonType("new");
                } else {
                  //관리자가 아닐때
                  alert("권한이 없습니다. 관리자에게 문의해 주시기 바랍니다.");
                  return;
                }
              }}
            >
              <Icon src="/assets/icons/plus.svg" />
              현장추가
            </Button>
          </div>
        </Header>
        {loading ? (
          <Loading></Loading>
        ) : (
          <>
            {" "}
            <TableContainer>
              <TableWrapper>
                <Table>
                  <thead>
                    <tr>
                      <th>현장코드</th>
                      <th width={"700px"}>현장명</th>
                      <th>발주처/원청사</th>
                      <th>현장소장</th>
                      <th>관리부서</th>
                      <th>진행상태</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sites?.length === 0 ? (
                      <tr style={{ height: "500px" }}>
                        <td colSpan={5}>
                          <EmptyContainer
                            falseText={"등록된 현장이"}
                            style={{ background: "#fff" }}
                          ></EmptyContainer>
                        </td>
                      </tr>
                    ) : (
                      sites?.map((e, i) => (
                        <tr key={i}>
                          <td style={{ width: "50px" }}>{e.site_code}</td>
                          <td style={{ width: "250px" }}>
                            <div
                              style={{
                                minWidth: "162px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {e.site_nm}
                              <div style={{ display: "flex" }}>
                                <Button
                                  onClick={() => {
                                    handleSiteInfo(e.site_seq);
                                    handleLogitudeAndLatitude(null, null);
                                    getSiteAddress(e.site_seq);
                                  }}
                                  style={{ marginLeft: "8px" }}
                                >
                                  상세보기
                                </Button>
                                <Button
                                  onClick={() => {
                                    if (e.end_yn == "Y") {
                                      alert("이미 마감된 현장입니다");
                                      return;
                                    }
                                    setWorkWeekSelect(0);
                                    setWorkWeek({
                                      월: false,
                                      화: false,
                                      수: false,
                                      목: false,
                                      금: false,
                                      토: false,
                                      일: false,
                                    });
                                    handleWorkTimeSet(e.site_seq);
                                    handleLogitudeAndLatitude(null, null);
                                  }}
                                  style={{ marginLeft: "8px" }}
                                >
                                  근무조건설정
                                </Button>
                              </div>
                            </div>
                          </td>
                          <td style={{ width: "50px" }}>{e.orderer_nm}</td>
                          <td style={{ width: "50px" }}>{e.manager_nm}</td>
                          <td style={{ width: "50px" }}>
                            {e?.team_seq === 0
                              ? ""
                              : teamsOptions?.find(
                                  (item) => item.value == e?.team_seq
                                )?.label}
                          </td>
                          <td style={{ width: "50px" }}>
                            {e.end_yn == "Y" ? "종료" : "진행중"}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </TableWrapper>
            </TableContainer>
            <PagingFooter>
              {/*<div></div>*/}
              <Pagination
                prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
                nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
                activePage={page}
                itemsCountPerPage={limit}
                totalItemsCount={totalCount === 0 ? 1 : totalCount}
                pageRangeDisplayed={5}
                hideFirstLastPages={true}
                onChange={handlePage}
              />
              <PagesList options={options} onChange={handleLimit}></PagesList>
            </PagingFooter>
          </>
        )}

        <SideModal
          opend={opendDetailSite}
          closeModal={() => {
            setOpendDetailSite(false);
          }}
          header={"현장 상세정보"}
          buttons={
            <>
              <ModalButton
                onClick={() => {
                  if (
                    !checkRole(location.pathname, cookies.role, RoleType.WRITE)
                  ) {
                    return;
                  }
                  if (detailSite.end_yn === "Y") {
                    alert("이미 종료된 현장입니다.");
                    return;
                  }
                  setOpendModifySite(true);
                  handleInitFields(detailSite.tb_const_site_change_histories);
                  setAddFormField(true);
                  handleAddrAndZoneCode(detailSite.addr1, detailSite.postal_cd);
                  handleLogitudeAndLatitude(
                    detailSite.logitude,
                    detailSite.latitude
                  );

                  // 수정하기 진행 / 종료 표시를 위해
                  if (detailSite.end_yn === "N") {
                    setEndYtabColor("#EDF2F7");
                    setEndNtabColor("#fff");
                    setEndYn("N");
                  }
                  // 현장 상세 정보 => 현장 소장 변경
                  initPopupData();
                  setButtonType("modify");
                  // getSiteAddress(detailSite.site_seq);
                }}
              >
                수정하기
              </ModalButton>
            </>
          }
        >
          <div>
            {detailSite.end_yn === "Y" ? (
              <Tabs>
                <NoCursorTab>진행</NoCursorTab>
                <NoCursorTab style={{ background: "#fff" }}>종료</NoCursorTab>
              </Tabs>
            ) : (
              <Tabs>
                <NoCursorTab style={{ background: "#fff" }}>진행</NoCursorTab>
                <NoCursorTab>종료</NoCursorTab>
              </Tabs>
            )}
          </div>
          <Content>
            <figure>
              <h6>공사명</h6>
              <h5>{detailSite.const_nm}</h5>
            </figure>
            <figure>
              <h6>공사기간</h6>
              <h5>
                {detailSite.begin_day == "1899-11-30"
                  ? "0000년 00월 00일"
                  : dayjs(detailSite.begin_day).format("YYYY년 M월 D일")}{" "}
                ~{" "}
                {detailSite.end_day == "1899-11-30"
                  ? "0000년 00월 00일"
                  : dayjs(detailSite.end_day).format("YYYY년 M월 D일")}
              </h5>
            </figure>
            <figure>
              <h6>현장명</h6>
              <h5>{detailSite.site_nm}</h5>
            </figure>
            <figure>
              <h6>현장코드</h6>
              <h5>{detailSite.site_code}</h5>
            </figure>
            <figure>
              <h6>공종</h6>
              <h5>
                {detailSite.work_field === null
                  ? ""
                  : workFieldOptions.find(
                      (e) => e.value === detailSite.work_field
                    )?.label}
              </h5>
            </figure>
            <figure>
              <h6>계약금액(부가세포함)</h6>
              <h5>{addCommas(detailSite.contract_amt)}원</h5>
            </figure>
            <hr />
            {detailSite.tb_const_site_change_histories?.map((item, index) => (
              <div key={index}>
                <figure>
                  <h6>설계변경 ({index + 1}차)</h6>
                  <h5></h5>
                </figure>
                <figure>
                  <h6>계약금액(부가세포함)</h6>
                  <h5>{addCommas(item.contract_amt)}원</h5>
                </figure>
                <figure>
                  <h6>공사기간</h6>
                  <h5>
                    {dayjs(item.begin_day).format("YYYY년 M월 D일")} ~{" "}
                    {dayjs(item.end_day).format("YYYY년 M월 D일")}
                  </h5>
                </figure>
                <hr />
              </div>
            ))}

            <figure>
              <h6>발주처(원도급공사)</h6>
              <h5>{detailSite.orderer_nm}</h5>
            </figure>
            <figure>
              <h6>원도급사(하도급공사)</h6>
              <h5>{detailSite.contractor_nm}</h5>
            </figure>
            <figure>
              <h6>퇴직공제 가입번호</h6>
              <h5>{detailSite.retirement_num}</h5>
            </figure>
            <figure>
              <h6>고용/산재 관리번호</h6>
              <h5>{detailSite.employment_num}</h5>
            </figure>
            <figure>
              <h6>국민/건강 관리번호</h6>
              <h5>{detailSite.health_num}</h5>
            </figure>
            <figure>
              <h6>관리 부서</h6>
              <h5>
                {detailSite?.team_seq === 0
                  ? ""
                  : teamsOptions?.find((e) => e.value == detailSite?.team_seq)
                      ?.label}
              </h5>
            </figure>
            <hr />
            <figure>
              <h6>현장소장</h6>
              <h5>
                {detailSite.manager_nm
                  ? detailSite.manager_nm
                  : detailSite.manager_nm_temp}
              </h5>
            </figure>
            <figure style={{ alignItems: "baseline" }}>
              <h6>현장 주소</h6>
              <div
                style={{
                  width: "60%",
                  display: "flex",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <div>
                  {getSiteAddressList?.addressList &&
                    getSiteAddressList?.addressList?.map((e, i) => (
                      <h5 key={i}>
                        {e.addr1} {e.addr2}
                      </h5>
                    ))}
                </div>
              </div>
            </figure>
            <figure>
              <h6>가상펜스</h6>
              <h5>{detailSite.distance} 미터</h5>
            </figure>
            <hr />
            <figure>
              <h6>세금계산서 이메일</h6>
              <h5>{detailSite.tax_email_addr}</h5>
            </figure>
            <figure>
              <h6>세금계산서 담당자</h6>
              <h5>{detailSite.tax_user_nm}</h5>
            </figure>
            <figure>
              <h6>담당자 전화번호</h6>
              <h5>{detailSite.tax_phone_num}</h5>
            </figure>
            <hr />
            <figure>
              <h6>평일 오전</h6>
              <h5>
                {detailSite.am_begin} ~ {detailSite.am_end}
              </h5>
            </figure>
            <figure>
              <h6>평일 오후</h6>
              <h5>
                {detailSite.pm_begin} ~ {detailSite.pm_end}
              </h5>
            </figure>
            <figure>
              <h6>토요일 오전</h6>
              <h5>
                {detailSite.satday_yn === "Y"
                  ? `${detailSite.satday_begin} ~ ${detailSite.satday_end}`
                  : "인정안함"}
              </h5>
            </figure>
            <figure>
              <h6>토요일 오후</h6>
              <h5>
                {detailSite.satday_yn === "Y"
                  ? `${detailSite.satday_pm_begin} ~ ${detailSite.satday_pm_end}`
                  : "인정안함"}
              </h5>
            </figure>
            <figure>
              <h6>일요일/공휴일 오전</h6>
              <h5>
                {detailSite.weekend_yn === "Y"
                  ? `${detailSite.weekend_begin} ~ ${detailSite.weekend_end}`
                  : "인정안함"}
              </h5>
            </figure>
            <figure>
              <h6>일요일/공휴일 오후</h6>
              <h5>
                {detailSite.weekend_yn === "Y"
                  ? `${detailSite.weekend_pm_begin} ~ ${detailSite.weekend_pm_end}`
                  : "인정안함"}
              </h5>
            </figure>
            <figure>
              <h6>야간</h6>
              <h5>
                {detailSite.overtime_yn === "Y"
                  ? `${
                      detailSite?.overtime_begin
                        ? detailSite?.overtime_begin
                        : ""
                    } ~ ${
                      detailSite?.overtime_end ? detailSite?.overtime_end : ""
                    }`
                  : "인정안함"}
              </h5>
            </figure>
            <figure>
              <h6>오차범위</h6>
              <h5>
                {dayjs("0000-00-00 " + detailSite.time_range).format("mm") +
                  "분"}
              </h5>
            </figure>
            <hr />
          </Content>
        </SideModal>

        <SideModal
          opend={opendWorkTimeSet}
          closeModal={() => {
            setOpendWorkTimeSet(false);
          }}
          header={"근무조건 설정"}
        >
          <Content>
            <h6>근무일/휴일</h6>
            <span style={{ color: "red", fontSize: "12px" }}>
              * 근무일와 휴일 합이 7일 이어야 합니다
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <h5 style={{ marginBottom: "5px" }}>근무일</h5>
              <div
                style={{
                  width: "100%",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <SelectedFilterBox
                  placeholder="근무일을 선택해주세요"
                  options={workWeekOptions}
                  value={workWeekOptions.find(
                    (item) => item.value === workWeekSelect
                  )}
                  style={{ width: "100%" }}
                  onChange={(event) => {
                    setWorkWeekSelect(event.value);
                    setWorkWeek({
                      월: false,
                      화: false,
                      수: false,
                      목: false,
                      금: false,
                      토: false,
                      일: false,
                    });
                  }}
                ></SelectedFilterBox>
              </div>

              <h5 style={{ marginBottom: "5px" }}>휴일</h5>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4,1fr)",
                  rowGap: "8px",
                  marginBottom: "10px",
                }}
              >
                <CustomChecked
                  style={{ fontSize: "14px" }}
                  onChange={(checked) => {
                    handleCheck(checked, "월");
                  }}
                  data={{
                    text: "월요일",
                    isCheck: workWeek["월"],
                  }}
                />
                <CustomChecked
                  style={{ fontSize: "14px" }}
                  onChange={(checked) => {
                    handleCheck(checked, "화");
                  }}
                  data={{
                    text: "화요일",
                    isCheck: workWeek["화"],
                  }}
                />
                <CustomChecked
                  style={{ fontSize: "14px" }}
                  onChange={(checked) => {
                    handleCheck(checked, "수");
                  }}
                  data={{
                    text: "수요일",
                    isCheck: workWeek["수"],
                  }}
                />
                <CustomChecked
                  style={{ fontSize: "14px" }}
                  onChange={(checked) => {
                    handleCheck(checked, "목");
                  }}
                  data={{
                    text: "목요일",
                    isCheck: workWeek["목"],
                  }}
                />
                <CustomChecked
                  style={{ fontSize: "14px" }}
                  onChange={(checked) => {
                    handleCheck(checked, "금");
                  }}
                  data={{
                    text: "금요일",
                    isCheck: workWeek["금"],
                  }}
                />
                <CustomChecked
                  style={{ fontSize: "14px" }}
                  onChange={(checked) => {
                    handleCheck(checked, "토");
                  }}
                  data={{
                    text: "토요일",
                    isCheck: workWeek["토"],
                  }}
                />
                <CustomChecked
                  style={{ fontSize: "14px" }}
                  onChange={(checked) => {
                    handleCheck(checked, "일");
                  }}
                  data={{
                    text: "일요일",
                    isCheck: workWeek["일"],
                  }}
                />
              </div>
            </div>
            <hr />
            <h6>평일 근무</h6>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <h5 style={{ minWidth: "30px" }}>오전</h5>
              <div style={{ width: "100%", marginRight: "10px" }}>
                <a>시작 시간</a>
                <CustomTimePicker
                  ampm="am"
                  value="am_begin"
                  time={modifyTimeData.am_begin}
                  onChange={changeTime}
                />
              </div>
              <div style={{ width: "100%" }}>
                <a>종료 시간</a>
                <CustomTimePicker
                  ampm="am"
                  value="am_end"
                  time={modifyTimeData.am_end}
                  onChange={changeTime}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <h5 style={{ minWidth: "30px" }}>오후</h5>
              <div style={{ width: "100%", marginRight: "10px" }}>
                <a>시작 시간</a>
                <CustomTimePicker
                  ampm={"pm"}
                  value="pm_begin"
                  time={modifyTimeData.pm_begin}
                  onChange={changeTime}
                />
              </div>
              <div style={{ width: "100%" }}>
                <a>종료 시간</a>
                <CustomTimePicker
                  ampm={"pm"}
                  value="pm_end"
                  time={modifyTimeData.pm_end}
                  onChange={changeTime}
                />
              </div>
            </div>

            {/* 토요일 근무 */}
            <div style={{ display: "flex" }}>
              <h6 style={{ minWidth: "40%" }}>토요일 근무</h6>
              <RadioButton>
                <input
                  type="radio"
                  name="satday_yn"
                  checked={modifyTimeData.satday_yn === "N"}
                  onChange={(e) => changeTime("satday_yn", "N")}
                />
                <span className="checkmark" />
                <h6>인정안함</h6>
              </RadioButton>
              <RadioButton>
                <input
                  type="radio"
                  name="satday_yn"
                  checked={modifyTimeData.satday_yn === "Y"}
                  onChange={(e) => changeTime("satday_yn", "Y")}
                />
                <span className="checkmark" />
                <h6>인정함</h6>
              </RadioButton>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <h5 style={{ minWidth: "30px" }}>오전</h5>
              <div style={{ width: "100%", marginRight: "10px" }}>
                <a>시작 시간</a>
                <CustomTimePicker
                  ampm="am"
                  value="satday_begin"
                  time={modifyTimeData.satday_begin}
                  disabled={modifyTimeData.satday_yn === "N"}
                  onChange={changeTime}
                />
              </div>
              <div style={{ width: "100%" }}>
                <a>종료 시간</a>
                <CustomTimePicker
                  ampm="am"
                  value="satday_end"
                  time={modifyTimeData.satday_end}
                  disabled={modifyTimeData.satday_yn === "N"}
                  onChange={changeTime}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <h5 style={{ minWidth: "30px" }}>오후</h5>
              <div style={{ width: "100%", marginRight: "10px" }}>
                <a>시작 시간</a>
                <CustomTimePicker
                  ampm={"pm"}
                  value="satday_pm_begin"
                  time={modifyTimeData.satday_pm_begin}
                  disabled={modifyTimeData.satday_yn === "N"}
                  onChange={changeTime}
                />
              </div>
              <div style={{ width: "100%" }}>
                <a>종료 시간</a>
                <CustomTimePicker
                  ampm={"pm"}
                  value="satday_pm_end"
                  time={modifyTimeData.satday_pm_end}
                  disabled={modifyTimeData.satday_yn === "N"}
                  onChange={changeTime}
                />
              </div>
            </div>

            {/* 일요일/공휴일 근무 */}
            <div style={{ display: "flex" }}>
              <h6 style={{ minWidth: "40%" }}>일요일/공휴일 근무</h6>
              <RadioButton>
                <input
                  type="radio"
                  name="weekend_yn"
                  checked={modifyTimeData.weekend_yn === "N"}
                  onChange={(e) => changeTime("weekend_yn", "N")}
                />
                <span className="checkmark" />
                <h6>인정안함</h6>
              </RadioButton>
              <RadioButton>
                <input
                  type="radio"
                  name="weekend_yn"
                  checked={modifyTimeData.weekend_yn === "Y"}
                  onChange={(e) => changeTime("weekend_yn", "Y")}
                />
                <span className="checkmark" />
                <h6>인정함</h6>
              </RadioButton>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <h5 style={{ minWidth: "30px" }}>오전</h5>
              <div style={{ width: "100%", marginRight: "10px" }}>
                <a>시작 시간</a>
                <CustomTimePicker
                  ampm="am"
                  value="weekend_begin"
                  time={modifyTimeData.weekend_begin}
                  disabled={modifyTimeData.weekend_yn === "N"}
                  onChange={changeTime}
                />
              </div>
              <div style={{ width: "100%" }}>
                <a>종료 시간</a>
                <CustomTimePicker
                  ampm="am"
                  value="weekend_end"
                  time={modifyTimeData.weekend_end}
                  disabled={modifyTimeData.weekend_yn === "N"}
                  onChange={changeTime}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <h5 style={{ minWidth: "30px" }}>오후</h5>
              <div style={{ width: "100%", marginRight: "10px" }}>
                <a>시작 시간</a>
                <CustomTimePicker
                  ampm={"pm"}
                  value="weekend_pm_begin"
                  time={modifyTimeData.weekend_pm_begin}
                  disabled={modifyTimeData.weekend_yn === "N"}
                  onChange={changeTime}
                />
              </div>
              <div style={{ width: "100%" }}>
                <a>종료 시간</a>
                <CustomTimePicker
                  ampm={"pm"}
                  value="weekend_pm_end"
                  time={modifyTimeData.weekend_pm_end}
                  disabled={modifyTimeData.weekend_yn === "N"}
                  onChange={changeTime}
                />
              </div>
            </div>

            {/* 야간 근무 */}
            <div style={{ display: "flex" }}>
              <h6 style={{ minWidth: "40%" }}>야간 근무</h6>
              <RadioButton>
                <input
                  type="radio"
                  name="overtime_yn"
                  checked={modifyTimeData.overtime_yn === "N"}
                  onChange={(e) => changeTime("overtime_yn", "N")}
                />
                <span className="checkmark" />
                <h6>인정안함</h6>
              </RadioButton>
              <RadioButton>
                <input
                  type="radio"
                  name="overtime_yn"
                  checked={modifyTimeData.overtime_yn === "Y"}
                  onChange={(e) => changeTime("overtime_yn", "Y")}
                />
                <span className="checkmark" />
                <h6>인정함</h6>
              </RadioButton>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              <h5 style={{ minWidth: "30px" }}></h5>
              <div style={{ width: "100%", marginRight: "10px" }}>
                <a>시작 시간</a>
                <CustomTimePicker
                  ampm={"pm"}
                  value="overtime_begin"
                  time={modifyTimeData.overtime_begin}
                  disabled={modifyTimeData.overtime_yn === "N"}
                  onChange={changeTime}
                />
              </div>
              <div style={{ width: "100%" }}>
                <a>종료 시간</a>
                <CustomTimePicker
                  ampm={"pm"}
                  value="overtime_end"
                  time={modifyTimeData.overtime_end}
                  disabled={modifyTimeData.overtime_yn === "N"}
                  onChange={changeTime}
                />
              </div>
            </div>

            <h6 style={{ minWidth: "40%" }}>오차 범위</h6>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <input
                style={{ width: "100px", height: "30px", padding: "0 10px" }}
                maxLength={2}
                value={modifyTimeData.time_range}
                onChange={(e) => {
                  if (e.target.value.match(/[^0-9]/g)) {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }
                  if (e.target.value.length > 2) {
                    e.target.value = e.target.value.slice(0, 2);
                  }
                  changeTime("time_range", e.target.value);
                }}
              />
              <h5 style={{ marginTop: "10px", marginLeft: "10px" }}>분</h5>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{ backgroundColor: "#3279F5", color: "#fff" }}
                onClick={() => {
                  const validate = validateTime();
                  if (validate) {
                    requestModifyTime();
                  }
                  // setWorkWeekSelect(0);
                  // setWorkWeek({
                  //     monday: false,
                  //     tuesday: false,
                  //     wednesday: false,
                  //     thursday: false,
                  //     friday: false,
                  //     saturday: false,
                  //     sunday: false,
                  // });
                }}
              >
                저장하기
              </Button>
            </div>
          </Content>
        </SideModal>

        <Modal
          opend={opendModifySite}
          closeModal={() => {
            setOpendModifySite(modalClose.current);
            modalClose.current = false;
          }}
          header={"현장 정보 등록"}
          okText={"수정하기"}
          handleOk={handleModifySite}
        >
          <Tabs>
            <Tab
              id={"endN"}
              style={{ background: endNtabColor }}
              onClick={handleEndYnTab}
            >
              진행
            </Tab>
            <Tab
              id={"endY"}
              style={{ background: endYtabColor }}
              onClick={handleEndYnTab}
            >
              종료
            </Tab>
          </Tabs>

          <form className="modify-site-form">
            <FieldGroup>
              <Field>
                <h6>
                  공사명 <span>*</span>
                </h6>
                <input
                  defaultValue={detailSite.const_nm}
                  maxLength={50}
                  name="const_nm"
                  placeholder=""
                />
              </Field>

              <Field>
                <h6>
                  현장명 <span>*</span>
                </h6>
                <input
                  defaultValue={detailSite.site_nm}
                  maxLength={50}
                  name="site_nm"
                  placeholder=""
                />
              </Field>

              <Field>
                <h6>
                  공사기간 (시작일) <span>*</span>
                </h6>
                <input
                  id={"begin_day_modify"}
                  type={"date"}
                  defaultValue={detailSite.begin_day}
                  name="begin_day"
                  onKeyDown={(e) => e.preventDefault()}
                  placeholder=""
                />
              </Field>

              <Field>
                <h6>
                  공사기간 (종료일) <span>*</span>
                </h6>
                <input
                  id={"end_day_modify"}
                  type={"date"}
                  defaultValue={detailSite.end_day}
                  name="end_day"
                  placeholder=""
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={handleInputDate}
                />
              </Field>

              <Field>
                <h6>
                  현장코드 <span>*</span>
                </h6>
                <input
                  defaultValue={detailSite.site_code}
                  maxLength={25}
                  name="site_code"
                  placeholder=""
                />
              </Field>

              <Field>
                <h6>
                  공종 <span>*</span>
                </h6>
                <Select
                  name={"work_field"}
                  id={"work_field"}
                  styles={selectStyles}
                  placeholder={"공종을 선택해 주세요"}
                  defaultValue={workFieldOptions.find(
                    (e) => e.value == detailSite.work_field
                  )}
                  options={workFieldOptions}
                />
              </Field>

              <Field fulled>
                <h6>
                  계약금액(부가세포함) <span>*</span>
                </h6>
                <input
                  defaultValue={addCommas(detailSite.contract_amt)}
                  name="contract_amt"
                  onChange={(e) => {
                    numberOnly(e);
                    e.target.value = addCommas(parseInt(e.target.value));
                  }}
                />
              </Field>

              <Field fulled>
                <div
                  style={{
                    backgroundColor: "#EDF2F7",
                    border: "10px solid",
                    borderColor: "white",
                  }}
                >
                  <h4
                    style={{
                      textAlign: "",
                      marginLeft: "12px",
                      marginBottom: "12px",
                    }}
                  >
                    설계변경
                  </h4>
                  <h6
                    style={{
                      textAlign: "",
                      marginLeft: "12px",
                      marginBottom: "12px",
                    }}
                  >
                    <Icon src="/assets/icons/alert-circle.svg" /> 입력 후 꼭
                    재확인해주세요.
                  </h6>
                  {formFields.map((field, index) => (
                    <Tabs key={index}>
                      <Tab>
                        <h6>설계변경 - 계약금액(부가세포함)</h6>
                        <input
                          name="contractAmt"
                          id={"contractAmt" + index}
                          data-tooltip-id="my-tooltip"
                          value={field.contractAmt}
                          readOnly={true}
                          onChange={(e) => {
                            handleInputChange(index, e);
                          }}
                        />
                        <Tooltip
                          id={"contractAmt" + index}
                          content="설계변경 후 내용을 재확인해주세요!"
                        />
                      </Tab>
                      <Tab>
                        <h6>설계변경 - 시작일</h6>
                        <input
                          name="beginDay"
                          type="date"
                          value={field.beginDay}
                          id={"beginDay" + index}
                          readOnly={true}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Tab>
                      <Tab>
                        <h6>설계변경 - 종료일</h6>
                        <input
                          name="endDay"
                          type="date"
                          value={field.endDay}
                          id={"endDay" + index}
                          readOnly={true}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </Tab>
                    </Tabs>
                  ))}
                  <FormButton
                    onClick={() => {
                      handleAddFields();
                    }}
                  >
                    + 설계변경 추가하기
                  </FormButton>
                </div>
              </Field>
              <Field>
                <h6>
                  발주처(원도급공사) <span>*</span>
                </h6>
                <input
                  defaultValue={detailSite.orderer_nm}
                  maxLength={50}
                  name="orderer_nm"
                  placeholder=""
                />
              </Field>

              <Field>
                <h6>
                  원도급사(하도급공사) <span>*</span>
                </h6>
                <input
                  defaultValue={detailSite.contractor_nm}
                  maxLength={50}
                  name="contractor_nm"
                  placeholder=""
                />
              </Field>

              <Field>
                <h6>퇴직공제 가입번호</h6>
                <input
                  defaultValue={detailSite.retirement_num}
                  maxLength={50}
                  name="retirement_num"
                  placeholder=""
                />
              </Field>

              <Field>
                <h6>고용/산재 관리번호</h6>
                <input
                  defaultValue={detailSite.employment_num}
                  maxLength={50}
                  name="employment_num"
                  placeholder=""
                />
              </Field>

              <Field fulled>
                <h6>국민/건강 관리번호</h6>
                <input
                  defaultValue={detailSite.health_num}
                  maxLength={50}
                  name="health_num"
                  placeholder=""
                />
              </Field>

              <Field>
                <h6>세금계산서 이메일</h6>
                <input
                  defaultValue={detailSite.tax_email_addr}
                  maxLength={50}
                  name="tax_email_addr"
                  placeholder=""
                />
              </Field>

              <Field>
                <h6>세금계산서 담당자</h6>
                <input
                  defaultValue={detailSite.tax_user_nm}
                  maxLength={50}
                  name="tax_user_nm"
                  placeholder=""
                />
              </Field>

              <Field>
                <h6>세금계산서 담당자 전화번호</h6>
                <input
                  defaultValue={detailSite.tax_phone_num}
                  maxLength={11}
                  name="tax_phone_num"
                  placeholder=""
                />
              </Field>
              <Field>
                <h6>관리 부서 </h6>
                <Select
                  name={"department"}
                  id={"department"}
                  styles={selectStyles}
                  placeholder={"관리 부서를 선택해 주세요"}
                  defaultValue={teamsOptions.find(
                    (e) => e.value == detailSite.team_seq
                  )}
                  options={teamsOptions}
                />
              </Field>
              <Field fulled>
                <h6>
                  현장소장 <span>*</span>
                </h6>
                <Button
                  style={{
                    backgroundColor: "#708090",
                    color: "aliceblue",
                    marginTop: "7px",
                  }}
                  onClick={() => {
                    setCancelSelectSiteManager(false);
                    setOpendSelectSiteManager(true);
                  }}
                >
                  검색
                </Button>
                <input
                  id={"manager_nm_modify"}
                  defaultValue={
                    detailSite.manager_nm
                      ? detailSite.manager_nm
                      : detailSite.manager_nm_temp
                  }
                  name="manager_nm"
                  placeholder="검색을 이용해 주세요"
                  readOnly={true}
                />
              </Field>

              <Field fulled>
                <h6>
                  현장주소 <span>*</span> (최대 10개까지 등록 가능)
                </h6>

                <Button
                  style={{
                    backgroundColor: "#708090",
                    color: "aliceblue",
                    marginTop: "7px",
                  }}
                  onClick={() => {
                    if (getSiteAddressList?.addressList.length >= 10) {
                      alert("최대 10개까지 등록 가능합니다");
                      return;
                    }
                    setOpendMap(true);
                    setOpenMapMode({ modeName: "add" });
                  }}
                >
                  주소등록
                </Button>
                <ul
                  style={{
                    margin: "5px 0 8px",
                    minHeight: "56px",
                    boxSizing: "border-box",
                    background: "#edf2f7",
                    padding: "8px 5px",
                    borderRadius: "3px",
                  }}
                >
                  {getSiteAddressList?.addressList &&
                    getSiteAddressList?.addressList?.map((e, i) => (
                      <li
                        style={{
                          padding: "3px 0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        key={i}
                      >
                        {`${e.addr1 === null ? "" : e.addr1} ${
                          e.addr2 === null ? "" : e.addr2
                        }`}
                        {e.addr1 !== null && (
                          <div style={{ display: "flex", columnGap: "15px" }}>
                            {e.addr_seq !== -1 && (
                              <Button
                                onClick={() => {
                                  addressDelete(e);
                                }}
                              >
                                삭제
                              </Button>
                            )}
                            <Button
                              onClick={() => {
                                setOpendMap(true);
                                setOpenMapMode({
                                  modeName: "modify",
                                  items: e,
                                });
                              }}
                            >
                              수정
                            </Button>
                          </div>
                        )}
                      </li>
                    ))}
                </ul>
              </Field>

              <Field fulled>
                <h6>
                  가상펜스<span style={{ marginLeft: "3px" }}>*</span> (현장
                  사무실에서부터 거리)
                </h6>
                <input
                  defaultValue={addCommas(detailSite.distance)}
                  name="distance"
                  onChange={(e) => {
                    numberOnly(e);
                    e.target.value = addCommas(parseInt(e.target.value));
                  }}
                />
                <h6>* 가상펜스는 50 ~ 1,000 미터 이내로 등록해 주세요.</h6>
              </Field>
            </FieldGroup>
          </form>
        </Modal>

        <Modal
          opend={opendNewSite}
          closeModal={() => {
            setOpendNewSite(modalClose.current);
            modalClose.current = false;
          }}
          header={"현장 정보 등록"}
          okText={"등록하기"}
          handleOk={handleNewSite}
        >
          <form className="new-site-form">
            <FieldGroup>
              <Field>
                <h6>
                  공사명 <span>*</span>
                </h6>
                <input name="const_nm" maxLength={50} placeholder="" />
              </Field>

              <Field>
                <h6>
                  현장명 <span>*</span>
                </h6>
                <input name="site_nm" maxLength={50} placeholder="" />
              </Field>

              <Field>
                <h6>
                  공사기간 (시작일) <span>*</span>
                </h6>
                <input
                  id="begin_day_new"
                  type="date"
                  name="begin_day"
                  placeholder=""
                  onKeyDown={(e) => e.preventDefault()}
                />
              </Field>

              <Field>
                <h6>
                  공사기간 (종료일) <span>*</span>
                </h6>
                <input
                  id="end_day_new"
                  type="date"
                  name="end_day"
                  placeholder=""
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={handleInputDate}
                />
              </Field>

              <Field>
                <h6>
                  현장코드 <span>*</span>
                </h6>
                <input name="site_code" maxLength={25} placeholder="" />
              </Field>

              <Field>
                <h6>
                  공종 <span>*</span>
                </h6>
                <Select
                  name={"work_field"}
                  styles={selectStyles}
                  defaultValue={workFieldOptions[0]}
                  options={workFieldOptions}
                />
              </Field>

              <Field fulled>
                <h6>
                  계약금액(부가세포함) <span>*</span>
                </h6>
                <input
                  name="contract_amt"
                  onChange={(e) => {
                    numberOnly(e);
                    e.target.value = addCommas(parseInt(e.target.value));
                  }}
                />
              </Field>

              <Field>
                <h6>
                  발주처(원도급공사) <span>*</span>
                </h6>
                <input name="orderer_nm" maxLength={50} placeholder="" />
              </Field>

              <Field>
                <h6>
                  원도급사(하도급공사) <span>*</span>
                </h6>
                <input name="contractor_nm" maxLength={50} placeholder="" />
              </Field>

              <Field>
                <h6>퇴직공제 가입번호</h6>
                <input name="retirement_num" maxLength={50} placeholder="" />
              </Field>

              <Field>
                <h6>고용/산재 관리번호</h6>
                <input name="employment_num" maxLength={50} placeholder="" />
              </Field>

              <Field fulled>
                <h6>국민/건강 관리번호</h6>
                <input name="health_num" maxLength={50} placeholder="" />
              </Field>

              <Field>
                <h6>세금계산서 이메일</h6>
                <input name="tax_email_addr" maxLength={50} placeholder="" />
              </Field>

              <Field>
                <h6>세금계산서 담당자</h6>
                <input name="tax_user_nm" maxLength={50} placeholder="" />
              </Field>

              <Field>
                <h6>세금계산서 담당자 전화번호</h6>
                <input name="tax_phone_num" maxLength={11} placeholder="" />
              </Field>
              <Field>
                <h6>관리 부서 </h6>
                <Select
                  name={"department"}
                  id={"department"}
                  styles={selectStyles}
                  placeholder={"관리 부서를 선택해 주세요"}
                  //defaultValue={teamsOptions.find((e) => e.value == detailSite.work_field)}
                  options={teamsOptions}
                />
              </Field>
              <Field fulled>
                <h6>
                  현장소장 <span>*</span>
                </h6>
                <Button
                  style={{
                    backgroundColor: "#708090",
                    color: "aliceblue",
                    marginTop: "7px",
                  }}
                  onClick={() => {
                    initPopupData();
                    setCancelSelectSiteManager(false);
                    setOpendSelectSiteManager(true);
                  }}
                >
                  검색
                </Button>
                <input
                  id={"manager_nm_new"}
                  name={"manager_nm_new"}
                  placeholder="검색을 이용해 주세요"
                  readOnly={true}
                />
              </Field>

              <Field fulled>
                <h6>
                  현장주소 <span>*</span> (2개 이상의 현장주소 등록은 현장정보
                  수정에서 가능합니다.)
                </h6>
                <Button
                  style={{
                    backgroundColor: "#708090",
                    color: "aliceblue",
                    marginTop: "7px",
                  }}
                  onClick={() => {
                    setOpendMap(true);
                    setOpenMapMode({ modeName: "create" });
                  }}
                >
                  주소등록
                </Button>

                <ul
                  style={
                    !addr
                      ? {
                          margin: "5px 0 8px",
                          borderRadius: "3px",
                          padding: "8px 5px",
                        }
                      : {
                          margin: "5px 0 8px",
                          background: "#edf2f7",
                          borderRadius: "3px",
                          padding: "8px 5px",
                        }
                  }
                >
                  {addr && (
                    <li style={{ padding: "3px 0" }}>
                      {`${addr} ${subAddr === null ? "" : subAddr}`}
                    </li>
                  )}
                </ul>
              </Field>
              <Field fulled>
                <h6>
                  가상펜스<span style={{ marginLeft: "3px" }}>*</span> (현장
                  사무실에서부터 거리)
                </h6>
                <input
                  name="distance"
                  onChange={(e) => {
                    numberOnly(e);
                    e.target.value = addCommas(parseInt(e.target.value));
                  }}
                />
                <h6>* 가상펜스는 50 ~ 1,000 미터 이내로 등록해 주세요.</h6>
              </Field>
            </FieldGroup>
          </form>
        </Modal>

        <Modal
          opend={opendMap}
          // formRef={childRef}
          closeModal={(e) => {
            if (e) {
              setOpendMap(false); // 취소버튼 클릭시에만 map 팝업 닫음
            }
          }}
          // closeModal={() => setOpendMap(false)}
          header={`${
            openMapMode.modeName === "modify" ? "주소 수정" : "주소 등록"
          }`}
          okText={"확인"}
          handleOk={() => childRef.current.setAddrData()}
        >
          {/* <KakaoMap
            setAddrInfo={handleAddrInfo}
            ref={childRef}
            openMapMode={openMapMode}
            setOpendMap={setOpendMap}
            addressList={getSiteAddressList?.addressList}
          ></KakaoMap> */}
          <NaverMap
            setAddrInfo={handleAddrInfo}
            ref={childRef}
            openMapMode={openMapMode}
            setOpendMap={setOpendMap}
            addressList={getSiteAddressList?.addressList}
          ></NaverMap>
        </Modal>

        <PopUpModal
          opend={opendSelectSiteManager}
          closeModal={() => {
            setOpendSelectSiteManager(false);
          }}
          header={"현장소장 등록"}
          okText={""}
          cancelText={""}
          handleOk={() => {}}
          widthCheck={"40%"}
        >
          <Content>
            <Field>현장소장을 등록해주세요</Field>
            <br />
            <Field>
              <h5>이름</h5>
              <input
                style={{ width: "250px", minWidth: "250px" }}
                name="site_manager_nm"
              />
            </Field>

            <h5>휴대전화</h5>
            <Field
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                style={{ width: "70px", minWidth: "70px" }}
                name="site_manager_phone_num1"
                type="text"
                maxLength={"3"}
                defaultValue={"010"}
                readOnly={true}
                onChange={(e) => {
                  numberOnly(e);
                  if (e.target.value.length >= e.target.maxLength) {
                    const nextInput = e.target.nextElementSibling;
                    if (nextInput) {
                      nextInput.focus();
                    }
                  }
                }}
              />
              -
              <input
                style={{ width: "70px", minWidth: "70px" }}
                name="site_manager_phone_num2"
                type="text"
                maxLength={"4"}
                onChange={(e) => {
                  numberOnly(e);
                  if (e.target.value.length >= e.target.maxLength) {
                    const nextInput = e.target.nextElementSibling;
                    if (nextInput) {
                      nextInput.focus();
                    }
                  }
                }}
              />
              -
              <input
                style={{ width: "70px", minWidth: "70px" }}
                name="site_manager_phone_num3"
                type="text"
                maxLength={"4"}
                onChange={(e) => {
                  numberOnly(e);
                  if (e.target.value.length >= e.target.maxLength) {
                    const nextInput = e.target.nextElementSibling;
                    if (nextInput) {
                      nextInput.focus();
                    }
                  }
                }}
              />
              <Button
                style={{
                  display: "inline-block",
                  minWidth: "70px",
                  height: "35px",
                  textAlign: "center",
                  alignContent: "center",
                  backgroundColor: "#708090",
                  color: "aliceblue",
                  marginLeft: "10px",
                  marginBottom: "5px",
                }}
                onClick={async () => {
                  await handleFindManager();
                }}
              >
                소장 검색
              </Button>
            </Field>
          </Content>
        </PopUpModal>

        <PopUpModal
          opend={opendSiteManagerPopup}
          closeModal={() => {
            setCancelSelectSiteManager(true);
            setOpendSelectSiteManager(false);
            setOpendSiteManagerPopup(false);
          }}
          header={"현장소장 등록"}
          okText={"확인"}
          cancelText={"취소"}
          handleOk={() => {
            if (popUpData?.response) {
              handleManagerChange();
            }
            setOpendSelectSiteManager(false);
            setOpendSiteManagerPopup(false);
          }}
          widthCheck={"30%"}
          heightCheck={"40%"}
        >
          <Content style={{ whiteSpace: "pre-wrap" }}>
            {popUpData.content}
          </Content>
          {/* <Content
            style={{ whiteSpace: "pre-wrap", color: "red", marginTop: "10px" }}
          >
            {popUpData.response == false
              ? "* 현장 등록 또는 수정 완료 시 문자가 전송됩니다."
              : ""}
          </Content> */}
          <br />

          {popUpData?.userData != null ? (
            <LaborCard>
              <Avatar
                style={{
                  marginRight: "3px",
                  width: "64px",
                  height: "64px",
                  backgroundImage: `url(${
                    popUpData?.userData.photo || "/assets/images/profile.png"
                  })`,
                }}
              />
              <LaborCardContent>
                {/*<h5>{JSON.stringify(popUpData)}</h5>*/}
                <h4>
                  {popUpData?.userData.user_nm} /{" "}
                  {popUpData?.userData.phone_num}
                </h4>
              </LaborCardContent>
            </LaborCard>
          ) : (
            <LaborCard>
              <div />
            </LaborCard>
          )}
        </PopUpModal>

        <PlanSelectModal
          opend={openPlanSelect}
          closeModal={() => {
            setOpenPlanSelect(false);
          }}
          handleOk={() => {}}
          widthCheck={"50%"}
          maxHeightCheck={"797"}
        >
          <div>test</div>
        </PlanSelectModal>
      </div>
    </AxiosNavigate>
  );
}

export const FormButton = styled.div`
  cursor: pointer;
  padding: 8px 14px;
  border-radius: 8px;
  background-color: #708090;
  color: aliceblue;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  //width: fit-content;
  margin-top: 12px;
  margin-bottom: 15px;
`;
const CheckboxIcon = styled.div`
  display: flex;
  flex: 1;
  background-color: #3279f5;
  align-self: stretch;
  cursor: pointer;
`;

const CheckboxIconInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckboxIconImage = styled.img`
  width: 12px;
  height: 12px;
`;
const Search = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #cbd5e0;
  overflow: hidden;
  margin-right: 16px;
`;

const SearchIcon = styled.img`
  padding-left: 12px;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: 0px;
  outline: none;

  padding: 6px 12px;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  :focus {
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  background: #fff;

  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;
`;

const Select = styled(ReactSelect)`
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  border-radius: 6px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  background: #fff;
  margin-top: 5px;

  div {
    cursor: pointer;
    color: #4a5568;
    border-color: #e2e8f0;
  }
`;

const Icon = styled.img`
  width: 16px;
`;

const TableContainer = styled.div`
  padding: 0 32px;
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: left;
    padding: 2px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    //background-color: #F7FAFC;
  }

  tr:last-child td {
    border-bottom: none;
  }

  thead {
    height: 31px;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-right: 6px;
  }
`;

const Content = styled.div`
  figure {
    margin: 0;
    display: flex;
    align-items: center;
    min-height: 34px;
  }

  h6,
  h5 {
    margin: 0;
  }

  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 127px;
  }

  h5 {
    color: rgba(0, 0, 0, 0.92);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  a {
    color: rgba(0, 0, 0, 0.92);
    font-size: 12px;
  }

  hr {
    background: #edf2f7;
    color: #edf2f7;
    border: 1px solid #edf2f7;
    margin: 12px 0;
  }

  input {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    appearance: none;
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 8px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #fff;
    border: 1px solid #cbd5e0;
    border-radius: 6px;
    margin-bottom: 12px;
    margin-top: 6px;

    :focus {
    }
  }

  input[type="checkbox"] {
    display: none;
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #edf2f7;
  padding: 4px;
  margin-bottom: 28px;
`;

const Tab = styled.div`
  width: 50%;
  text-align: center;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
`;
const NoCursorTab = styled.div`
  width: 50%;
  text-align: center;
  padding: 6px 10px;
  border-radius: 4px;
`;

const FieldGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0 14px;
`;

const Field = styled.div`
  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
  }

  span {
    color: #e53e3e;
  }

  input {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    appearance: none;
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 8px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #fff;
    border: 1px solid #cbd5e0;
    border-radius: 6px;
    margin-bottom: 12px;
    margin-top: 6px;

    :focus {
    }
  }

  width: ${({ fulled }) => (fulled ? `100%` : "48%")};

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;
const Avatar = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  margin-left: 8px;
`;
const Card = styled.div`
  border-radius: 12px;
  background: #f7fafc;
  padding: 20px;
  margin-top: 20px;

  h5 {
    color: #171923;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
    margin-bottom: 16px;
  }
`;

const LaborCard = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 0;
  cursor: pointer;

  //&:hover {
  //    background-color: #EBF2FE !important;
  //    border-radius: 4px;
  //}
`;

const LaborCardContent = styled.div`
  h5,
  h6 {
    margin: 0;
  }

  h5 {
    color: #4a5568;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  h6 {
    color: #718096;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;
const EmptyLaborCard = styled.div`
  color: #4a5568;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding-top: 26px;
`;

const RadioButton = styled.label`
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  margin-right: 20px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 50%;
  }

  input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top: 4px;
    left: 4px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
  }

  h6 {
    width: 100%;
  }
`;

export default Site;
