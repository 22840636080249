import styled from '@emotion/styled';
import {forwardRef} from 'react';
import dayjs from 'dayjs';
import {TablePrintStyle} from '../../library/styled-components';

const StatementSubmissionPagePrint = forwardRef((props, ref) => {

    const report = props.data.report;

    return (
      <Container ref={ref}>
        <Header>
          <div>
            <Title>
              {dayjs(props.data.date).format("YYYY년 MM월")}분 지급명세서 신고
            </Title>
            <HeaderGroup>
              <Badge>
                <Label>현장명</Label>
                <Text>{props.data.site}</Text>
              </Badge>
            </HeaderGroup>
          </div>

          <div></div>
        </Header>

        <TablePrintStyle>
          <div className="table_wrapper">
            <table>
              <thead style={{ height: "31px" }}>
                <tr>
                  <th>구분</th>
                  <th>이름</th>
                  <th>국적</th>
                  <th>주민등록번호</th>
                  <th>근무년월</th>
                  <th>지급년월</th>
                  <th>근무일수</th>
                  <th>노무비총액</th>
                  <th>소득세</th>
                  <th>주민세</th>
                  <th>실지급액</th>
                  <th>현장명</th>
                </tr>
              </thead>
              <tbody>
                {report.users.length &&
                  report.users.map((e, i) => {
                    if (i === report.users.length - 1) {
                      return (
                        <tr key={i} className="none">
                          <th colSpan={6}>총계</th>
                          <th>{e.attend_cnt}</th>
                          <th className={"table-right"}>
                            {e.salary_amt.toLocaleString()}
                          </th>
                          <th className={"table-right"}>
                            {e.tax1.toLocaleString()}
                          </th>
                          <th className={"table-right"}>
                            {e.tax2.toLocaleString()}
                          </th>
                          <th className={"table-right"}>
                            {e.pay_amt.toLocaleString()}
                          </th>
                          <th></th>
                        </tr>
                      );
                    }
                    return (
                      <tr key={i}>
                        <td>
                          <div>{i + 1}</div>
                        </td>
                        <td>{e.user_nm}</td>
                        <td>{e.nationality}</td>
                        <td>{e.id_num}</td>
                        <td>{e.work_dt}</td>
                        <td>{e.pay_dt}</td>
                        <td>{e.attend_cnt}</td>
                        <td className={"table-right"}>
                          {e.salary_amt.toLocaleString()}
                        </td>
                        <td className={"table-right"}>
                          {e.tax1.toLocaleString()}
                        </td>
                        <td className={"table-right"}>
                          {e.tax2.toLocaleString()}
                        </td>
                        <td className={"table-right"}>
                          {e.pay_amt.toLocaleString()}
                        </td>
                        <td style={{ textAlign: "left" }}>{e.site_nm}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </TablePrintStyle>
      </Container>
    );
});

const Container = styled.div`
    //border: 1px solid #E2E8F0;
    //  border: 1px solid #FFFFFF;
    background: #FFF;
    padding: 20px 24px;

    @page {
        size: A4 landscape !important;
        margin-top: 20px;
        margin-bottom: 25px;
        //size: landscape; // 가로 방향으로 설정
    }
    @media print {
        .table-wrapper {
            //break-inside: auto;
            break-after: page;
        }

        table th {
            background-color: #EDF2F7 !important;
            -webkit-print-color-adjust: exact;
        }

    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const Title = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 6px;
`;

const HeaderGroup = styled.div`
    display: flex;
`;

const Badge = styled.div`
    display: flex;
    padding: 4px 6px;
    align-items: flex-start;
    border-radius: 6px;
    background: #F7FAFC;

    &:not(:last-child) {
        margin-right: 4px;
    }
`;

const Label = styled.div`
    color: #718096;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
    margin-right: 4px;
`;

const Text = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
`;

const TableContainer = styled.div`
`;

const TableWrapper = styled.div`
    // border-radius: 8px;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #000;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;


    th, td {
        text-align: left;
        font-size: 9px;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;

        &:not(:last-child) {
            //border-bottom: none;

        }
    }

    th {
        background-color: #EDF2F7;
        color: #4A5568;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        padding: 2px;
    }

    td {
        color: #1A202C;
    }

    tbody tr:last-child th {
        border-bottom: none;
    }

    thead {
        height: 31px;
    }

    tr {
        //height: 20px;
    }
`;

export default StatementSubmissionPagePrint;