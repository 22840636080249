import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {InputBox} from '../../library/styled-components';
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import moment from 'moment';
import {addCommas} from '../../bin/common';
import {updateDiscountApply} from '../../library/admin-react-query';
import {useMutation} from '@tanstack/react-query';

const fieldsOrder = [
    'startDt',
    'endDt',
    'useYN',
];

const discountType = [{
    value: '신규가입 할인', label: '신규가입 할인'
}, {
    value: '8월 할인', label: '8월 할인'
},];

const monthType = [{
    value: '월', label: '월'
}];
const ModifyApplyForm = forwardRef((props, ref) => {
    const data = props?.data;
    const action = props?.action;
    const editorRef = useRef(null);
    const [searchTxt, setSearchTxt] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [company, setCompany] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);


    const {
        watch,
        register,
        control,
        handleSubmit,
        reset,
        setValue,
        getValues,
        setFocus,
        formState: {errors}
    } = useForm({
        defaultValues:
            {
                startDt: moment(data.start_dt).format('YYYY-MM'),
                endDt: moment(data.end_dt).format('YYYY-MM'),
                useYN: data.use_yn,
            }
    });

    // 할인 적용 생성
    const {mutate: updateDiscountApplyMutate} = useMutation({
        mutationKey: ['updateDiscountApply'],
        mutationFn: updateDiscountApply,
        onSuccess: data => {
            if (data.done) {
                action('update');
            }
        },
        onError: error => {
            alert(error.message);
        }
    });


    useEffect(() => {
        //console.log(data);
    }, []);


    const onSubmit = (e) => {
        if (e.startDt > e.endDt) {
            alert('기간을 확인해주새요');
            return;
        }
        e.cdmSeq = data.cdm_seq;
        e.startDt = moment(e.startDt).format('YYYY-MM-01');
        e.endDt = moment(e.endDt).format('YYYY-MM-01');
        //   console.log('안녕', e);
        updateDiscountApplyMutate(e);

    };

    const onError = (errors) => {
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            const firstError = fieldsOrder.find(key => errorKeys.includes(key));
            setFocus(firstError);

            if (errors[firstError].type === 'noSpaces') {
                setValue(firstError, '');
            }
            alert(errors[firstError]?.message || 'Form validation error');
        }
    };
    return (
        <FieldGroup className="discount-apply-form" ref={ref} onSubmit={handleSubmit(onSubmit, onError)}>
            <InputBox fulled>
                <h6>할인 정책</h6>
                <h5>{data.dscnt_title}</h5>
            </InputBox>

            <InputBox fulled>
                <h6>할인 내용</h6>
                <h5>{data.dscnt_desc}</h5>
            </InputBox>
            <InputBox fulled>
                <h6>할인율/금액</h6>
                <h5>{data.dscnt_type === 'FR' ? data.dscnt_amt + '%' : addCommas(data.dscnt_amt) + '원'}</h5>
            </InputBox>
            <InputBox fulled>
                <h6>기간</h6>
                <div style={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                    columnGap: '15px'
                }}>

                    <input name="startDt" style={{width: '430px'}} {...register('startDt', {
                        required: '시작 날짜를 입력해주세요'
                    })}
                           type="month"/>
                    <span>~</span>
                    <input name="endDt" style={{width: '430px'}}
                           {...register('endDt', {
                               required: '종료 날짜를 입력해주세요'
                           })}
                           type="month" placeholder={'입력'}/>
                </div>
            </InputBox>

            <InputBox>
                <Controller
                    name="useYN"
                    control={control}
                    rules={{required: '사용 여부를 선택해주세요'}}
                    render={({field}) => (
                        <FormControl style={{rowGap: '8px', flexDirection: 'column'}}>
                            <h6>사용 여부</h6>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                {...field}
                                value={field.value} // 폼 상태와 라디오 버튼 동기화

                            >
                                <FormControlLabel
                                    value="Y"
                                    control={<Radio size="small"/>}
                                    label="사용"
                                    sx={{fontSize: '14px'}}
                                />
                                <FormControlLabel
                                    value="N"
                                    control={<Radio size="small"/>}
                                    label="중지"
                                    sx={{fontSize: '14px'}}
                                />
                            </RadioGroup>
                        </FormControl>
                    )}
                />
            </InputBox>


        </FieldGroup>
    );
});
const Icon = styled.img`
    width: 16px;
`;
const Label = styled.span`
    padding: 2px 4px;
    align-items: center;
    margin-right: 6px;
    border-radius: 6px;
    //border: 1px solid #E2E8F0;
    background-color: #D7FBE2;
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    width: fit-content;
    display: flex;
    height: 30px;
    box-sizing: border-box;
`;
const SelectedList = styled.div`
    overflow: auto;
    min-height: 250px;
    height: 250px;
    padding: 7px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #CBD5E0;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    row-gap: 6px;
`;
const CompanyList = styled.div`
    overflow: auto;
    height: 250px;
    margin: 5px 0;
    box-sizing: border-box;
    background-color: #F7FAFC;
    //border-radius: 4px;
    //border: 1px solid #CBD5E0;
`;

const CompanyItem = styled.div`
    color: #4A5568;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 8px;
    cursor: pointer;


    &&.action {
        background-color: #D7FBE2;
    }

    :hover {
        background: rgba(0, 0, 0, 0.05) !important;
    }

`;


const SearchWrap = styled.div`
    input {
        border: none;
    }


`;

const FieldGroup = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 12px;

    > div {
        row-gap: 8px;
        display: flex;
        flex-direction: column;
    }

    textarea {
        width: 100%;
        border-radius: 6px;
        border: 1px solid #CBD5E0;
        box-sizing: border-box;
        // margin-top: 6px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        resize: none;
        padding: 8px 12px;
        color: #171923;
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    input {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        font-size: 14px !important;
    }

    label {
        margin-left: 0;
        // align-items: flex-start;

        > span,
        input {
            padding: 0;

        }

        .MuiFormControlLabel-label {
            font-size: 14px;
            color: #171923;
            font-weight: 500;
            //  line-height: 20px;
        }

    }

    h5 {
        //margin-bottom: 0 !important;
        //margin-top: 0 !important;
        margin: 0 !important;
        font-size: 14px !important;
    }
`;
export default ModifyApplyForm;