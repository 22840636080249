import styled from '@emotion/styled';
import {Header} from '../../compontents/Header';
import React, {useCallback, useEffect, useState} from 'react';
import ReactSelect from 'react-select';
import Pagination from 'react-js-pagination';
import SideModal from '../../compontents/SideModal';
import {PagingFooter} from '../../compontents/PagingFooter';
import dayjs from 'dayjs';
import {addCommas, BlockScrolling} from '../../bin/common';
import AxiosNavigate from '../../library/axios-navigate';
import {useLocation} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import PlanSelectModal from '../../compontents/PlanSelectModal';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {adminSiteList, planData} from '../../library/axios-query';
import SearchBox from '../../compontents/Search';
import SelectedFilter from '../../compontents/SelectedFilter';
import PagesList from '../../compontents/PagesList';
import {getCompanyFilter} from '../../library/admin-react-query';
import EmptyContainer from '../../compontents/EmptyContainer';
import { TableStyle } from '../../library/styled-components';
const selectStyles = {
    control: base => ({
        ...base,
        boxShadow: 'none',
        marginTop: '0px',
        // height: '34px',
        width: '150px',
        minHeight: '34px',  // 원하는 높이로 변경
        height: '34px',
        '&:hover': {
            border: '1px solid #E2E8F0',
        },

    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '34px',  // 원하는 높이로 변경
        // width: '150px',
        padding: '0 6px'
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '0px',
        color: '#171923',
        backgroundColor: 'transparent',
        // display: 'none',  // 커서 깜빡임을 없앰
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '34px',  // 원하는 높이로 변경
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#171923',
    })

};

const options = [
    {value: 20, label: '20'},
    {value: 30, label: '30'},
    {value: 50, label: '50'},
    {value: 100, label: '100'}
];

function SiteAdmin() {
    const queryClient = useQueryClient();
    const location = useLocation();
    const [cookies] = useCookies(['role']);


    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(1);
    const [searchTxt, setSearchTxt] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [corpFilter, setCorpFilter] = useState(0);
    const [optionsData, setOptionsData] = useState(null);

    const [detailSite, setDetailSite] = useState({});

    const [logitude, setLogitude] = useState('');
    const [latitude, setLatitude] = useState('');

    const [opendDetailSite, setOpendDetailSite] = useState(false);
    const [openPlanSelect, setOpenPlanSelect] = useState(false);
    const [companyOptions, setCompanyOptions] = useState(null);

    BlockScrolling(opendDetailSite); // 스크롤 비활성화

    // 회사 목록 필터 어드민용
    const {mutate: getCompanyFilterItem} = useMutation({
        mutationKey: ['getCompanyFilter'],
        mutationFn: getCompanyFilter,
        onSuccess: (data) => {
            if (companyOptions === null) {
                data.unshift({value: null, label: '전체 회사'});
                setCompanyOptions(data);
            }

        },
        onSettled: () => {

        },
        onError: (error) => {
            throw error;
        }
    });


    const {mutate: adminSiteItems, data: sites} = useMutation({
        mutationFn: adminSiteList,
        mutationKey: ['adminSiteList', limit, page, corpFilter],
        onSuccess: (data) => {
            if (optionsData === null) {
                setOptionsData(data.siteList);
            }
        }
    });
    const {mutate: workFieldItems, data: workFieldData, isSuccess: workFieldSuccess} = useMutation({
        mutationKey: ['planData', 'M0003'],
        mutationFn: planData,
        onSuccess: (data) => {

        },
    });

    const handleSiteInfo = useCallback((site_info) => {
        setOpendDetailSite(true);
        setDetailSite(site_info);
    }, []);

    useEffect(() => {

        const siteData = {
            search_txt: searchTxt.replaceAll(' ', ''),
            corpFilter: corpFilter,
            page: page,
            limit: limit
        };
        adminSiteItems(siteData);
    }, [page, limit, searchTxt, corpFilter]);

    useEffect(() => {
        workFieldItems('M0003');
        if (companyOptions !== null) return;
        if (optionsData === null) return;
        // 확인 완료
        getCompanyFilterItem(0);
    }, [optionsData]);


    const handleLogitudeAndLatitude = (logitude, latitude) => {
        setLogitude(logitude);
        setLatitude(latitude);
    };


    const numberOnly = (e) => {
        if (e.target.value.match(/[^0-9]/g)) {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
        }
    };


    const dataFilter = (id) => {
        if (opendDetailSite === null) return;
        const filterData = workFieldData?.filter((e, i) => {
            if (e?.dtl_cd_id === id) {
                return e?.dtl_cd_nm;
            }
        });

        return filterData[0].dtl_cd_nm;
    };


    return (
      <AxiosNavigate>
        <div>
          <Header>
            <div>현장 관리</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "16px",
              }}
            >
              {companyOptions?.length > 0 && (
                <SelectedFilter
                  style={{ width: "200px" }}
                  placeholder={companyOptions ? companyOptions[0].label : ""}
                  options={companyOptions}
                  onChange={(e) => {
                    setPage(1);
                    setSearchTxt("");
                    setCorpFilter(e.value);
                  }}
                ></SelectedFilter>
              )}

              <SearchBox
                placeholder={"현장명 검색"}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    e.nativeEvent.isComposing === false
                  ) {
                    if (
                      e.target.value.length > 0 &&
                      e.target.value.trim().length === 0
                    ) {
                      alert("현장명을 입력해 주세요");
                      setSearchValue(e.target.value.trim());
                      return;
                    }

                    setCorpFilter("");
                    setSearchTxt(e.target.value);
                    setSearchValue(e.target.value.trim());
                    setPage(1);
                  }
                }}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              >
                {" "}
              </SearchBox>
            </div>
          </Header>

          <TableStyle>
            <div>
              <table style={{ tableLayout: "fixed" }}>
                <thead>
                  <tr>
                    <th style={{ width: "80px" }}>법인명</th>
                    <th style={{ width: "80px" }}>아이디</th>
                    <th style={{ width: "100px" }}>현장코드</th>
                    <th style={{ width: "260px" }}>현장명</th>
                    <th style={{ width: "80px" }}>발주처/원청사</th>
                    <th style={{ width: "80px" }}>현장소장</th>
                    <th style={{ width: "60px" }}>진행상태</th>
                  </tr>
                </thead>
                <tbody>
                  {sites && sites?.count === 0 ? (
                    <tr style={{ height: "500px" }}>
                      <td colSpan={6}>
                        <EmptyContainer
                          falseText={"등록된 현장 목록이"}
                          style={{ background: "#fff" }}
                        ></EmptyContainer>
                      </td>
                    </tr>
                  ) : (
                    sites?.siteList?.map((e, i) => (
                      <tr key={i}>
                        <td className="spaces" style={{ width: "50px" }}>
                          {e.corp_nm}
                        </td>
                        <td className="spaces" style={{ width: "50px" }}>
                          {e.corp_id}
                        </td>
                        <td className="spaces" style={{ width: "50px" }}>
                          {e.site_code}
                        </td>
                        <td className="spaces" style={{ width: "250px" }}>
                          <div
                            style={{
                              minWidth: "162px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {e.site_nm}
                            <div style={{ display: "flex" }}>
                              <Button
                                onClick={() => {
                                  handleSiteInfo(e);
                                  handleLogitudeAndLatitude(null, null);
                                }}
                                style={{ marginLeft: "8px" }}
                              >
                                상세보기
                              </Button>
                            </div>
                          </div>
                        </td>
                        <td className="spaces" style={{ width: "50px" }}>
                          {e.orderer_nm}
                        </td>
                        <td className="spaces" style={{ width: "50px" }}>
                          {e.manager_nm}
                        </td>
                        <td className="spaces" style={{ width: "50px" }}>
                          {e.end_yn == "Y" ? "종료" : "진행중"}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </TableStyle>

          <PagingFooter>
            <div></div>

            <Pagination
              prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
              nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={sites && sites?.count !== 0 ? sites.count : 1}
              pageRangeDisplayed={5}
              hideFirstLastPages={true}
              onChange={(e) => {
                setPage(e);
              }}
            />

            <PagesList
              options={options}
              onChange={(e) => {
                setPage(1);
                setLimit(e.value);
              }}
            ></PagesList>
          </PagingFooter>

          <SideModal
            opend={opendDetailSite}
            closeModal={() => {
              setOpendDetailSite(false);
            }}
            header={"현장 상세정보"}
          >
            {detailSite && (
              <>
                <div>
                  {detailSite.end_yn === "Y" ? (
                    <Tabs>
                      <NoCursorTab>진행</NoCursorTab>
                      <NoCursorTab style={{ background: "#fff" }}>
                        종료
                      </NoCursorTab>
                    </Tabs>
                  ) : (
                    <Tabs>
                      <NoCursorTab style={{ background: "#fff" }}>
                        진행
                      </NoCursorTab>
                      <NoCursorTab>종료</NoCursorTab>
                    </Tabs>
                  )}
                </div>
                <Content>
                  <figure>
                    <h6>공사명</h6>
                    <h5>{detailSite.const_nm}</h5>
                  </figure>
                  <figure>
                    <h6>공사기간</h6>
                    <h5>
                      {detailSite.begin_day == "1899-11-30"
                        ? "0000년 00월 00일"
                        : dayjs(detailSite.begin_day).format("YYYY년 M월 D일")}
                      ~{" "}
                      {detailSite.end_day == "1899-11-30"
                        ? "0000년 00월 00일"
                        : dayjs(detailSite.end_day).format("YYYY년 M월 D일")}
                    </h5>
                  </figure>
                  <figure>
                    <h6>현장명</h6>
                    <h5>{detailSite.site_nm}</h5>
                  </figure>
                  <figure>
                    <h6>현장코드</h6>
                    <h5>{detailSite.site_code}</h5>
                  </figure>
                  <figure>
                    <h6>공종</h6>
                    <h5>
                      {workFieldSuccess &&
                        detailSite?.work_field &&
                        dataFilter(detailSite?.work_field)}
                    </h5>
                  </figure>
                  <figure>
                    <h6>계약금액(부가세포함)</h6>
                    <h5>{addCommas(detailSite.contract_amt)}원</h5>
                  </figure>
                  <hr />
                  {detailSite.tb_const_site_change_histories?.map(
                    (item, index) => (
                      <div>
                        <figure>
                          <h6>설계변경 ({index + 1}차)</h6>
                          <h5></h5>
                        </figure>
                        <figure>
                          <h6>계약금액(부가세포함)</h6>
                          <h5>{addCommas(item.contract_amt)}원</h5>
                        </figure>
                        <figure>
                          <h6>공사기간</h6>
                          <h5>
                            {dayjs(item.begin_day).format("YYYY년 M월 D일")} ~{" "}
                            {dayjs(item.end_day).format("YYYY년 M월 D일")}
                          </h5>
                        </figure>
                        <hr />
                      </div>
                    )
                  )}

                  <figure>
                    <h6>발주처(원도급공사)</h6>
                    <h5>{detailSite.orderer_nm}</h5>
                  </figure>
                  <figure>
                    <h6>원도급사(하도급공사)</h6>
                    <h5>{detailSite.contractor_nm}</h5>
                  </figure>
                  <figure>
                    <h6>퇴직공제 가입번호</h6>
                    <h5>{detailSite.retirement_num}</h5>
                  </figure>
                  <figure>
                    <h6>고용/산재 관리번호</h6>
                    <h5>{detailSite.employment_num}</h5>
                  </figure>
                  <figure>
                    <h6>국민/건강 관리번호</h6>
                    <h5>{detailSite.health_num}</h5>
                  </figure>
                  <hr />
                  <figure>
                    <h6>현장소장</h6>
                    <h5>
                      {detailSite.manager_nm
                        ? detailSite.manager_nm
                        : detailSite.manager_nm_temp}
                    </h5>
                  </figure>
                  <figure>
                    <h6>현장 주소</h6>
                    <h5>
                      {" "}
                      {detailSite.addr1} {detailSite.addr2}
                    </h5>
                  </figure>
                  <hr />
                  <figure>
                    <h6>세금계산서 이메일</h6>
                    <h5>{detailSite.tax_email_addr}</h5>
                  </figure>
                  <figure>
                    <h6>세금계산서 담당자</h6>
                    <h5>{detailSite.tax_user_nm}</h5>
                  </figure>
                  <figure>
                    <h6>담당자 전화번호</h6>
                    <h5>{detailSite.tax_phone_num}</h5>
                  </figure>
                  <hr />
                  <figure>
                    <h6>평일 오전</h6>
                    <h5>
                      {detailSite.am_begin} ~ {detailSite.am_end}
                    </h5>
                  </figure>
                  <figure>
                    <h6>평일 오후</h6>
                    <h5>
                      {detailSite.pm_begin} ~ {detailSite.pm_end}
                    </h5>
                  </figure>
                  <figure>
                    <h6>토요일 오전</h6>
                    <h5>
                      {detailSite.satday_yn === "Y"
                        ? `${detailSite.satday_begin} ~ ${detailSite.satday_end}`
                        : "인정안함"}
                    </h5>
                  </figure>
                  <figure>
                    <h6>토요일 오후</h6>
                    <h5>
                      {detailSite.satday_yn === "Y"
                        ? `${detailSite.satday_pm_begin} ~ ${detailSite.satday_pm_end}`
                        : "인정안함"}
                    </h5>
                  </figure>
                  <figure>
                    <h6>일요일/공휴일 오전</h6>
                    <h5>
                      {detailSite.weekend_yn === "Y"
                        ? `${detailSite.weekend_begin} ~ ${detailSite.weekend_end}`
                        : "인정안함"}
                    </h5>
                  </figure>
                  <figure>
                    <h6>일요일/공휴일 오후</h6>
                    <h5>
                      {detailSite.weekend_yn === "Y"
                        ? `${detailSite.weekend_pm_begin} ~ ${detailSite.weekend_pm_end}`
                        : "인정안함"}
                    </h5>
                  </figure>
                  <figure>
                    <h6>야간</h6>
                    <h5>
                      {detailSite.overtime_yn === "Y"
                        ? `${
                            detailSite?.overtime_begin
                              ? detailSite?.overtime_begin
                              : ""
                          } ~ ${
                            detailSite?.overtime_end
                              ? detailSite?.overtime_end
                              : ""
                          }`
                        : "인정안함"}
                    </h5>
                  </figure>
                  <figure>
                    <h6>오차범위</h6>
                    <h5>
                      {dayjs("0000-00-00 " + detailSite.time_range).format(
                        "mm"
                      ) + "분"}
                    </h5>
                  </figure>
                  <hr />
                </Content>
              </>
            )}
          </SideModal>

          <PlanSelectModal
            opend={openPlanSelect}
            closeModal={() => {
              setOpenPlanSelect(false);
            }}
            handleOk={() => {}}
            widthCheck={"50%"}
            maxHeightCheck={"797"}
          >
            <div>test</div>
          </PlanSelectModal>
        </div>
      </AxiosNavigate>
    );
}

export const FormButton = styled.div`
    cursor: pointer;
    padding: 8px 14px;
    border-radius: 8px;
    background-color: #708090;
    color: aliceblue;
    border: .5px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    //width: fit-content;
    margin-top: 12px;

    margin-bottom: 15px;
`;

const Search = styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #CBD5E0;
    overflow: hidden;
    height: 34px;
    box-sizing: border-box;
    //margin-right: 16px;
`;

const SearchIcon = styled.img`
    padding-left: 12px;
`;

const SearchInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 6px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 100%;

    :focus {

    }
`;

const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const Button = styled.div`
    display: flex;
    flex-wrap: wrap;
    //width: fit-content;
    min-height: 32px;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 6px;
    border: 1px solid #E2E8F0;
    background: #FFF;
    width: max-content;
    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    cursor: pointer;

`;

const Select = styled(ReactSelect)`
    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    border-radius: 6px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    background: #FFF;
    //margin-top: 5px;

    .react-select__control {
        height: 38px;
    }

    > div {
        cursor: pointer;
        color: #4A5568;
        border-color: #CBD5E0;
    }

    > div > div {
        //padding: 0;

    }

    #react-select-23-placeholder {
        content: none;
    }
`;

const Icon = styled.img`
    width: 16px;
`;

const TableContainer = styled.div`
    padding: 0 32px;
    margin-top: 28px;
`;

const TableWrapper = styled.div`
    border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: left;
        padding: 8px;
        font-size: 13px;
        font-weight: 300;
        border-right: 1px solid #CBD5E0;
        border-bottom: 1px solid #CBD5E0;
        white-space: nowrap;
    }

    th {
        background-color: #F7FAFC;
    }

    tr:last-child td {
        border-bottom: none;
    }

    td {
        //background: red;
        text-wrap: wrap;
    }

`;


const SelectWrapper = styled.div`
    display: flex;
    align-items: center;

    span {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-right: 6px;
    }
`;

const Content = styled.div`
    figure {
        //margin: 0;
        display: flex;
        margin: 0 0 10px;
        align-items: center;
        justify-content: space-between;
        //height: 34px;
    }

    h6, h5 {
        margin: 0;
    }

    h6 {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        width: 125px;
    }

    h5 {
        color: rgba(0, 0, 0, 0.92);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        width: calc(100% - 140px);
    }

    a {
        color: rgba(0, 0, 0, 0.92);
        font-size: 12px;
    }

    hr {
        background: #EDF2F7;
        color: #EDF2F7;
        border: 1px solid #EDF2F7;
        margin: 12px 0;
    }

    input {
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;

        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        ::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        appearance: none;
        box-sizing: border-box;
        width: 100%;
        border: 0px;
        outline: none;

        padding: 8px 12px;

        color: #171923;
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        background: #FFF;
        border: 1px solid #CBD5E0;
        border-radius: 6px;
        margin-bottom: 12px;
        margin-top: 6px;

        :focus {

        }
    }
`;

const Tabs = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    background: #EDF2F7;
    padding: 4px;
    margin-bottom: 28px;
`;

const Tab = styled.div`
    width: 50%;
    text-align: center;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
`;
const NoCursorTab = styled.div`
    width: 50%;
    text-align: center;
    padding: 6px 10px;
    border-radius: 4px;
`;

const FieldGroup = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0 14px;
`;

const Field = styled.div`
    h6 {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
    }

    span {
        color: #E53E3E
    }

    input {
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;

        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        ::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        appearance: none;
        box-sizing: border-box;
        width: 100%;
        border: 0px;
        outline: none;

        padding: 8px 12px;

        color: #171923;
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        background: #FFF;
        border: 1px solid #CBD5E0;
        border-radius: 6px;
        margin-bottom: 12px;
        margin-top: 6px;

        :focus {

        }
    }


    width: ${({fulled}) => fulled ? `100%` : '48%'};

    @media only screen and (max-width: 992px) {
        width: 100%;
    }
`;
const Avatar = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin-left: 8px;
`;
const Card = styled.div`
    border-radius: 12px;
    background: #F7FAFC;
    padding: 20px;
    margin-top: 20px;

    h5 {
        color: #171923;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin: 0;
        margin-bottom: 16px;
    }
`;

const LaborCard = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 14px 0;
    cursor: pointer;

    &:hover {
        background-color: #EBF2FE !important;
        border-radius: 4px;
    }
`;

const LaborCardContent = styled.div`
    h5, h6 {
        margin: 0;
    }

    h5 {
        color: #4A5568;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px
    }

    h6 {
        color: #718096;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
`;
const EmptyLaborCard = styled.div`
    color: #4A5568;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    padding-top: 26px;
`;

const RadioButton = styled.label`
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 12px;
    user-select: none;
    margin-right: 20px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #eee;
        border-radius: 50%;
    }

    input:checked ~ .checkmark {
        background-color: #2196F3;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        top: 4px;
        left: 4px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: white;
    }

    h6 {
        width: 100%;
    }
`;

export default SiteAdmin;