import './App.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {routes} from './app-routes';
import React from 'react';
import {RecoilRoot} from 'recoil';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

const router = createBrowserRouter(routes);
const queryClient = new QueryClient();


function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <RecoilRoot>
                <RouterProvider router={router}/>
            </RecoilRoot>
        </QueryClientProvider>
    );
}


export default App;