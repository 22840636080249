import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Link as DomLink, useNavigate, useSearchParams} from 'react-router-dom';
import {HOST_URL} from '../bin/env';
import {useEffect, useState} from 'react';
import axios from 'axios';
import {useCookies} from 'react-cookie';
import {useMutation} from '@tanstack/react-query';
import {companyInfoList, companySignIn, planData, verifyUser} from '../library/axios-query';
import {useSetRecoilState} from 'recoil';
import {companyState} from '../library/recoil/atoms/companyState';
import {planState} from '../library/recoil/atoms/planState';
import {verifyUserState} from '../library/recoil/atoms/verifyUserState';

function Login() {
    const setPlanDataState = useSetRecoilState(planState);
    const setVerifyUserState = useSetRecoilState(verifyUserState);
    const setCompanyInfo = useSetRecoilState(companyState);
    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [isRemember, setIsRemember] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['rememberId', 'rememberState']);
    const navigate = useNavigate();
    const isFirstLoad = sessionStorage.getItem('first_load');
    const {register, handleSubmit, watch} = useForm();

    // 플랜 정보
    const {mutate: planDataItem, data: planDataList} = useMutation({
        mutationKey: ['planData'],
        mutationFn: planData,
        onSuccess: (data) => {
            setPlanDataState(data);
        },
        onSettled: () => {

        }
    });


    // 회사정보  저장
    const {mutate: companyInfoItem} = useMutation({
        mutationKey: ['companyInfo'],
        mutationFn: companyInfoList,
        onSuccess: (data) => {
            setCompanyInfo(data);
            navigate('/');
        }
    });


    // 사용자 정보 저장
    const {mutate: verifyUserItem} = useMutation({
        mutationKey: ['verifyUser'],
        mutationFn: verifyUser,
        onSuccess: (data) => {

            if (id === 'admin') {
                data.admin = true;
                navigate('/admin/company');
            } else
                companyInfoItem();
            setVerifyUserState(data);
        }
    });


    const {mutate: signInMutate} = useMutation({
        mutationKey: ['companySignIn'],
        mutationFn: companySignIn,
        onSuccess: (result) => {
            localStorage.setItem('admin_token', result.token);
            sessionStorage.setItem('first_load', true);
            removeCookie('rememberId');
            removeCookie('rememberState');

            if (isRemember) {
                setCookie('rememberId', id, {maxAge: 2000});
                setCookie('rememberState', isRemember, {maxAge: 2000});
            }

            planDataItem('M0015');
            verifyUserItem();
        },
        onError: (error) => {
            console.error('error fetching data:', error.message);
            // alert(error.message);
        }
    });
    useEffect(() => {
        // console.log(isFirstLoad);
        if (!isFirstLoad) {
            // 비어있을때
            removeCookie('role');
        }
        if (cookies.rememberState) {
            setId(cookies.rememberId);
            setIsRemember(true);
        }
    }, []);

    const handleOnChange = (e) => {
        setIsRemember(e.target.checked);
        if (!e.target.checked) {
            removeCookie('rememberId');
        }
    };

    const onSubmit = (e) => {
        if (!e.corp_id && !id) {
            alert('아이디를 입력해주세요');
            return;
        }
        if (!e.corp_pw) {
            alert('비밀번호를 입력해주세요');
            return;
        }
        if (!e.corp_id) {
            e.corp_id = id;
        }
        setId(e.corp_id);
        setPassword(e.corp_pw);
        signInMutate(e);
    };
    const onAuth = async (entrySite) => {
        window.location.href = `${HOST_URL}/oauth2/${entrySite}/auth/admin`;

    };

    useEffect(() => {
        const access_token = searchParams.get('access_token');
        const entry_site = searchParams.get('entry_site');
        if (access_token) {
            setSearchParams('');
            let token = null;
            (async () => {
                try {
                    const {data: {result}} = await axios.post(`${HOST_URL}/user/signIn/web`, {
                        lang: 'ko',
                        entry_site: entry_site,
                        access_token: access_token
                    }, {withCredentials: true});
                    token = result.token;
                    localStorage.setItem('admin_token', token);
                    // console.log('로그인시', token);
                    verifyUserItem();
                    planDataItem('M0015');
                    // navigate('/');
                } catch (e) {
                    alert(e.response.data.message);
                }
            })();
        }
    }, [searchParams]);

    const findPassword = () => {
        navigate('/find-account/password?finish=false');
    };

    const findID = () => {
        navigate('/find-account/id?finish=false');
    };
    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
                <MainWrapper>
                    <Wrapper>
                        <Header>
                            <img src="/assets/images/ilsa-logo.png" style={{width: '144px', height: '48px'}}/>
                            <div>일과 사람</div>
                        </Header>
                        <Input placeholder="아이디 입력" type="text" defaultValue={id} {...register('corp_id')}/>
                        <Input placeholder="비밀번호 입력" type="password" defaultValue={password} {...register('corp_pw')} />
                        <Footer>
                            <div>
                                <label>
                                    <input type="checkbox" onChange={(e) => handleOnChange(e)} checked={isRemember}/>
                                    아이디 저장
                                </label>
                            </div>
                            <FindWrapper>
                                <span onClick={findID}>아이디</span>
                                <span onClick={findPassword}>비밀번호 <em>찾기</em></span>
                            </FindWrapper>
                        </Footer>
                        <Button type="submit" style={{
                            background: '#3279F5',
                            color: '#FFFFFF'
                        }}>
                            <span>로그인</span>
                        </Button>
                        <div style={{
                            border: '1px solid #E5E5E6',
                            width: '320px',
                            marginTop: '35px',
                            marginBottom: '20px'
                        }}></div>
                        <Text>카카오, 애플 로그인은 <span>프리미엄</span>과 <span>엔터프라이즈 등급</span>에서 등록된 인원만 적용됩니다.</Text>
                        <SnsButtonWrapper>
                            <Button type="button"
                                    onClick={() => onAuth('kakao')}
                                    style={{
                                        background: '#FEE500',
                                        color: '#000'
                                    }}
                            >
                                <Icon>
                                    <img src="/assets/icons/kakao.svg"/>
                                </Icon>
                                <span>카카오 로그인</span>
                            </Button>
                            <Button type="button" style={{
                                background: '#000000',
                                color: '#fff'
                            }}
                                    onClick={() => onAuth('apple')}>
                                <Icon style={{background: '#000', border: '1px solid #000'}}>
                                    <img src="/assets/icons/apple.svg"/>
                                </Icon>
                                <span>애플 로그인</span>
                            </Button>
                            <Button type="button" style={{
                                backgroundColor: '#FFFFFF',
                                color: '#3279F5',
                                border: '1px solid #3279F5'
                            }} onClick={() => navigate('/signup-new')}>
                                <span>신규가입하기</span>
                            </Button>
                            <Button type="button" style={{
                                backgroundColor: '#569674',
                                color: '#FFF',
                                marginTop: '25px'
                            }}
                                    onClick={() => window.open('https://bucket-9uc28v.s3.ap-northeast-2.amazonaws.com/ilsa/manual/ilsa_setup_manual.pdf')}>
                                <span>사용 메뉴얼</span>
                            </Button>
                        </SnsButtonWrapper>
                    </Wrapper>
                </MainWrapper>
            </form>
            <LoginImgWrapper>
                <div>
                    <span>현장의 근태부터 급여까지,</span>
                    <span>관리업무를 쉽고 정확하게 <br/>
<em>일사</em>로 해결하세요.</span>
                </div>
                <LoginImg>
                    <img src="/assets/icons/login-bg02.svg"/>
                </LoginImg>
            </LoginImgWrapper>
        </Container>
    );
}

const Container = styled.div`
    background: #F7FAFC;
    overflow: hidden;
    display: flex;
    min-height: 100vh;
    height: 900px;
    //min-height: 900px;

    form {
        width: 50vw;
        display: block;
        position: relative;
        @media only screen and (max-width: 992px) {
            width: 100vw;
        }
    }



`;
const MainWrapper = styled.div`
    box-shadow: 0px 0px 5px 0px #0000001A;
    border-radius: 20px;
    background: #FFFFFF;
    max-width: 580px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 860px;
    min-width: 470px;
    min-height: 700px;
    max-width: 580px;

    @media only screen and (max-width: 768px) {
        //width: 30%;
        //background: aquamarine;
        box-shadow: none;
        border-radius: 0;
        background: #F7FAFC;
    }

`;
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 320px;
    width: 100%;
    margin: auto;
    max-height: 860px;
    min-height: 700px;

`;

const Header = styled.div`
    text-align: center;
    margin-bottom: 40px;

    @media only screen and (max-width: 768px) {
        div {
            font-size: 14px;
            font-weight: 500;
        }

        img {
            width: 120px !important;
            height: auto !important;
        }
    }
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    label {
        color: #718096;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        display: flex;
        align-items: center;
        column-gap: 3px;
        @media only screen and (max-width: 768px) {
            font-size: 14px;
        }
    }
`;
const FindWrapper = styled.div`
    span {
        font-size: 13px;
        font-weight: 500;
        color: #757575;
        position: relative;
        cursor: pointer;

        em {
            font-weight: 400;
            font-style: normal;
        }
    }

    span:first-of-type {
        margin-right: 13px;
    }

    span:first-of-type:after {
        position: absolute;
        display: block;
        content: '|';
        top: 0px;
        right: -8px;
        color: #757575;

    }

    @media only screen and (max-width: 768px) {
        font-size: 14px;
    }

`;
const Text = styled.em`
    font-style: normal;
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 400;
    color: #2D3748;

    span {
        font-weight: 700;
    }

    @media only screen and (max-width: 768px) {
        font-size: 14px;
    }
`;
const Link = styled(DomLink)`
    color: #718096;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-decoration-line: underline;
`;

const Input = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    outline: none;

    padding: 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #FFF;
    border: 1px solid #CBD5E0;
    border-radius: 6px;
    margin-bottom: 12px;

    @media only screen and (max-width: 768px) {
        font-size: 14px;
        &::placeholder {
            font-size: 14px;
        }
    }
`;

const Button = styled.button`
    display: flex;
    width: 100%;
    height: 39px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
        // background: ${props => props.btnBackground};
        // color: ${props => props.btnColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
    border: none;
    text-decoration: none;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    span {
        width: 110px;
        font-size: 19px;
        font-weight: 500;
    }

    @media only screen and (max-width: 768px) {
        span {
            font-size: 16px;
            width: 90px;
        }
    }
`;
const SnsButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    row-gap: 10px;
`;
const Icon = styled.div`
    display: flex;

    img {
        //width: 100%;
    }
`;
const LoginImgWrapper = styled.div`
    width: 50vw;
    background: #EBF2FE;
    position: relative;

    div:first-of-type {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin-top: 10%;
    }

    div > span {
        font-size: 2em;
        font-weight: 700;
        text-align: center;
        color: #2D3748;

        &:last-of-type {
            font-size: 2.5em;
        }

        em {
            color: #569674;
            font-style: normal;
        }
    }

    @media only screen and (max-width: 992px) {
        display: none;
    }
    @media only screen and (min-width: 1800px) {
        div:first-of-type {
            font-size: 19px;
            margin-top: 5%;
        }
    }
`;
const LoginImg = styled.div`
    width: calc(100% - 15px);
    max-width: 930px;
    height: auto;
    float: right;
    position: absolute;
    bottom: 0;
    right: 0;

    img {
        width: 100%;
    }


`;
export default Login;