import styled from '@emotion/styled';
import React, {forwardRef} from 'react';
import dayjs from 'dayjs';
import {addCommas} from '../../bin/common';
import {TablePrintStyle} from '../../library/styled-components';

const LaborAttendancePagePrint = forwardRef((props, ref) => {

    const data = props.data;
    const totalCount = props.totalCount;
    const siteUserCount = props.siteUserCount;
    const attendanceList = data.attendances;

    return (
        <Container ref={ref}>
            <Header>
                <div style={{width: '80%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Title>출역일보</Title>
                    <Text>
                        {/*<strong>현장명</strong> <span>{data.}</span>*/}
                    </Text>
                </div>

                <div>
                </div>

                <div style={{
                    marginTop: '10px', marginRight: '10px',
                    color: '#4a5568', fontSize: '13px'
                }}>
                    출역인원 : {totalCount} / 전체인원 : {siteUserCount}
                </div>

            </Header>
            <TablePrintStyle>
                <div className="table_wrapper">
                    <table>
                        <thead style={{height: 'unset'}}>

                        <tr>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>이름</th>
                            <th rowSpan={2}>주민등록번호</th>
                            <th rowSpan={2}>직종</th>
                            <th rowSpan={2}>소속</th>
                            <th rowSpan={2}>출근시간</th>
                            <th rowSpan={2}>퇴근시간</th>
                            <th rowSpan={2}>실제<br/>출근시간</th>
                            <th rowSpan={2}>실제<br/>퇴근시간</th>
                            <th colSpan={4}>출역공수</th>
                            <th rowSpan={2}>단가</th>
                            <th rowSpan={2}>금액</th>
                            <th rowSpan={2}>현장</th>
                        </tr>
                        <tr>
                            <th>오전</th>
                            <th>오후</th>
                            <th>야간</th>
                            <th className={'print_border'}>합계</th>
                        </tr>
                        </thead>
                        <tbody>

                        {attendanceList?.map((e, i) =>
                            <tr key={i} className={attendanceList.length === i + 1 && 'none'}>
                                <td>
                                    <div>{i + 1}</div>
                                </td>
                                <td style={{textAlign: 'left'}}>{e.user_nm}</td>
                                <td>{e.id_num}</td>
                                <td>{e.job_position}</td>
                                <td style={{textAlign: 'left'}}>{e.cmpny_nm}</td>
                                <td>{e.start_dt ? dayjs(e.start_dt).add(-9, 'hour').format('HH:mm') : ''}</td>
                                <td>{e.end_dt ? dayjs(e.end_dt).add(-9, 'hour').format('HH:mm') : ''}</td>
                                <td>{e.actual_start_dt ? dayjs(e.actual_start_dt).add(-9, 'hour').format('HH:mm') : ''}</td>
                                <td>{e.actual_end_dt ? dayjs(e.actual_end_dt).add(-9, 'hour').format('HH:mm') : ''}</td>
                                <td>{e.man_day_am}</td>
                                <td>{e.man_day_pm}</td>
                                <td>{e.man_day_night}</td>
                                <td>{e.man_day}</td>
                                <td style={{textAlign: 'right'}}>{addCommas(e.day_amt)}</td>
                                <td style={{textAlign: 'right'}}>{addCommas(e.salary_amt)}</td>
                                <td style={{textAlign: 'left'}}>{e.site_nm}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </TablePrintStyle>

        </Container>
    );
});

const Container = styled.div`
    //border: 1px solid #E2E8F0;
    background: #FFF;
    padding: 20px 24px;


    @page {
        size: A4 landscape !important;
        margin-top: 20px;
        margin-bottom: 25px;
    }
    @media print {
        table th {
            background-color: #EDF2F7 !important;
            -webkit-print-color-adjust: exact;
        }

        .table-wrapper {
            //break-inside: auto;
            break-after: page;
        }

        body {
            margin: 0;
        }
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const Title = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 6px;
`;

const HeaderGroup = styled.div`
    display: flex;
`;

const Badge = styled.div`
    display: flex;
    padding: 4px 6px;
    align-items: flex-start;
    border-radius: 6px;
    background: #F7FAFC;

    &:not(:last-child) {
        margin-right: 4px;
    }
`;

const Label = styled.div`
    color: #718096;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
    margin-right: 4px;
`;

const Text = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
`;

export default LaborAttendancePagePrint;